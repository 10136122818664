import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  UncontrolledAlert,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Button,
  Badge,
  FormGroup,
  Input,
} from "reactstrap";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import ReactPaginate from "react-paginate";
import Empty from "../components/Empty";
import queryString from "query-string";
import useUrlState from "utils/useUrlState";
import moment from "moment";
import Status from "components/Donctor/Status";
import debounce from "utils/debounce";
import { Notifications } from "utils/notification";

let timer = null;

const Doctors = () => {
  let location = useLocation()
  const { search: searchSting } = useLocation();
  const [specialities, setSpecialities] = useState([]);
  const { addToast } = useToasts();
  const [doctors, setDoctors] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });
  const [where, setWhere] = useState({});
  const [populate, setPopulate] = useState(
    `["doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]`
  );
  const [params, updateParams, isStateSet] = useUrlState({
    page: 0,
    is_license_expired: "All",
    search: "",


  });
  const { is_license_expired: licenseStatus, doctor_status: status, page, search } = params;


  useEffect(() => {
    if (!!status) {
      where["status"] = { $eq: status };
    } else {
      const { status, ...reset } = where;
      setWhere(reset);
    }
  }, [status]);
  useEffect(() => {
    if (licenseStatus === "Active") {
      where["is_license_expired"] = false;
    } else if (licenseStatus === "Expired") {
      where["is_license_expired"] = true;
    } else if (licenseStatus === "All") {
      const { is_license_expired, ...reset } = where;
      setWhere(reset);
    }
  }, [licenseStatus]);
  const fetchDoctors = async (params) => {
    setDoctors([]);
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: "/doctor",
      params,
    })
      .then(({ data }) => {
        setSpecialities(data?.specialities);
        setDoctors(data.doctors);
        setPerPage(data.limit);
        setPageCount(Math.ceil(data.count / data.limit));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const sortDoctors = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  // useEffect(() => {
  //   setPendingCount(doctors.filter((item) => item.status === "pending").length);
  // }, [doctors]);

  useEffect(() => {
    if (isStateSet && licenseStatus != undefined) fetchDoctors(getParms());
  }, [sort, serviceType, where, populate, status, licenseStatus, params.page, isStateSet, location?.pathname, params?.doctor_status]);
  console.log(params);
  const getParms = () => {
    return {
      // select: '["uid","full_name","npi","phone","email","status"]',
      sort:
        sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
      where,
      populate,
      offset: page * perPage,
      search,
      limit: perPage,
    }
  }

  // const getSpecialities = (docSpec) => {
  //   if (docSpec) {
  //     const arr = docSpec.map((item) => {
  //       return item.speciality ? item.speciality["name"] : "";
  //     });
  //     return arr.length > 1 ? arr[0] + `  +${arr.length - 1}` : arr[0] || "N/A";
  //   } else {
  //     return "NA";
  //   }
  // };
  const [placeHolder, setPlaceholder] = useState({
    speciality: "all",
    serviceType: "all",
  });
  const { state } = React.useContext(Notifications);
  return (
    <>
      <div className="content">
        {state?.unread?.doctorPendingCount > 0 && (
          <UncontrolledAlert color="warning" fade={false}>
            <span>
              <b>
                {" "}
                There {localStorage.getItem("pending_count") > 1 ? "are" : "is"} {localStorage.getItem("pending_count")} Doctor
                Approvals Pending{" "}
              </b>
              <Button
                className="btn-round  mb-0 mt-0 ml-2"
                color="darkwarning"
                type="button"
                onClick={() => {
                  updateParams((prev) => ({
                    ...prev,
                    doctor_status: "pending",
                  }));
                }}
              >
                View
              </Button>
            </span>
          </UncontrolledAlert>
        )}

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                {" "}
                <Row className="p-1">
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Search</label>
                      <Input
                        // defaultValue=""
                        onChange={({ target: { value } }) => {
                          updateParams((prev) => ({
                            ...prev,
                            page: 0,
                            search: value.replace(/^\s+/g, ''),
                          }));
                          clearTimeout(timer)
                          timer = setTimeout(() => {
                            let params = getParms();
                            params["search"] = value.replace(/^\s+/g, '');
                            fetchDoctors(params);
                          }, 500)
                          // debounce(() => {
                          //   updateParams((prev) => ({
                          //     ...prev,
                          //     page: 0,
                          //     search: value,
                          //   }));
                          // }, 500);

                        }}
                        placeholder="Full name / Email / NPI / Phone"
                        type="text"
                        value={search}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>specialty</label>
                      <Input
                        type="select"
                        name="select"
                        onChange={({ target: { value } }) => {
                          const iSServiceSelected = where["$doctorFacilityFilter.service_type$"] ? true : false
                          updateParams((prev) => ({
                            ...prev,
                            page: 0
                          }));
                          setPlaceholder({ ...placeHolder, speciality: value });
                          if (value === "all") {
                            setPopulate(
                              iSServiceSelected ? `["doctorSpecialityFilter","doctorFacilityFilter","doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]` : `["doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]`
                            );
                            const {
                              ["$doctorSpecialityFilter.speciality_id$"]: any,
                              ...rest
                            } = where;
                            console.log(rest);
                            setWhere(rest);
                          } else {
                            setPopulate(
                              `["doctorSpecialityFilter","doctorFacilityFilter","doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]`
                            );
                            setWhere({
                              ...where,
                              ["$doctorSpecialityFilter.speciality_id$"]: value,
                            });
                          }
                        }}
                        id="exampleSelect"
                        value={placeHolder?.speciality}
                      >
                        <option value="all">All</option>
                        {specialities?.map((speciality, id) => (
                          <option value={speciality["speciality_id"]} key={id}>
                            {speciality["speciality.name"]}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Service Type</label>
                      <Input
                        type="select"
                        name={where["$doctorFacilityFilter.service_type$"]}
                        onChange={({ target: { value } }) => {
                          const iSSpecialtySelected = where["$doctorSpecialityFilter.speciality_id$"] ? true : false
                          setPlaceholder({
                            ...placeHolder,
                            serviceType: value,
                          });
                          updateParams((prev) => ({
                            ...prev,
                            page: 0
                          }));
                          if (value === "all") {
                            setPopulate(
                              iSSpecialtySelected ? `["doctorSpecialityFilter","doctorFacilityFilter","doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]` : `["doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]`
                            );
                            const {
                              ["$doctorFacilityFilter.service_type$"]: a,
                              ["$doctorFacilityFilter.status$"]: b,
                              ...rest
                            } = where;
                            setWhere(rest);
                          } else {
                            setPopulate(
                              `["doctorSpecialityFilter","doctorFacilityFilter","doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]`
                            );
                            setWhere({
                              ...where,
                              "$doctorFacilityFilter.service_type$": value,
                              "$doctorFacilityFilter.status$": 1,
                            });
                          }
                        }}
                        id="exampleSelect"
                        value={placeHolder?.serviceType}
                      >
                        <option value="all">All</option>
                        <option value="in_clinic">In Clinic</option>
                        <option value="video_visit">Video Visit</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Sponsored</label>
                      <Input
                        type="select"
                        name="select"
                        onChange={({ target: { value } }) => {
                          if (value === "all") {
                            const { is_sponsored, ...rest } = where;
                            setWhere(rest);
                          } else {
                            setWhere({ ...where, is_sponsored: value });
                          } updateParams((prev) => ({
                            ...prev,
                            page: 0
                          }));
                        }}
                        id="exampleSelect"
                        value={where?.is_sponsored || ""}
                      >
                        <option value="all">All</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Admin verification</label>
                      <Input
                        type="select"
                        name="select"
                        onChange={({ target: { value } }) => {
                          if (value) {
                            updateParams((prev) => ({
                              page: 0,
                              is_license_expired: "All",
                              search: "",
                              doctor_status: value
                            }));
                          } else {
                            updateParams((prev) => ({
                              page: 0,
                              is_license_expired: "All",
                              search: "",
                              doctor_status: ""
                            }));
                          }

                        }}
                        id="exampleSelect"
                        value={status}
                      >
                        <option value="">All</option>
                        <option value="active">Verified</option>
                        <option value="pending">Pending</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>License Expired Doctor</label>
                      <Input
                        type="select"
                        name="select"
                        onChange={({ target: { value } }) => {
                          updateParams((prev) => ({
                            ...prev,
                            is_license_expired: value,
                            page: 0
                          }));
                        }}
                        id="exampleSelect"
                        value={licenseStatus}
                      >
                        <option value="All">All</option>
                        <option value="Active">Active</option>
                        <option value="Expired">Expired</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="2">
                    <FormGroup>
                      <Button
                        className="btn-round  mb-0 mt-4 ml-2"
                        color="clear"
                        type="button"
                        onClick={() => {
                          setPlaceholder({
                            speciality: "all",
                            serviceType: "all",
                          });
                          setPopulate(
                            `["doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]`
                          );
                          setWhere({});
                          setServiceType("");
                          updateParams((prev) => ({
                            page: 0,
                            is_license_expired: "All",
                            search: "",
                            doctor_status: ""
                          }));
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card style={{ overflowY: "scroll" }}>
              <CardBody>
                <Table>
                  {doctors.length != 0 && (
                    <thead className="text-primary">
                      <tr>
                        <th>
                          Name{" "}
                          <i
                            className={`fas ${sort.name === "full_name" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortDoctors("full_name")}
                          />
                        </th>
                        <th>
                          NPI number #
                          <i
                            className={`fas ${sort.name === "npi" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortDoctors("npi")}
                          />
                        </th>
                        <th>
                          Phone
                          <i
                            className={`fas ${sort.name === "npi" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortDoctors("phone")}
                          />
                        </th>
                        <th>
                          Email
                          <i
                            className={`fas ${sort.name === "npi" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortDoctors("email")}
                          />
                        </th>
                        <th className="avoid-hand-cursor">Rating</th>
                        <th className="avoid-hand-cursor"># of Appt</th>
                        <th className="avoid-hand-cursor">Primary specialty</th>
                        <th className="avoid-hand-cursor">Secondary specialty</th>
                        <th className="avoid-hand-cursor">Service Type</th>
                        <th className="avoid-hand-cursor">License</th>
                        <th className="text-right">
                          Sponsored{" "}
                          <i
                            className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortDoctors("is_sponsored")}
                          />
                        </th>
                        <th className="text-right avoid-hand-cursor">Status</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {doctors?.map((item, id) => (
                      <tr key={id}>
                        <td>
                          {" "}
                          <Link to={"/admin/doctors/" + item.uid + "?active=1"}>
                            {item.full_name}
                          </Link>
                        </td>
                        <td>{item.npi || "N/A"}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>
                          <i className="fas fa-star text-black coloro" />{" "}
                          {item.doctor_avg_rating}
                        </td>
                        <td>{item.appt_count}</td>
                        <td>
                          {Array.isArray(item.doctorSpeciality) &&
                            item.doctorSpeciality.length > 0 &&
                            item.doctorSpeciality.filter(
                              (v) => v.is_primary === "1"
                            ).length > 0 ? (
                            <>
                              {
                                item.doctorSpeciality.filter(
                                  (v) => v.is_primary === "1"
                                )[0].speciality.name
                              }
                            </>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {Array.isArray(item.doctorSpeciality) &&
                            item.doctorSpeciality.length > 0 &&
                            item.doctorSpeciality.filter(
                              (v) => v.is_primary === "2"
                            ).length > 0 ? (
                            <>
                              {
                                item.doctorSpeciality.filter(
                                  (v) => v.is_primary === "2"
                                )[0].speciality.name
                              }
                            </>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {Array.isArray(item.doctorFacility) &&
                            item.doctorFacility.length > 0 &&
                            item.doctorFacility.filter(
                              (v) =>
                                v.service_type === "video_visit" &&
                                v.status === "1"
                            ).length > 0 && (
                              <>
                                <i
                                  className="fas fa-video text-black"
                                  title="Video Visit"
                                />{" "}
                              </>
                            )}
                          {Array.isArray(item.doctorFacility) &&
                            item.doctorFacility.length > 0 &&
                            item.doctorFacility.filter(
                              (v) =>
                                v.service_type === "in_clinic" &&
                                v.status === "1"
                            ).length > 0 && (
                              <>
                                <i
                                  className="fas fa-hospital text-black"
                                  title="In Clinic"
                                />{" "}
                              </>
                            )}
                          {Array.isArray(item.doctorFacility) &&
                            item.doctorFacility.length > 0 &&
                            item.doctorFacility.filter((v) => v.status === "1")
                              .length === 0 && <>N/A</>}
                          {Array.isArray(item.doctorFacility) &&
                            item.doctorFacility.length === 0 &&
                            "N/A"}
                        </td>
                        <td>
                          <span className="danger badge badge-red_">
                            {item?.expired_license +
                              " / " +
                              item?.total_license}
                          </span>
                        </td>
                        <td>
                          {item.is_sponsored === "0" && <>No</>}
                          {item.is_sponsored === "1" && <>Yes</>}
                        </td>
                        <td className="text-right">
                          <Status item={item} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {doctors.length === 0 && <Empty msg="No Data" />}
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <div className="justify-content-end d-flex">
              <ReactPaginate
                pageCount={pageCount}
                forcePage={parseInt(page)}
                // marginPagesDisplayed={2}
                // pageRangeDisplayed={5}
                containerClassName="pagination-style"
                previousClassName="pagination-prev"
                nextClassName="pagination-next"
                pageClassName="pagination-btn"
                breakClassName="pagination-btn"
                activeClassName="pagination-active"
                onPageChange={({ selected }) => {
                  updateParams((prev) => ({
                    ...prev,
                    page: parseInt(selected),
                  }));
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Doctors;
