import React, { useState, useEffect } from "react";
import {
  UncontrolledAlert,
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Badge,
  FormGroup,
  Form,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import moment from "moment";
import Empty from "../components/Empty";
import { decryptField } from "utils/helper";
import { Notifications } from "utils/notification";
const Dashboard = ({ }) => {
  const { state, setRead } = React.useContext(Notifications);
  const [appointments, setAppointments] = useState([]);
  const [details, setDetails] = useState({});
  const [revenue, setRevenue] = useState({});
  const { addToast } = useToasts();
  const [isLoading, setLoading] = useState(false);
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });

  useEffect(() => {
    fetchAppointments();
    fetchDetails();
    fetchRevenue();
  }, []);

  const fetchAppointments = async () => {
    try {
      const API = new ApiService();
      setLoading(true);
      let where = {};
      // if (search) where["appt_id"] = { $like: `%${search}%` };
      // if (status) where["status"] = { $eq: status };
      const {
        data: { appointments, count, limit },
      } = await API._GET({
        api: "/user/appointments",
        params: {
          sort:
            sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
          where,
          populate: `["user", "doctor","transactions"]`,
        },
      });
      // console.log(appointments);
      setAppointments(appointments);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const fetchDetails = async () => {
    try {
      const API = new ApiService();
      setLoading(true);
      const { data } = await API._GET({
        api: "/user/dashboard",
        params: {},
      });
      localStorage.setItem("pending_count", data.doctorPendingCount || 0)
      setDetails(data);
      setLoading(false);
      setRead({
        specialties: data?.unreadSpeciality,
        services: data?.unreadService,
      });
    } catch (error) {
      setLoading(false);
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const fetchRevenue = async () => {
    try {
      const API = new ApiService();
      setLoading(true);
      const { data } = await API._GET({
        api: "/payments/revenue",
        params: {},
      });
      // console.log(data);
      setRevenue(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [sort]);

  const sortAppointments = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  return (
    <>
      <div className="content">
        <Row className="flex-column flex-md-row">
          <Col>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col className="p-0">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-user-md text-warning" />
                    </div>
                  </Col>
                  <Col>
                    <div className="numbers">
                      <p className="card-category">
                        Number
                        <br /> of <br />
                        Doctors
                      </p>
                      <CardTitle tag="p">
                        {details.doctorActiveCount
                          ? details.doctorActiveCount
                          : 0}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <a
                  href={`/admin/doctors`}
                  style={{ textDecoration: "none" }}
                > <Button color="link" className="new-dark-blue text-primary">

                    {" View more"}
                    {" "}
                    <i className="fas fa-chevron-right" />
                  </Button>
                </a>
              </CardFooter>
            </Card>
          </Col>{" "}
          <Col>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col className="p-0">
                    <div className="icon-big text-center icon-danger">
                      <img
                        src="/verified.png"
                        style={{
                          width: "36px",
                          maxWidth: "fit-content",
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="numbers">
                      <p className="card-category">
                        Doctors <br />
                        Approval <br />
                        Pending
                      </p>
                      <CardTitle tag="p">
                        {details.doctorPendingCount || 0}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <a
                  href={`/admin/doctors?doctor_status=pending`}
                  style={{ textDecoration: "none" }}
                > <Button color="link" className="new-dark-blue text-primary">

                    {" View more"}
                    {" "}
                    <i className="fas fa-chevron-right" />
                  </Button> </a>
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col className="icon-big text-center icon-success p-0">
                    <i className="fas fa-user text-success" />
                  </Col>

                  <Col className="numbers">
                    <p className="card-category">
                      Number
                      <br /> of <br />
                      Patients
                    </p>
                    <CardTitle tag="p">
                      {details.patientCount ? details.patientCount : 0}
                    </CardTitle>
                    <p />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <a
                  href={`/admin/patients`}
                  style={{ textDecoration: "none" }}
                >  <Button color="link" className="new-dark-blue text-primary">

                    {" View more"}
                    {" "}
                    <i className="fas fa-chevron-right" />
                  </Button></a>
              </CardFooter>
            </Card>
          </Col>
          {/* <Col lg="2" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-danger">
                      <i className="fas fa-hospital text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Number of Clinics</p>
                      <CardTitle tag="p">
                        {details.clinicCount ? details.clinicCount : 0}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Button color="link" className="new-dark-blue">
                  <Link
                    to={`/admin/clinics`}
                    style={{ textDecoration: "none" }}
                  >
                  {" View more"}
                  </Link>{" "}
                  <i className="fas fa-chevron-right" />
                </Button>
              </CardFooter>
            </Card>
          </Col> */}
          <Col>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5" className="p-0">
                    <div className="icon-big text-center new-dark-blue">
                      <i className="fas fa-calendar-check text-blue" />
                    </div>
                  </Col>
                  <Col md="8" xs="7" className="pl-0 pr-2">
                    <div className="numbers">
                      <p className="card-category">
                        Number <br />
                        of <br />
                        Appointments
                      </p>
                      <CardTitle tag="p">
                        {details.appointmentCount
                          ? details.appointmentCount
                          : 0}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <a
                  href={`/admin/appointments`}
                  style={{ textDecoration: "none" }}
                >  <Button color="link" className="new-dark-blue text-primary">

                    {" View more"}
                    {" "}
                    <i className="fas fa-chevron-right" />
                  </Button>  </a>
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5" className="p-0">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-money-bill-wave text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        Total <br />
                        Amount
                        <br /> Paid
                      </p>

                      <p />
                    </div>
                  </Col>
                  <div className="w-100 d-flex justify-content-end pr-1">
                    <p style={{
                      fontSize: "2em",
                      textAline: "end",
                      margin: 0
                    }}>
                      ${revenue.lifetime_total
                        ? revenue.lifetime_total
                        : 0}
                    </p></div>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <a
                  href={`/admin/payments`}
                  style={{ textDecoration: "none" }}
                ><Button color="link" className="new-dark-blue text-primary">

                    {" View more"}
                    {" "}
                    <i className="fas fa-chevron-right" />
                  </Button></a>
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5" className="p-0">
                    <div className="icon-big text-center icon-warning position-relative">
                      <i className="fas fa-user-md text-warning position-relative" />
                      {details.expiredCount > 0 && (
                        <i
                          className="fas fa-exclamation-circle text-primary"
                          style={{
                            position: "absolute",
                            right: 12,
                            top: -14,
                            fontSize: 15,
                          }}
                        />
                      )}
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        License <br />Expired <br />
                        Doctors
                      </p>
                      <CardTitle tag="p">
                        {details.expiredCount ? details.expiredCount : 0}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <a
                  href={`/admin/doctors?page=0&is_license_expired=Expired&search=`}
                  style={{ textDecoration: "none" }}
                ><Button color="link" className="new-dark-blue text-primary">

                    {" View more"}
                    {" "}
                    <i className="fas fa-chevron-right" />
                  </Button></a>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  {appointments.length != 0 && (
                    <thead className="text-primary">
                      <tr>
                        <th>
                          Appointment ID{" "}
                          <i
                            className={`fas ${sort.name === "appt_id" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments("appt_id")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th class="avoid-hand-cursor">Patient Name </th>
                        <th class="avoid-hand-cursor">Doctor Name </th>
                        <th>
                          Appointment Date & Time{" "}
                          <i
                            className={`fas ${sort.name === "service_at_date"
                              ? sort.icon
                              : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments("service_at_date")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th class="avoid-hand-cursor">Amount </th>
                        <th class="avoid-hand-cursor">
                          Type{" "}
                          {/* <i
                          className={`fas ${
                            sort.name === "full_name" ? sort.icon : "fa-sort"
                          } text-black`}
                          onClick={() => sortAppointments("appt_type")}
                          style={{ cursor: "pointer" }}
                        /> */}
                        </th>
                        <th className="text-right">
                          Status{" "}
                          <i
                            className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments("status")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {appointments.map((appointment) => (
                      <tr key={appointment.uid}>
                        <td>
                          <Link to={`/admin/appointments/${appointment.uid}`}>
                            {appointment.appt_id || "N/A"}
                          </Link>
                        </td>
                        <td>
                          {appointment.someone_else === "1" &&
                            decryptField(appointment.full_name)}
                          {(appointment.someone_else != "1" &&
                            appointment.user &&
                            decryptField(appointment.user.full_name)) ||
                            (appointment.someone_else != "1" && "N/A")}
                        </td>
                        <td>
                          {(appointment.doctor &&
                            appointment.doctor.full_name) ||
                            "N/A"}
                        </td>
                        <td>
                          {" "}
                          {moment(appointment.service_at_date).format(
                            "MM/DD/YY - hh:mm a"
                          )}{" "}
                        </td>
                        <td>
                          $
                          {appointment.transactions &&
                            appointment.transactions.collected_amount}
                        </td>
                        <td>
                          {appointment.booking_type === "video_visit" && (
                            <>
                              <i className="fas fa-video text-black" />{" "}
                            </>
                          )}
                          {appointment.booking_type === "in_clinic" && (
                            <>
                              <i className="fas fa-hospital text-black" />{" "}
                            </>
                          )}
                        </td>
                        <td className="text-right">
                          {appointment.status === "completed" && (
                            <Badge color="success">Completed</Badge>
                          )}
                          {appointment.status === "pending" && (
                            <Badge color="warning">Pending</Badge>
                          )}
                          {appointment.status === "ongoing" && (
                            <Badge color="dark">On Going</Badge>
                          )}
                          {appointment.status === "cancelled" && (
                            <Badge color="danger">Canceled</Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {appointments.length === 0 && <Empty msg="No Data" />}
              </CardBody>
            </Card>
          </Col>

          <Col md="12">
            <Link
              to={`/admin/appointments`}
              className="text-white"
              style={{ textDecoration: "none" }}
            >
              <div className="update text-right ">
                <Button className="btn-round" color="bluedark" type="submit">
                  {"MORE"}
                  <i className="fas fa-chevron-right" />
                </Button>
              </div>{" "}
            </Link>{" "}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
