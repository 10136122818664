import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  label,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { isNumber } from "../utils/helper";

const AddService = ({ is_open, onClose, isLoading, onSubmit, data }) => {
  const [service, setService] = useState("");
  const [cpt_code, setCptCode] = useState("");
  const [cost, setCost] = useState("");
  const [validation, setValidation] = useState({
    service: undefined,
    cpt_code: undefined,
    cost: undefined,
  });
  const [processing, setSubmit] = useState(false);

  const isValidateForm = (service, cost) => {
    let errors = {};
    if (!service.length) errors.service = "Please enter service";
    if (!cost) errors.cost = "Please enter cost";
    if (cost && isNaN(cost)) errors.cost = "Please enter valid cost";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };

  const submit = () => {
    setSubmit(true);
    if (!isValidateForm(service, cost)) {
      setSubmit(false);
      return;
    }
    onSubmit({ service, cpt_code, cost });
    setService("");
    setCptCode("");
    setCost("");
  };

  useEffect(() => {
    if (data) {
      setService(data.name);
      setCptCode(data.cpt);
      setCost(data.cost);
    }
  }, [data]);

  const toggle = () => onClose();

  return (
    <div>
      <Modal isOpen={is_open} toggle={toggle}>
        <ModalHeader toggle={toggle} className="modeltitle">
          <b> {data ? "Edit" : "Add"} Service</b>
        </ModalHeader>
        <ModalBody className="text-center pb-2">
          <FormGroup className="text-left mt-5 pb-3">
            <div>
              <label>Service Name</label>
              <Input
                placeholder="Service"
                type="text"
                value={service}
                valid={!validation.service && !!service}
                invalid={!!validation.service}
                onChange={async ({ target: { value } }) => {
                  if (value?.length <= 50) {
                    if (service?.length > 0) {
                      await setService(value);
                    } else {
                      await setService(value.replace(/^\s+|\s+$/gm, ""));
                    }
                    value && isValidateForm(value, cost);
                  }
                }}
              />
              <FormFeedback>{validation.service}</FormFeedback>
            </div>
            <br />
            {/* <div>
              <label>CPT Code</label>
              <Input
                placeholder="CPT Code"
                type="text"
                value={cpt_code}
                onChange={({ target: { value } }) => {
                  if (value.length <= 10) setCptCode(value);
                }}
              />
            </div>
            <br /> */}
            <div>
              <label>Default Amount</label>
              <Input
                placeholder="Default Amount"
                type="number"
                value={cost}
                valid={!validation.cost && !!cost}
                invalid={!!validation.cost}
                onChange={({ target: { value } }) => {
                  // Parse the input value as a number
                  const numericValue = parseFloat(value);

                  // Check if the numeric value is valid and greater than or equal to 1
                  if (
                    (!isNaN(numericValue) && numericValue >= 1 && numericValue <= 9999) ||
                    value === '' // Allow empty value
                  ) {
                    // Check for non-empty, non-whitespace value or empty string
                    if (value.trim().length > 0 || value === '') {
                      // Update the state with trimmed non-empty value or empty string
                      setCost(value.trim());
                      // Perform form validation with the validated value
                      isValidateForm(service, value.trim());
                    }
                  }
                }}
              />

              <FormFeedback>{validation.cost}</FormFeedback>
            </div>

            <Button
              className="btn-round  btn-block mt-4"
              color="info"
              type="button"
              onClick={submit}
              disabled={!service || !cost}
            >
              {isLoading && (
                <Spinner
                  style={{ width: "1rem", height: "1rem" }}
                  // type="grow"
                  color="dark"
                />
              )}
              {data ? "Save" : "Add"}{" "}
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddService;
