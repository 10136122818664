import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AdminLayout from "layouts/Admin.js";
import { ToastProvider } from "react-toast-notifications";

import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import Verification from "./views/Verification";
import SetNewPassword from "./views/SetNewPassword";
import SuccessLogin from "./views/SuccessLogin";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ToastProvider>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/verification" component={Verification} />
        <Route path="/set-new-password" component={SetNewPassword} />
        <Route path="/success-login" component={SuccessLogin} />
        <Redirect to="/admin/dashboard" />
      </Switch>
    </ToastProvider>
  </Router>,
  document.getElementById("root")
);
