import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, Nav } from "reactstrap";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import Item from "components/Notification/Item";
import { ItemLoading } from "components/Notification/Item";
import groupByDate from "utils/groupBydate";
import moment from "moment";
import ConfirmPopup from "components/ConfirmPopup";
import APIService from "../services/APIService";
import Empty from "components/Empty";

const Notification = () => {
  const { addToast } = useToasts();
  const [notification, updateNotification] = useState([]);
  const [showClear, setShowClear] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    fetchNotification();
  }, []);
  useEffect(() => {
    fetchNotification();
  }, [reload]);

  const fetchNotification = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: "/notificationLog",
    })
      .then(({ data }) => {
        const notifications = groupByDate(data.notifications);
        updateNotification(
          Object.entries(notifications).map((i) => ({
            date: i[0],
            notifications: i[1],
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        addToast(error.data.message || "Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return (
    <>
      {" "}
      <ConfirmPopup
        msg="Are you sure you want to clear notifications!"
        desc=""
        is_open={showClear}
        onConfirm={() => {
          const API = new APIService();
          setClearing(true);
          API._PUT({
            api: "/notificationLog/dummy",
          })
            .then(({ data }) => {
              setReload((prev) => !prev);
              setClearing(false);
              setShowClear(false);
            })
            .catch((error) => {
              setClearing(false);
              addToast(error.data.message || "Something went wrong.", {
                appearance: "error",
                autoDismiss: true,
              });
            });
        }}
        onClose={() => setShowClear(false)}
        loading={clearing}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                {!isLoading && !notification?.at(0) && <Empty msg="No Data" />}
                {isLoading &&
                  [...Array(100)].map((i) => <ItemLoading key={i} />)}
                {notification?.at(0) &&
                  notification?.map(({ date, notifications }, i) => (
                    <>
                      <Nav
                        tabs
                        className="mt-2 mr-2 mb-4  nav-item-services d-flex justify-content-between"
                      >
                        <h5 className="text-dark s ">
                          {moment(date).calendar(null, {
                            sameDay: "[Today]",
                            nextDay: "[Tomorrow]",
                            nextWeek: "dddd",
                            lastDay: "[Yesterday]",
                            lastWeek: "[Last] dddd",
                            sameElse: "MM/DD/YYYY",
                          })}
                        </h5>
                        {i=== 0 && (
                          <b
                            className="text-primary"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowClear(true);
                            }}
                          >
                            Clear All
                          </b>
                        )}
                      </Nav>
                      {notifications.map((n, i) => (
                        <Item key={i} {...n} index={i} />
                      ))}
                    </>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Notification;
