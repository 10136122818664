import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input, Spinner
} from "reactstrap";
import { useHistory } from "react-router-dom";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";

const ForgotPassword = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const forgotPassword = async () => {
    const API = new ApiService();
    setLoading(true);
    API._POST({
      api: "/auth/password/forgot",
      body: {
        email
      },
    })
      .then(({ data }) => {
        localStorage.setItem('session_id', data.session_id)
        localStorage.setItem('email', email)
        setLoading(false);
        history.push("/verification");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  // useEffect(() => {
  // }, [email]);
  return (
    <div className='panelLogin'>
      <div className="content">
        <Row>
          <Col className="d-flex justify-content-center " md="12" >
            <Card className="col-lg-3 col-md-4">
              <CardBody>  <Row>
                <Col className="mb-3">
                  <div className="text-center mt-5 mb-5">
                    <img src={require("assets/img/logo.svg")} className="imglogo" />
                  </div>
                  <FormGroup>
                    <div className="intro">
                      <h2>Forgot Password?</h2>
                      <p>Please enter your email associated with your account. We will send you the verification code to reset your password.</p>
                    </div>
                    <div className="mb-2">
                      <label>Email</label>
                      <Input placeholder="Enter Email" type="text"
                        onChange={({ target: { value } }) => {
                          setEmail(value)
                        }} />
                    </div>
                    <Button className="btn-round  btn-block mt-4" color="info" type="submit" onClick={forgotPassword} > {isLoading && (
                      <Spinner
                        style={{ width: "1rem", height: "1rem" }}
                        // type="grow"
                        color="dark"
                      />
                    )}  CONTINUE </Button>
                  </FormGroup>
                </Col>
              </Row></CardBody></Card></Col>
        </Row>
      </div>
    </div>
  );
}

export default ForgotPassword;