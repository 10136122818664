import moment from "moment";
import React from "react";

function groupByDate(groups) {
  return groups.reduce((acc, curr) => {
    const dateKey = moment(curr.created_at).format("YYYY-MM-DD");
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(curr);
    return acc;
  }, {});
}

export default groupByDate;
