// reactstrap components
import { Button, FormGroup, FormFeedback, Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import React, { useState, useEffect } from "react";
import ApiService from "../services/APIService";
import moment from "moment";
import { decryptField } from "utils/helper";

const EnqDetail = () => {
  const [enqDetails, setEnqDetails] = useState({});
  const [notes, setNotes] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingOne, setLoadingOne] = useState(false);
  const { addToast } = useToasts();
  let { id } = useParams();

  useEffect(() => {
    getEnquiryDetails();
  }, []);

  const isValidateFormPassword = () => {
    if (!notes)
      addToast("Add a note before submitting", {
        appearance: "error",
        autoDismiss: true,
      });
    else return true;
  };

  const getEnquiryDetails = async () => {
    try {
      const API = new ApiService();
      const {
        data: { enquiry },
      } = await API._GET({
        api: `/enquiry/${id}`,
        params: {
          populate: `["user","enquiryReply"]`,
        },
      });
      setEnqDetails(enquiry);
    } catch (error) {
      // alert(error);
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const addNotes = async () => {
    if (!isValidateFormPassword()) {
      return;
    }
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/enquiry/${id}`,
      body: {
        notes,
        note_added_date: moment().format(),
      },
    })
      .then(() => {
        getEnquiryDetails();
        setLoading(false);
        setNotes("");
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const changeStatus = async (status) => {
    const API = new ApiService();
    setLoadingOne(true);
    API._PUT({
      api: `/enquiry/${id}`,
      body: {
        status: status,
      },
    })
      .then(() => {
        getEnquiryDetails();
        setLoadingOne(false);
        addToast("Status Updated", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoadingOne(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return (
    <>
      {!!enqDetails.user && (
        <div>
          <div className="enqRow word_break">
            <div className="enqDetail">
              <div className="enqHd">
                <div className="enqHdL">
                  <h2>Enquiry Detail</h2>
                </div>
                <div className="enqHdR">
                  {!enqDetails.status ? (
                    <button
                      onClick={() => changeStatus(true)}
                      style={{
                        backgroundColor: "#307dd6",
                      }}
                      disabled={isLoadingOne}
                    >
                      {isLoadingOne && (
                        <Spinner
                          style={{ width: "1rem", height: "1rem" }}
                          color="light"
                        />
                      )}
                      {" Mark as done"}
                    </button>
                  ) : (
                    <button
                      onClick={() => changeStatus(false)}
                      disabled={isLoadingOne}
                    >
                      {isLoadingOne && (
                        <Spinner
                          style={{ width: "1rem", height: "1rem" }}
                          color="light"
                        />
                      )}
                      {" Completed"}
                    </button>
                  )}
                </div>
              </div>
              <div className="enqBody">
                <div className="row py-2">
                  <div className="col-lg-3 enqFld">
                    <span>Name:</span>
                  </div>
                  <div className="col-lg-4 ansEnq">
                    <span>
                      {enqDetails.user.full_name
                        ? enqDetails.user.role_id === 2
                          ? decryptField(enqDetails.user.full_name)
                          : enqDetails.user.full_name
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-lg-3 enqFld">
                    <span>Email :</span>
                  </div>
                  <div className="col-lg-4 ansEnq">
                    <span>
                      {enqDetails.user.email
                        ? enqDetails.user.role_id === 2
                          ? decryptField(enqDetails.user.email)
                          : enqDetails.user.email
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-lg-3 enqFld">
                    <span>Phone Number :</span>
                  </div>
                  <div className="col-lg-4 ansEnq">
                    <span>
                      {enqDetails.phone
                        ? formatPhoneNumber(enqDetails.phone)
                          ? formatPhoneNumber(enqDetails.phone)
                          : enqDetails.phone
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-lg-3 enqFld">
                    <span>Subject :</span>
                  </div>
                  <div className="col-lg-4 ansEnq">
                    <span>
                      {enqDetails.subject ? enqDetails.subject : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-lg-3 enqFld">
                    <span>Enquiry Date & Time :</span>
                  </div>
                  <div className="col-lg-4 ansEnq">
                    <span>
                      {enqDetails.created_at
                        ? moment(enqDetails.created_at).format(
                          "MM/DD/YY - hh:mm A"
                        )
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="enqFooter p-3 text-justify">
                {/* <span>Hi Aaminah,</span> */}
                <span style={{ whiteSpace: "pre-line" }}>
                  {enqDetails.message ? enqDetails.message : "N/A"}
                </span>
              </div>
            </div>
            {enqDetails?.enquiryReply?.map((enq, i) => (
              <div className="note" key={i}>
                <div className="noteWrp">
                  <div className="noteTp">
                    <label htmlFor="">Reply</label>
                    <span style={{ color: "#a9a9a9" }}>
                      {enq.created_at
                        ? moment(enq.created_at).format("MM/DD/YY - hh:mm A")
                        : "N/A"}
                    </span>
                  </div>
                  <div className="btntext">
                    <p
                      className="p-3 text-justify"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {enq.reply}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div className="note">
              <div className="noteWrp">
                <label htmlFor="">Reply</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Add your text here..."
                  onChange={({ target: { value } }) => {
                    if (notes.length > 0) {
                      setNotes(value);
                    } else {
                      setNotes(value.replace(/^\s+|\s+$/gm, ""));
                    }
                  }}
                  value={notes}
                ></textarea>
                <div className="btnNote">
                  <button className="ntBtn" onClick={addNotes}>
                    {" "}
                    {isLoading && (
                      <Spinner
                        style={{ width: "1rem", height: "1rem" }}
                        // type="grow"
                        color="dark"
                      />
                    )}{" "}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EnqDetail;
