import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  label,
  Spinner,
  FormFeedback,
} from "reactstrap";

const AddSpeciality = ({ is_open, onClose, isLoading, onSubmit, data }) => {
  const [speciality, setSpeciality] = useState("");
  const [validation, setValidation] = useState({
    speciality: undefined,
  });
  const [processing, setSubmit] = useState(false);

  const isValidateForm = (value) => {
    let errors = {};
    if (!value) errors.speciality = "Please enter specialty";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };

  const submit = () => {
    setSubmit(true);
    if (!isValidateForm(speciality)) {
      setSubmit(false);
      return;
    }
    onSubmit(speciality);
    setSpeciality("");
  };

  useEffect(() => {
    if (data) setSpeciality(data.name);
  }, [data]);

  const toggle = () => onClose();

  return (
    <div>
      <Modal isOpen={is_open} toggle={toggle}>
        <ModalHeader toggle={toggle} className="modeltitle">
          <b> {data ? "Edit" : "Add "} specialty</b>
        </ModalHeader>
        <ModalBody className="text-center pb-2">
          <FormGroup className="text-left mt-5 pb-3">
            <div>
              <label>Specialty Name</label>
              <Input
                placeholder="Specialty"
                type="text"
                value={speciality}
                valid={!validation.speciality && speciality}
                invalid={!!validation.speciality}
                onChange={({ target: { value } }) => {
                  if (value.length <= 50) setSpeciality(value);
                  value && isValidateForm(value);
                }}
              />
              <FormFeedback>{validation.speciality}</FormFeedback>
            </div>

            <Button
              className="btn-round  btn-block mt-4"
              color="info"
              type="button"
              onClick={submit}
            >
              {isLoading && (
                <Spinner
                  style={{ width: "1rem", height: "1rem" }}
                  // type="grow"
                  color="dark"
                />
              )}
              {data ? "Save" : "Add "}
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddSpeciality;
