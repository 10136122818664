import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, Row, Col, Table, Badge } from "reactstrap";
import ApiService from "services/APIService";
import { useToasts } from "react-toast-notifications";
import ReactPaginate from "react-paginate";
import Empty from "components/Empty";
import useUrlState from "utils/useUrlState";
import Status from "components/Donctor/Status";

const Doctors = ({ id }) => {
  const { search: searchSting } = useLocation();
  const { addToast } = useToasts();
  const [doctors, setDoctors] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const [serviceType, setServiceType] = useState("");
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });
  const [where, setWhere] = useState({});
  const [populate, setPopulate] = useState(
    `["doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]`
  );
  const [params, updateParams, isStateSet] = useUrlState({
    page: 0,
    is_license_expired: "All",
    search: "",
  });
  const { is_license_expired: licenseStatus, page, search } = params;

  useEffect(() => {
    if (!!status) {
      where["status"] = { $eq: status };
    } else {
      const { status, ...reset } = where;
      setWhere(reset);
    }
  }, [status]);
  useEffect(() => {
    if (licenseStatus === "Active") {
      where["is_license_expired"] = false;
    } else if (licenseStatus === "Expired") {
      where["is_license_expired"] = true;
    } else if (licenseStatus === "All") {
      const { is_license_expired, ...reset } = where;
      setWhere(reset);
    }
  }, [licenseStatus]);
  const fetchDoctors = async () => {
    setDoctors([]);
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: "/doctor",
      params: {
        // select: '["uid","full_name","npi","phone","email","status"]',
        sort:
          sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
        where: { ...where, clinic_id: id },
        populate,
        offset: page * perPage,
        search,
        limit: perPage,
      },
    })
      .then(({ data }) => {
        setDoctors(data.doctors);
        setPerPage(data.limit);
        setPageCount(Math.ceil(data.count / data.limit));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const sortDoctors = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  useEffect(() => {
    setPendingCount(doctors.filter((item) => item.status === "pending").length);
  }, [doctors]);
  useEffect(() => {
    if (isStateSet && licenseStatus != undefined) fetchDoctors();
  }, [sort, serviceType, where, populate, status, params, isStateSet]);

  // const getSpecialities = (docSpec) => {
  //   if (docSpec) {
  //     const arr = docSpec.map((item) => {
  //       return item.speciality ? item.speciality["name"] : "";
  //     });
  //     return arr.length > 1 ? arr[0] + `  +${arr.length - 1}` : arr[0] || "N/A";
  //   } else {
  //     return "NA";
  //   }
  // };
  const [placeHolder, setPlaceholder] = useState({
    speciality: "all",
    serviceType: "all",
  });
  return (
    <Row>
      <Col md="12">
        <Card style={{ overflowY: "scroll" }}>
          <CardBody>
            <Table>
              {doctors.length != 0 && (
                <thead className="text-primary">
                  <tr>
                    <th>
                      Name{" "}
                      <i
                        className={`fas ${sort.name === "full_name" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortDoctors("full_name")}
                      />
                    </th>
                    <th>
                      NPI number
                      <i
                        className={`fas ${sort.name === "npi" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortDoctors("npi")}
                      />
                    </th>
                    <th>
                      Phone
                      <i
                        className={`fas ${sort.name === "npi" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortDoctors("phone")}
                      />
                    </th>
                    <th>
                      Email
                      <i
                        className={`fas ${sort.name === "npi" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortDoctors("email")}
                      />
                    </th>
                    <th>Rating</th>
                    <th># of Appt</th>
                    <th>Primary specialty</th>
                    <th>Secondary specialty</th>
                    <th>Service Type</th>
                    <th>License</th>
                    <th className="text-right">
                      Sponsored{" "}
                      <i
                        className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortDoctors("is_sponsored")}
                      />
                    </th>
                    <th className="text-right">Status</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {doctors?.map((item, id) => (
                  <tr key={id}>
                    <td>
                      {" "}
                      <Link to={"/admin/doctors/" + item.uid + "?active=1"}>
                        {item.full_name}
                      </Link>
                    </td>
                    <td>{item.npi || "N/A"}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>
                      <i className="fas fa-star text-black coloro" />{" "}
                      {item.doctor_avg_rating}
                    </td>
                    <td>{item.appt_count}</td>
                    <td>
                      {Array.isArray(item.doctorSpeciality) &&
                        item.doctorSpeciality.length > 0 &&
                        item.doctorSpeciality.filter((v) => v.is_primary === "1")
                          .length > 0 ? (
                        <>
                          {
                            item.doctorSpeciality.filter(
                              (v) => v.is_primary === "1"
                            )[0].speciality.name
                          }
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {Array.isArray(item.doctorSpeciality) &&
                        item.doctorSpeciality.length > 0 &&
                        item.doctorSpeciality.filter((v) => v.is_primary === "2")
                          .length > 0 ? (
                        <>
                          {
                            item.doctorSpeciality.filter(
                              (v) => v.is_primary === "2"
                            )[0].speciality.name
                          }
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {Array.isArray(item.doctorFacility) &&
                        item.doctorFacility.length > 0 &&
                        item.doctorFacility.filter(
                          (v) =>
                            v.service_type === "video_visit" && v.status === "1"
                        ).length > 0 && (
                          <>
                            <i
                              className="fas fa-video text-black"
                              title="Video Visit"
                            />{" "}
                          </>
                        )}
                      {Array.isArray(item.doctorFacility) &&
                        item.doctorFacility.length > 0 &&
                        item.doctorFacility.filter(
                          (v) =>
                            v.service_type === "in_clinic" && v.status === "1"
                        ).length > 0 && (
                          <>
                            <i
                              className="fas fa-hospital text-black"
                              title="In Clinic"
                            />{" "}
                          </>
                        )}
                      {Array.isArray(item.doctorFacility) &&
                        item.doctorFacility.length > 0 &&
                        item.doctorFacility.filter((v) => v.status === "1")
                          .length === 0 && <>N/A</>}
                      {Array.isArray(item.doctorFacility) &&
                        item.doctorFacility.length === 0 &&
                        "N/A"}
                    </td>
                    <td>
                      <span className="danger badge badge-red_">
                        {item?.expired_license + " / " + item?.total_license}
                      </span>
                    </td>
                    <td>
                      {item.is_sponsored === "0" && <>No</>}
                      {item.is_sponsored === "1" && <>Yes</>}
                    </td>

                    <td className="text-right">

                      <Status item={item} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {doctors.length === 0 && <Empty msg="No Data" />}
          </CardBody>
        </Card>
      </Col>
      <Col md="12">
        <div className="justify-content-end d-flex">
          <ReactPaginate
            pageCount={pageCount}
            forcePage={parseInt(page)}
            // marginPagesDisplayed={2}
            // pageRangeDisplayed={5}
            containerClassName="pagination-style"
            previousClassName="pagination-prev"
            nextClassName="pagination-next"
            pageClassName="pagination-btn"
            breakClassName="pagination-btn"
            activeClassName="pagination-active"
            onPageChange={({ selected }) => {
              updateParams((prev) => ({
                ...prev,
                page: parseInt(selected),
              }));
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Doctors;
