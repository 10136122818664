import React, { useState, useEffect } from "react";
// react plugin used to create charts
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Button,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
// core components
import ReactPaginate from "react-paginate";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import Confirm from "../components/ConfirmPopup";
import classnames from "classnames";
import AddEditBoardCertifications from "components/AddEditBoardCertifications";
import Empty from "../components/Empty";

const BoardCertifications = () => {
  const { addToast } = useToasts();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [openAddService, setAddService] = useState(false);
  const [services, setServices] = useState([]);
  // const [suggestedServices, setSuggestedServices] = useState([]);
  const [openEditService, setEditService] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState({});
  const [sort, setSort] = useState({
    name: "",
    order: "",
    icon: "fa-sort",
  });

  const [isDelLoading, setDelLoading] = useState(false);
  const [delUid, setDeleteUid] = useState("");
  // const [delSuggUid, setSuggDeleteUid] = useState("");
  // const [acceptSuggUid, setSuggAcceptUid] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  // const [showAccept, setShowAccept] = useState(false);
  // const [showReject, setShowReject] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    const API = new ApiService();
    setLoading(true);
    let where = {};
    // if (search) where["name"] = { $like: `%${search}%` };
    API._GET({
      api: "/doctor/board-certificates/global",
      params: {
        sort:
          sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
        where,
        offset: page * perPage,
        limit: perPage,
        search,
      },
    })
      .then(({ data }) => {
        setServices(data.board_certificates);
        setPerPage(data.limit);
        setPageCount(Math.ceil(data.count / data.limit));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const addSpeciality = (data) => {
    const API = new ApiService();
    setLoading(true);
    console.log(data);
    API._POST({
      api: "/doctor/board-certificates/global",
      body: {
        active: true,
        name: data.service,
        cpt: data.cpt_code,
        cost: data.cost,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setAddService(false);
        fetchServices();
        addToast(" Board certification added successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const updateServices = (data) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/doctor/board-certificates/${editData.uid}`,
      body: {
        name: data.service,
        cpt: data.cpt_code,
        cost: data.cost,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setEditService(false);
        fetchServices();
        addToast(" Board certification updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    fetchServices();
  }, [search, page, sort]);

  const deleteServices = () => {
    const API = new ApiService();
    setDelLoading(true);
    API._DELETE({
      api: `/doctor/board-certificates/${delUid}`,
      body: {},
    })
      .then(({ data }) => {
        fetchServices();
        setDelLoading(false);
        setShowConfirm(false);
        addToast(" Board certification deleted successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setDelLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const sortName = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  return (
    <>
      <AddEditBoardCertifications
        is_open={openAddService}
        onClose={() => {
          setAddService(false);
        }}
        isLoading={isLoading}
        onSubmit={addSpeciality}
      />
      <AddEditBoardCertifications
        is_open={openEditService}
        onClose={() => {
          setEditService(false);
        }}
        isLoading={isLoading}
        onSubmit={updateServices}
        data={editData}
      />

      <Confirm
        msg="Are you sure you want to delete this board certification!"
        desc="This board certification will no longer be available"
        is_open={showConfirm}
        onConfirm={deleteServices}
        onClose={() => setShowConfirm(false)}
        loading={isDelLoading}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                {" "}
                <Row>
                  <Col className="pr-1" md="6">
                    <FormGroup>
                      <label>Search</label>
                      <Input
                        placeholder="Search by board"
                        type="text"
                        value={search}
                        onChange={({ target: { value } }) => {
                          setPage(0);
                          setSearch(value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="2">
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round  mb-0 mt-0 ml-2"
                        color="clear"
                        type="button"
                        onClick={() => {
                          setSearch("");
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </FormGroup>
                  </Col>

                  <Col className="pl-1 align-self-end " md="4">
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round float-right  "
                        color="info"
                        type="button"
                        onClick={() => {
                          setAddService(true);
                        }}
                      >
                        {" "}
                        Add Board Certifications{" "}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Nav tabs className="nav-item-services">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                    >
                      <b>Active Board Certifications</b>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {services.length > 0 && (
                      <div>
                        <Table responsive className="mt-3">
                          <thead className="text-primary">
                            <tr>
                              <th>
                                Board Name{" "}
                                <i
                                  className={`fas ${sort.name === "status"
                                    ? sort.icon
                                    : "fa-sort"
                                    } text-black`}
                                  onClick={() => sortName("name")}
                                  style={{ cursor: "pointer" }}
                                />
                              </th>
                              <th className="avoid-hand-cursor">Edit</th>
                              <th className="text-right avoid-hand-cursor">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {services?.map((service, index) => (
                              <tr key={index}>
                                <td>{service.name}</td>
                                <td>
                                  {" "}
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => {
                                      setEditData(service);
                                      setEditService(true);
                                    }}
                                  >
                                    <i className="fas fa-pen"></i>
                                  </button>{" "}
                                </td>
                                <td className="text-right">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setDeleteUid(service.uid);
                                      setShowConfirm(true);
                                    }}
                                    className="btn btn-link"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Col md="12">
                          <div className="justify-content-end d-flex ">
                            <ReactPaginate
                              pageCount={pageCount}
                              forcePage={page}
                              // marginPagesDisplayed={2}
                              // pageRangeDisplayed={5}
                              containerClassName="pagination-style"
                              previousClassName="pagination-prev"
                              nextClassName="pagination-next"
                              pageClassName="pagination-btn"
                              breakClassName="pagination-btn"
                              activeClassName="pagination-active"
                              onPageChange={(page) => {
                                setPage(page.selected);
                              }}
                            />
                          </div>
                        </Col>
                      </div>
                    )}
                    {services.length === 0 && (
                      <div>
                        <Empty />
                        <p className="text-center text-muted">
                          No Board Certifications
                        </p>
                      </div>
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BoardCertifications;
