import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Badge,
  Table,
} from "reactstrap";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from "react";
import ApiService from "../services/APIService";
import moment from "moment";
import { decryptField } from "utils/helper";

const Appointmentdetails = () => {
  const [res, setRes] = useState({});
  const [apptDetails, setApptDetails] = useState([]);
  const [review, setReview] = useState([]);
  const [bookedServices, setBookedServices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [docDetails, setDocDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [transaction, setTransaction] = useState({});
  const [logs, setLogs] = useState([]);
  const { addToast } = useToasts();
  let { uid } = useParams();
  useEffect(() => {
    getApptDetails();
  }, []);

  const getApptDetails = async () => {
    try {
      const API = new ApiService();
      const {
        data: { appointments, payment_status, review },
      } = await API._GET({
        api: `/user/appointments/${uid}`,
        params: {},
      });
      const {
        booked_services,
        doctor,
        user,
        transactions,
        extended_count,
        total_cost,
        booking_amount,
        ...appt
      } = appointments;
      fetchCallLogs({ id: appt.id });
      setApptDetails(appt);
      setRes(appointments);
      setTransaction({
        ...transactions,
        extended_count,
        total_cost,
        booking_amount,
      });
      setReview(review);
      setBookedServices(booked_services);
      setDocDetails(doctor);
      setPatientDetails(user);
      setPaymentDetails(payment_status);
    } catch (error) {
      // alert(error);
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const fetchCallLogs = ({ id }) => {
    const API = new ApiService();
    API._GET({
      api: `/user/calls/${id}`,
      params: {},
    })
      .then(({ data }) => {
        setLogs(data.data);
      })
      .catch((error) => {
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const renderStars = (count) => {
    let stars = [];
    let balance = 5 - count;
    for (var i = 0; i < count; i++) {
      stars.push(
        <i className="fas fa-star  coloro-review m-1" key={Math.random()} />
      );
    }
    for (var i = 0; i < balance; i++) {
      stars.push(
        <i className="fas fa-star  colorg-review m-1" key={Math.random()} />
      );
    }
    return stars;
  };

  useEffect(() => {
    let _t = 0;
    if (bookedServices.length > 0) {
      _t = bookedServices.reduce((total, a) => {
        return (total += +a.cost);
      }, 0);
    } else {
      _t = apptDetails.booking_amount;
    }
    setTotalAmount(_t);
  }, [bookedServices]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="9" xs="12">
            <Card className="card-user">
              <CardBody>
                <h5 className="mb-3 text-left p-2">
                  {" "}
                  Appointment ID: <b>{apptDetails.appt_id}</b>{" "}
                  {apptDetails.status === "completed" && (
                    <Badge color="success">Completed</Badge>
                  )}
                  {apptDetails.status === "pending" && (
                    <Badge color="warning">Pending</Badge>
                  )}
                  {apptDetails.status === "cancelled" && (
                    <Badge color="warning">Canceled</Badge>
                  )}
                </h5>
                <hr />

                <Row className="pb-3">
                  <Col lg="6" md="12" xs="12" className="appointdetails">
                    <h5 className="mb-0 text-left p-2">
                      {" "}
                      <b>Patient Details</b>{" "}
                    </h5>

                    <div className="d-flex align-items-center">
                      <img
                        className="avatar-ad border-gray mb-0 mr-3"
                        src={
                          patientDetails.image_url ||
                          "https://cashdocs.s3.us-east-2.amazonaws.com/placeholder.png"
                        }
                      />
                      <div>
                        <h5 className=" mb-0">
                          {patientDetails.full_name &&
                            decryptField(
                              res?.someone_else === "1"
                                ? res?.full_name
                                : patientDetails.full_name
                            )}
                        </h5>
                        {/* <p className="mb-0">
                          {" "}
                          <i className="fas fa-star coloro" /> <b>0/5</b>
                        </p> */}
                        {res?.someone_else === "0" && (
                          <p className="mb-0 description">
                            <b>
                              Booked On:{" "}
                              {moment(apptDetails.created_at).format(
                                "dddd MMMM DD, YYYY, h:mm a"
                              )}{" "}
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                    {res?.someone_else === "1" && (
                      <div className="card p-2 mt-3">
                        <div className="d-flex align-items-center">
                          <img
                            className="avatar-ad border-gray mb-0 mr-3"
                            style={{
                              width: "70px",
                              height: "70px",
                            }}
                            src={
                              patientDetails?.image_url ||
                              "https://cashdocs.s3.us-east-2.amazonaws.com/placeholder.png"
                            }
                          />

                          <div>
                            <h5 className=" mb-0">
                              {patientDetails?.full_name &&
                                decryptField(patientDetails.full_name)}
                            </h5>
                            {/* <p className="mb-0">
                          {" "}
                          <i className="fas fa-star coloro" /> <b>0/5</b>
                        </p> */}
                            <p className="mb-0 description">
                              <b>
                                Booked On:{" "}
                                {moment(apptDetails.created_at).format(
                                  "dddd MMMM DD, YYYY, h:mm a"
                                )}{" "}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>

                  <Col lg="6" md="12" xs="12" className="appointdetails">
                    <h5 className="mb-0 text-left p-2">
                      {" "}
                      <b>Doctor Details</b>{" "}
                    </h5>

                    <div className="d-flex align-items-center">
                      <img
                        className="avatar-ad border-gray mb-0 mr-3"
                        src={
                          docDetails.image_url ||
                          require("assets/img/doctor_dummy.png")
                        }
                      />
                      <div>
                        <h5 className=" mb-0"> Dr. {docDetails.full_name} </h5>
                        <p className="mb-0">
                          {" "}
                          <i className="fas fa-star coloro" />{" "}
                          <b>
                            {apptDetails.avgReviewRating}
                            /5
                          </b>
                        </p>
                        <p className="mb-0 description">
                          {docDetails &&
                            docDetails.doctorSpeciality &&
                            docDetails.doctorSpeciality.filter(
                              (_i) => _i.is_primary === "1"
                            )[0] && (
                              <b>
                                {
                                  docDetails?.doctorSpeciality?.filter(
                                    (_i) => _i.is_primary === 1
                                  )[0]?.speciality.name
                                }
                              </b>
                            )}
                          {!docDetails &&
                            !docDetails.doctorSpeciality &&
                            !docDetails.doctorSpeciality.filter(
                              (_i) => _i.is_primary === 1
                            )[0] && <b>{"N/A"}</b>}
                        </p>
                        <p className="bluetext">
                          {" "}
                          <b>
                            {docDetails &&
                              docDetails.clinic &&
                              docDetails.clinic.full_name}
                            {!docDetails && !docDetails.clinic && "N/A"}
                          </b>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Row>
                    <Col lg="6" md="12" xs="12">
                      <h5 className="mb-3 text-left p-2">
                        {" "}
                        <b>Service Details</b>{" "}
                      </h5>
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td className="text-left text-primary">Type</td>
                            <td className="text-right">
                              {!apptDetails.booking_type && "N/A"}
                              {apptDetails.booking_type === "in_clinic" && (
                                <>
                                  <i className="fas fa-hospital text-black" />{" "}
                                  Clinic
                                </>
                              )}
                              {apptDetails.booking_type === "video_visit" && (
                                <>
                                  <i className="fas fa-video text-black" />{" "}
                                  Video Visit
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">
                              Service Date
                            </td>
                            <td className="text-right">
                              {moment(apptDetails.service_at_date).format(
                                "MMM DD YYYY, dddd hh:mm a"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">
                              Service Location
                            </td>
                            <td className="text-right">
                              {apptDetails.service_address}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>

                    <Col lg="6" md="12" xs="12">
                      <h5 className="mb-3 text-left p-2">
                        <b>Services Booked</b>
                      </h5>
                      <Table responsive>
                        <tbody>
                          {bookedServices.length > 0 &&
                            bookedServices.map((_s) => (
                              <tr key={_s.uid}>
                                <td className="text-left ">
                                  {_s.services && _s.services.name}
                                </td>
                                <td className="text-right">
                                  {_s.required_time.split(":")[0] +
                                    "hr " +
                                    _s.required_time.split(":")[1] +
                                    "mins"}
                                </td>
                              </tr>
                            ))}
                          {bookedServices.length === 0 && (
                            <tr>
                              <td className="text-left ">
                                {apptDetails.booking_type === "in_clinic" &&
                                  "General Appointment "}
                                {apptDetails.booking_type === "video_visit" &&
                                  "Video Visit"}
                              </td>
                              <td className="text-right">
                                {apptDetails.service_duration + " mins"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </CardFooter>
            </Card>
            {apptDetails.is_review_added && (
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Rating and Review </CardTitle>
                      <h6>
                        Received {Math.round(review.rating * 10) / 10}/5 star
                        rating from{" "}
                        {patientDetails.full_name &&
                          decryptField(patientDetails.full_name)}
                      </h6>
                    </CardHeader>
                    <CardBody>
                      <div className="mb-3 ">
                        {review && review.rating
                          ? renderStars(Math.floor(review.rating))
                          : ""}
                      </div>

                      <p>{review.comments}</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {/* VIDEO CALLS */}
            {logs.length > 0 && (
              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <Table responsive>
                        <thead className="text-primary">
                          <tr>
                            <th># </th>
                            <th>Call ID </th>
                            <th>Duration </th>
                            <th className="text-right">Status </th>
                          </tr>
                        </thead>
                        <tbody>
                          {logs.map((_log, index) => (
                            <tr key={_log.id}>
                              <td>{index + 1}</td>
                              <td>{_log.call_id}</td>
                              <td>
                                {_log.duration &&
                                  moment
                                    .utc(_log.duration)
                                    .format("mm[m] ss[s]")}
                              </td>
                              <td className="text-right">
                                <Badge color="danger">
                                  {_log.status.toUpperCase()}
                                </Badge>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>

          <Col md="12" lg="3" xs="12">
            {" "}
            <Card className="card-user">
              <CardBody>
                <h5 className="mb-3 text-left p-2">
                  <b>Payment Details</b>
                </h5>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td className="text-left ">Total Consultation Fee</td>
                      <td className="text-right text-primary">
                        ${transaction.booking_amount || 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left ">Stripe Commission</td>
                      <td className="text-right ">
                        ${transaction?.handling_charge || 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left ">
                        <strong>Total Paid</strong>
                      </td>
                      <td className="text-right text-primary">
                        $
                        {+transaction?.booking_amount +
                          +transaction?.handling_charge || 0}
                      </td>
                    </tr>
                    <tr></tr>
                    {!transaction?.extended_count === 0 && (
                      <tr>
                        <td className="text-left ">
                          Extended Video call (
                          {transaction?.extended_count || 0})
                        </td>
                        <td className="text-right">
                          $
                          {transaction.collected_amount *
                            transaction?.extended_count}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className="text-left ">
                        <strong>Grand Total Paid</strong>
                      </td>
                      <td className="text-right text-primary">
                        <b> ${transaction.total_cost || 0} </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card className="card-user">
              <CardBody>
                <h5 className="mb-3 text-left p-2">
                  <b>Payment to Doctor</b>
                </h5>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td className="text-left ">Service Fee</td>
                      <td className="text-right">
                        $
                        {transaction
                          ? (Math.round((+transaction.destination_amount +
                            +transaction.destination_amount *
                            +transaction?.extended_count) * 100) / 100) || 0
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left ">Total Amount</td>
                      <td className="text-right text-primary">
                        <b>
                          {" "}
                          $
                          {transaction
                            ? (Math.round((+transaction.destination_amount +
                              +transaction.destination_amount *
                              +transaction?.extended_count) * 100) / 100) || 0
                            : 0}{" "}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <h5 className="mb-3 text-left p-2">
                  <b>Payment to CashDocs</b>
                </h5>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td className="text-left ">Service Fee</td>
                      <td className="text-right">
                        $
                        {transaction
                          ? (Math.round((+transaction.application_amount +
                            +transaction.application_amount *
                            +transaction?.extended_count) * 100) / 100) || 0
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left ">Total Amount</td>
                      <td className="text-right text-primary">
                        <b>
                          $
                          {transaction
                            ? (Math.round((+transaction.application_amount +
                              +transaction.application_amount *
                              +transaction?.extended_count) * 100) / 100) || 0
                            : 0}{" "}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card className="card-user">
              <CardBody>
                <h5 className="mb-3 text-left p-2">
                  <b>
                    Payment Status{" "}
                    {!transaction.refund_id && (
                      <>
                        {paymentDetails.status === "succeeded" && (
                          <Badge color="successoutline">
                            {" "}
                            <i className="fas fa-check-circle" /> Success
                          </Badge>
                        )}
                        {paymentDetails.status === "failed" && (
                          <Badge color="danger">
                            {" "}
                            <i className="fas fa-check-circle" /> Failed
                          </Badge>
                        )}
                        {paymentDetails.status === "pending" && (
                          <Badge color="warning">
                            {" "}
                            <i className="fas fa-check-circle" /> Pending
                          </Badge>
                        )}
                      </>
                    )}
                    {!!transaction.refund_id && (
                      <Badge color="successoutline">
                        {" "}
                        <i className="fas fa-check-circle" /> Refunded
                      </Badge>
                    )}
                  </b>
                </h5>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td className="text-left ">Transaction ID</td>
                      <td className="text-right">{paymentDetails.charge_id}</td>
                    </tr>
                    <tr>
                      <td className="text-left ">Account</td>
                      <td className="text-right">
                        <b> XXXX XXXX XXXX {paymentDetails.last4}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Appointmentdetails;
