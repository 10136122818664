import React from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,

} from "reactstrap";
import { Link } from "react-router-dom";

function SuccessLogin() {
  return (
    <div>
      <div className='panelLogin'>
        <div className="content">
          <Row><Col className="d-flex justify-content-center " md="12" >
            <Card className="col-lg-3 col-md-4"><CardBody>  <Row>
              <Col className="mb-3"><div className="text-center mt-5 mb-5">
                {/* <img src={require("assets/img/logo.svg")} className="imglogo" /> */}
              </div>
                <FormGroup>
                  <div className="intro">
                    <div className="introWrap">
                      <div className="iimg">
                        <img src={require("assets/img/tick.png")} className="imglogo" />
                      </div>
                      <div className="introdetail">
                        <h2>Success</h2>
                        <p>You have successfully reset your password</p>
                      </div>
                    </div>
                  </div><Link to={`/`} style={{ color: "white", textDecoration: "none" }}>
                    <Button className="btn-round  btn-block mt-4" color="info" type="submit"  >  LOGIN   </Button></Link>
                </FormGroup>
              </Col>
            </Row></CardBody></Card></Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default SuccessLogin;