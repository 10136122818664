import jsCookie from "js-cookie";
import { redirect } from "../utils/helper";

class AuthService {
  constructor() {}

  setAuths({ accessToken, refreshToken, sessionId }) {
    jsCookie.set("auths", {
      "x-access-token": accessToken,
      "refresh-token": refreshToken,
      "session-id": sessionId,
    });
    // Router.push("/");
  }

  getAuths() {
    let auths = jsCookie.getJSON("auths");
    if (auths) {
      auths["Content-Type"] = "application/json";
      return auths;
    }
    return {};
  }

  clearSession() {
    jsCookie.remove("auths");
    jsCookie.remove("restrictions");
    redirect("/login");
  }

  isLogedIn() {
    let auths = jsCookie.getJSON("auths");
    // console.log(auths);
    if (auths && auths["x-access-token"] && auths["refresh-token"]) {
      return true;
    }
    return false;
  }
}

export default AuthService;
