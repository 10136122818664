import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  FormFeedback,
  Form,
  Spinner,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";

import { isValidEmail } from "../utils/helper";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import AuthService from "../services/AuthService";
import ApiService from "../services/APIService";

const Login = ({ history }) => {
  const [pType, setPType] = useState("password");
  const [uname, setUname] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setSubmit] = useState(false);
  const [validation, setValidation] = useState({
    uname: undefined,
    password: undefined,
  });
  const { addToast } = useToasts();

  useEffect(() => {
    let auth = new AuthService();
    if (auth.isLogedIn()) {
      history.push("/");
    }
  }, []);

  const isValidateForm = () => {
    let errors = {};
    if (!uname) errors.uname = "Please enter username";
    else if (!isValidEmail(uname))
      errors.uname = "Please enter a valid username";
    if (!password) errors.password = "Please enter password";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };

  const submitLogin = () => {
    setSubmit(true);
    let API = new ApiService();
    let pData = {
      username: uname,
      password,
    };
    if (!isValidateForm()) {
      setSubmit(false);
      addToast("Login Error", { appearance: "error", autoDismiss: true });
      return;
    }
    API._POST({ api: "/auth/local/admin", body: pData })
      .then((ress) => {
        setSubmit(false);
        addToast("Login Success", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/admin/dashboard");
      })
      .catch((err) => {
        setSubmit(false);
        addToast(err.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return (
    <>
      <div className="content">
        <Row className="m-0 my-5">
          <Col className="d-flex justify-content-center " md="12">
            <Card className="col-lg-3 col-md-4 login-card shadow-sm">
              <CardBody>
                {" "}
                <Row>
                  <Col className="mb-3">
                    <div className="text-center mt-5 mb-5">
                      <img
                        src={require("assets/img/logo.svg")}
                        className="imglogo"
                      />
                    </div>
                    <Form onSubmit={() => {}}>
                      <FormGroup>
                        <div className="mb-2">
                          <label>Email</label>
                          <Input
                            placeholder="Enter Email"
                            type="text"
                            valid={!validation.uname && !!uname}
                            invalid={!!validation.uname}
                            onChange={({ target }) => {
                              setUname(target.value);
                            }}
                          />
                          <FormFeedback>{validation.uname}</FormFeedback>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div>
                          <label>Password</label>
                          <div className="position-relative">
                            <Input
                              placeholder="Enter Password"
                              type={pType}
                              valid={!validation.password && !!password}
                              invalid={!!validation.password}
                              onChange={({ target }) => {
                                setPassword(target.value);
                              }}
                              style={{
                                backgroundImage: "none",
                              }}
                            />
                            <Eye pType={pType} setPType={setPType} />
                          </div>
                          <FormFeedback>{validation.password}</FormFeedback>
                        </div>
                      </FormGroup>
                      <div className="forgotPass">
                        <a href="/forgot-password">Forgot Password ?</a>
                      </div>
                      <Button
                        className="btn-round  btn-block mt-4"
                        color="info"
                        type="submit"
                        disabled={processing}
                        onClick={submitLogin}
                      >
                        {processing && (
                          <Spinner
                            style={{ width: "1rem", height: "1rem" }}
                            // type="grow"
                            color="dark"
                          />
                        )}
                        {processing ? " Loading..." : "Login"}
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
const Eye = ({ pType, setPType }) => {
  return (
    <div
      style={{
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
      onClick={() => {
        setPType(pType === "text" ? "password" : "text");
      }}
    >
      {pType === "text" ? (
        <i className="fas fa-eye-slash"></i>
      ) : (
        <i className="fas fa-eye"></i>
      )}
    </div>
  );
};

// class Login extends React.Component {
//   render() {
//     return (
//       <>
//         <div className="content">

//           <Row>

//             <Col className="d-flex justify-content-center " md="12" >
//               <Card className="col-lg-3 col-md-4">

//                 <CardBody>  <Row>
//                   <Col  className="mb-3">

//                     <div className="text-center mt-5 mb-5">
//                       <img src={require("assets/img/logo.svg")} className="imglogo" />
//                     </div>

//                     <FormGroup>

//                       <div className="mb-2">
//                         <label>Email</label>
//                         <Input placeholder="Enter Email" type="text"
//                         /></div>
//                       <div>
//                         <label>Password</label>
//                         <Input placeholder="Enter Password" type="PASSWORD"
//                         /></div>

//                       <Button className="btn-round  btn-block mt-4" color="info" type="submit"  >  LOGIN  </Button>
//                     </FormGroup>

//                   </Col>

//                 </Row></CardBody></Card></Col>
//           </Row>

//         </div>
//       </>
//     );
//   }
// }

export default Login;
