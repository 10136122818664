import React, { useState } from "react";
import { Spinner } from "reactstrap";
import APIService from "services/APIService";

function DoctorEditButton({ uid, addToast, typeBtn }) {
  const [isLoading, setLoading] = useState(false);
  const Edit = () => {
    const API = new APIService();
    setLoading(true);
    API._POST({
      api: `/auth/user`,
      body: {
        user_uid: uid,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        window.open(
          `${process.env.REACT_APP_WEB_URL}/?c=${data?.session_id}`,
          "_blank"
        );
      })
      .catch((error) => {
        setLoading(false);
        addToast("Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return typeBtn ? (
    <button
      className="btn-round float-right   btn btn-outline-info d-flex flex-row"
      onClick={Edit}
      disabled={isLoading}
    >
      {isLoading ? (
        <>
          {" "}
          Opening... <Spinner size="sm" className="ml-2" />
        </>
      ) : (
        "Edit"
      )}
    </button>
  ) : (
    <div className=" d-flex flex-row" onClick={Edit} disabled={isLoading}>
      {isLoading ? (
        <>
          {" "}
          Opening... <Spinner size="sm" className="ml-2" />
        </>
      ) : (
        "Edit"
      )}
    </div>
  );
}

export default DoctorEditButton;
