import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";

import routes from "routes.js";
import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import ConfirmPopup from "../ConfirmPopup";
import ApiService from "../../services/APIService";
import AuthService from "../../services/AuthService";
import APIService from "../../services/APIService";
import axios from "axios";
import BackButton from "components/BackButton";
import Loader from "components/Loader";

const Header = ({ history, location }) => {
  const [state, setState] = useState({
    isOpen: false,
    dropdownOpen: false,
    color: "transparent",
  });
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const sidebarToggle = React.createRef();
  const { addToast } = useToasts();

  useEffect(() => {
    if (
      window.innerWidth < 993 &&
      history.location.pathname != location.pathname &&
      document.documentElement.className.indexOf("nav-open") != -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, []);

  const toggle = () => {
    if (state.isOpen) {
      setState({
        ...state,
        color: "transparent",
      });
    } else {
      setState({
        ...state,
        color: "dark",
      });
    }
    setState({
      ...state,
      isOpen: !state.isOpen,
    });
  };

  const dropdownToggle = (e) => {
    setState({
      ...state,
      dropdownOpen: !state.dropdownOpen,
    });
  };

  const getBrand = () => {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) != -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };

  const updateColor = () => {
    if (window.innerWidth < 993 && state.isOpen) {
      setState({
        ...state,
        color: "dark",
      });
    } else {
      setState({
        ...state,
        color: "transparent",
      });
    }
  };

  const logout = () => {
    let auth = new AuthService();
    let API = new ApiService();
    const pData = {
      session_id: auth.getAuths()["session-id"],
    };
    API._POST({ api: "/auth/logout", body: pData })
      .then((ress) => {
        addToast("Logout Success", {
          appearance: "success",
          autoDismiss: true,
        });
        auth.clearSession();
        history.push("/login");
      })
      .catch((err) => {
        addToast(err.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        setIsLoading(true);
        return config;
      },
      (error) => {
        setIsLoading(false);
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setIsLoading(false);
        return response;
      },
      (error) => {
        setIsLoading(false);
        return Promise.reject(error);
      }
    );

    return () => {
      // Cleanup: Eject the interceptors to avoid memory leaks
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return (
    <>
      <ConfirmPopup
        msg="Are you sure you want to logout?"
        desc="This session will be automatically dismissed"
        is_open={logoutConfirm}
        onClose={() => setLogoutConfirm(false)}
        onConfirm={logout}
      />
      {isLoading && (
        <div className="load-bar">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      )}

      <Navbar
        color={
          location.pathname.indexOf("full-screen-maps") != -1
            ? "dark"
            : state.color
        }
        expand="lg"
        className={
          location.pathname.indexOf("full-screen-maps") != -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
            (state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand className="d-flex">
              <BackButton />
              {getBrand()}
            </NavbarBrand>
          </div>
          <Collapse className="justify-content-end" isOpen={true}>
            <Nav>
              <DropdownToggle nav onClick={() => setLogoutConfirm(true)}>
                <i className="fas fa-power-off"></i>{" "}
              </DropdownToggle>
              <NavItem>
                <Link to="/admin/settings" className="nav-link btn-magnify">
                  <i className="fas fa-cog" />
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

// class Header extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpen: false,
//       dropdownOpen: false,
//       color: "transparent",
//     };
//     this.toggle = this.toggle.bind(this);
//     this.dropdownToggle = this.dropdownToggle.bind(this);
//     this.sidebarToggle = React.createRef();
//   }
//   toggle() {
//     if (this.state.isOpen) {
//       this.setState({
//         color: "transparent",
//       });
//     } else {
//       this.setState({
//         color: "dark",
//       });
//     }
//     this.setState({
//       isOpen: !this.state.isOpen,
//     });
//   }
//   dropdownToggle(e) {
//     this.setState({
//       dropdownOpen: !this.state.dropdownOpen,
//     });
//   }
//   getBrand() {
//     let brandName = "Default Brand";
//     routes.map((prop, key) => {
//       if (window.location.href.indexOf(prop.layout + prop.path) !=-1) {
//         brandName = prop.name;
//       }
//       return null;
//     });
//     return brandName;
//   }
//   openSidebar() {
//     document.documentElement.classList.toggle("nav-open");
//     this.sidebarToggle.current.classList.toggle("toggled");
//   }
//   // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
//   updateColor() {
//     if (window.innerWidth < 993 && this.state.isOpen) {
//       this.setState({
//         color: "dark",
//       });
//     } else {
//       this.setState({
//         color: "transparent",
//       });
//     }
//   }
//   componentDidMount() {
//     window.addEventListener("resize", this.updateColor.bind(this));
//   }
//   componentDidUpdate(e) {
//     if (
//       window.innerWidth < 993 &&
//       e.history.location.pathname !=e.location.pathname &&
//       document.documentElement.className.indexOf("nav-open") !=-1
//     ) {
//       document.documentElement.classList.toggle("nav-open");
//       this.sidebarToggle.current.classList.toggle("toggled");
//     }
//   }

//   render() {
//     return (
//       // add or remove classes depending if we are on full-screen-maps page or not
//       <Navbar
//         color={
//           this.props.location.pathname.indexOf("full-screen-maps") !=-1
//             ? "dark"
//             : this.state.color
//         }
//         expand="lg"
//         className={
//           this.props.location.pathname.indexOf("full-screen-maps") !=-1
//             ? "navbar-absolute fixed-top"
//             : "navbar-absolute fixed-top " +
//             (this.state.color=== "transparent" ? "navbar-transparent " : "")
//         }
//       >
//         <Container fluid>
//           <div className="navbar-wrapper">
//             <div className="navbar-toggle">
//               <button
//                 type="button"
//                 ref={this.sidebarToggle}
//                 className="navbar-toggler"
//                 onClick={() => this.openSidebar()}
//               >
//                 <span className="navbar-toggler-bar bar1" />
//                 <span className="navbar-toggler-bar bar2" />
//                 <span className="navbar-toggler-bar bar3" />
//               </button>
//             </div>
//             <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
//           </div>
//           <NavbarToggler onClick={this.toggle}>
//             <span className="navbar-toggler-bar navbar-kebab" />
//             <span className="navbar-toggler-bar navbar-kebab" />
//             <span className="navbar-toggler-bar navbar-kebab" />
//           </NavbarToggler>
//           <Collapse
//             isOpen={this.state.isOpen}
//             navbar
//             className="justify-content-end"
//           >
//             {/* <form>
//               <InputGroup className="no-border">
//                 <Input placeholder="Search..." />
//                 <InputGroupAddon addonType="append">
//                   <InputGroupText>
//                     <i className="nc-icon nc-zoom-split" />
//                   </InputGroupText>
//                 </InputGroupAddon>
//               </InputGroup>
//             </form> */}
//             <Nav navbar>
//               {/* <NavItem>
//                 <Link to="#pablo" className="nav-link btn-magnify">
//                   <i className="nc-icon nc-layout-11" />
//                   <p>
//                     <span className="d-lg-none d-md-block">Stats</span>
//                   </p>
//                 </Link>
//               </NavItem> */}
//               <Dropdown
//                 nav
//                 isOpen={this.state.dropdownOpen}
//                 toggle={(e) => this.dropdownToggle(e)}
//               >
//                 <DropdownToggle caret nav>
//                   <i className="fas fa-user" />
//                   <p>
//                     <span className="d-lg-none d-md-block">Account</span>
//                   </p>
//                 </DropdownToggle>
//                 <DropdownMenu right>
//                   <DropdownItem tag="a">Settings</DropdownItem>
//                   <DropdownItem tag="a" >Logout</DropdownItem>

//                 </DropdownMenu>
//               </Dropdown>
//               <NavItem>
//                 <Link to="#pablo" className="nav-link btn-rotate">
//                   <i className="fas fa-cog" />
//                   <p>
//                     <span className="d-lg-none d-md-block">Settings</span>
//                   </p>
//                 </Link>
//               </NavItem>
//             </Nav>
//           </Collapse>
//         </Container>
//       </Navbar>
//     );
//   }
// }

export default Header;
