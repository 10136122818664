import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import PwdConfirm from "../components/PwdConfirm";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from "react";
import ApiService from "../services/APIService";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Empty from "../components/Empty";
import { encryptField, decryptField } from "../utils/helper";
import DoctorEditButton from "components/DoctorEditButton";

const PatientDetails = ({ history }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [pwdConfirm, setPwdConfirm] = useState(false);
  const [popupDesc, setPopupDesc] = useState("");
  const [popupMsg, setPopupMsg] = useState("");

  const [pwdConfirmDel, setPwdConfirmDel] = useState(false);
  const [popupDescDel, setPopupDescDel] = useState("");
  const [popupMsgDel, setPopupMsgDel] = useState("");

  const [pwdConfirmUnblock, setPwdConfirmUnblock] = useState(false);
  const [popupDescUnblock, setPopupDescUnblock] = useState("");
  const [popupMsgUnblock, setPopupMsgUnblock] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [patientsDetails, setPatientDetails] = useState({});
  const { addToast } = useToasts();
  let { uid } = useParams();

  const [patientAppts, setPatientAppts] = useState([]);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });

  useEffect(() => {
    fetchPatientDetails();
  }, []);

  const fetchPatientDetails = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: "/user/find",
      params: {
        where: `{"uid":"${uid}"}`,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setPatientDetails(data.user);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const fetchPatientAppointements = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: `/user/appointments/patients/${uid}`,
      params: {
        sort:
          sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
        offset: page * perPage,
        limit: perPage,
        populate: `["user", "doctor","transactions"]`,
      },
    })
      .then(({ data: { appointments, limit, count } }) => {
        setPerPage(limit);
        setPageCount(Math.ceil(count / limit));
        setPatientAppts(appointments);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const sortAppointments = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  useEffect(() => {
    fetchPatientAppointements();
  }, [sort, page]);

  const blockUser = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "block",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirm(false);
        fetchPatientDetails();
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const deleteUser = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "delete",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmDel(false);
        window.location.href = "/admin/patients";
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const unblockUser = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "unblock",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmUnblock(false);
        fetchPatientDetails();
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <>
      <PwdConfirm
        is_open={pwdConfirm}
        isLoading={isLoading}
        onClose={() => setPwdConfirm(!pwdConfirm)}
        msg={popupMsg}
        description={popupDesc}
        onSubmit={(password) => {
          setLoading(true);
          blockUser(password);
        }}
      />

      <PwdConfirm
        is_open={pwdConfirmDel}
        isLoading={isLoading}
        onClose={() => setPwdConfirmDel(!pwdConfirmDel)}
        msg={popupMsgDel}
        description={popupDescDel}
        onSubmit={(password) => {
          setLoading(true);
          deleteUser(password);
        }}
      />

      <PwdConfirm
        is_open={pwdConfirmUnblock}
        isLoading={isLoading}
        onClose={() => setPwdConfirmUnblock(!pwdConfirmUnblock)}
        msg={popupMsgUnblock}
        description={popupDescUnblock}
        onSubmit={(password) => {
          setLoading(true);
          unblockUser(password);
        }}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <div className="image ">
                <img
                  alt="..."
                  src={require("assets/img/profilebg.jpg")}
                  className="imgw"
                />
              </div>
              <CardBody>
                <div className=" d-flex justify-content-end listdrop">
                  <Dropdown
                    nav
                    isOpen={dropdownOpen}
                    toggle={(e) => setDropdownOpen(!dropdownOpen)}

                  >
                    <DropdownToggle>
                      <i className="fas fa-ellipsis-h" />
                    </DropdownToggle>
                    <DropdownMenu right
                      style={{
                        willChange: "auto",
                        right: 0
                      }}>
                      <div>
                        {patientsDetails.status === "active" && (
                          <DropdownItem
                            tag="a"
                            disabled={patientsDetails.status === "blocked"}
                            onClick={() => {
                              setPopupMsg(
                                `Are you sure to block the patient ${patientsDetails.full_name &&
                                decryptField(patientsDetails.full_name)
                                }?`
                              );
                              setPopupDesc(
                                `He/She won't be able to access the app and his appointments will be canceled automatically, if any.`
                              );
                              setPwdConfirm(true);
                            }}
                          >
                            Block
                          </DropdownItem>
                        )}
                        {patientsDetails.status === "blocked" && (
                          <DropdownItem
                            tag="a"
                            disabled={
                              patientsDetails.status === "active" ||
                              patientsDetails.status === "pending"
                            }
                            onClick={() => {
                              setPopupMsgUnblock(
                                `Are you sure to unblock the doctor ${patientsDetails.full_name &&
                                decryptField(patientsDetails.full_name)
                                }?`
                              );
                              setPopupDescUnblock(
                                `He/She will be able to access the app.`
                              );
                              setPwdConfirmUnblock(true);
                            }}
                          >
                            Unblock
                          </DropdownItem>
                        )}
                        <DropdownItem
                          tag="a"
                          disabled={patientsDetails.status === "deleted"}
                          onClick={() => {
                            setPopupMsgDel(
                              `Are you sure to delete the patient ${patientsDetails.full_name &&
                              decryptField(patientsDetails.full_name)
                              }?`
                            );
                            setPopupDescDel(
                              `He/She won't be able to access the app and his appointments will be canceled automatically, if any.`
                            );
                            setPwdConfirmDel(true);
                          }}
                        >
                          Delete
                        </DropdownItem></div>
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="author">
                  <img
                    alt="..."
                    className="avatar border-gray"
                    src={
                      patientsDetails.image_url ||
                      "https://cashdocs.s3.us-east-2.amazonaws.com/placeholder.png"
                    }
                  />
                  <h5 className="title">
                    {patientsDetails.full_name &&
                      decryptField(patientsDetails.full_name)}{" "}
                    {patientsDetails.status && (
                      <Badge
                        color={
                          patientsDetails.status === "active"
                            ? "success"
                            : patientsDetails.status === "pending"
                              ? "warning"
                              : "danger"
                        }
                      >
                        {patientsDetails.status === "active"
                          ? "Active"
                          : patientsDetails.status === "pending"
                            ? "Pending"
                            : patientsDetails.status === "blocked"
                              ? "Blocked"
                              : patientsDetails.status === "rejected"
                                ? "Rejected"
                                : "Deleted"}
                      </Badge>
                    )}
                  </h5>
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="button-container p-2">
                  <Row>
                    <Col
                      lg="12"
                      md="12"
                      xs="12"
                      className="mb-3  d-flex justify-content-between align-items-center"
                    >
                      <h5 className="text-left ">
                        <b>Profile Details</b>
                      </h5>
                      <DoctorEditButton
                        addToast={addToast}
                        uid={uid}
                        typeBtn={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4" md="12" xs="12">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td className="text-left text-primary">Email</td>
                            <td className="text-right">
                              {patientsDetails.email &&
                                decryptField(patientsDetails.email)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">Phone</td>
                            <td className="text-right">
                              {(patientsDetails.phone &&
                                decryptField(patientsDetails.phone)) ||
                                "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">DOB</td>
                            <td className="text-right">
                              {patientsDetails.dob &&
                                decryptField(patientsDetails.dob)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">
                              Created Date
                            </td>
                            <td className="text-right">
                              {patientsDetails.created_at}
                            </td>
                          </tr>{" "}
                          <tr>
                            <td className="text-left text-primary">Address</td>
                            <td className="text-right">
                              {patientsDetails.address &&
                                decryptField(patientsDetails.address)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>

                    <Col lg="4" md="12" xs="12">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td className="text-left text-primary">City</td>
                            <td className="text-right">
                              {decryptField(patientsDetails?.city)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">State</td>
                            <td className="text-right">
                              {decryptField(patientsDetails?.state)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">Zip Code</td>
                            <td className="text-right">
                              {decryptField(patientsDetails?.zip_code)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">
                              Occupation
                            </td>
                            <td className="text-right">
                              {" "}
                              {patientsDetails.occupation &&
                                decryptField(patientsDetails.occupation)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">Gender</td>
                            <td className="text-right">
                              {patientsDetails.gender &&
                                decryptField(patientsDetails.gender)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>

                    <Col lg="4" md="12" xs="12">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td className="text-left text-primary">
                              Marital Status
                            </td>
                            <td className="text-right">
                              {patientsDetails.martial_status &&
                                decryptField(patientsDetails.martial_status)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">
                              Number of Children
                            </td>
                            <td className="text-right">
                              {patientsDetails.no_of_children &&
                                decryptField(patientsDetails.no_of_children)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left text-primary">
                              Login Via
                            </td>
                            <td className="text-right">
                              {" "}
                              {patientsDetails.provider === "Local"
                                ? "Email"
                                : patientsDetails.provider}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </CardFooter>
            </Card>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Recent Appointments</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead className="text-primary">
                        <tr>
                          <th>
                            Appointment ID{" "}
                            <i
                              className={`fas ${sort.name === "appt_id" ? sort.icon : "fa-sort"
                                } text-black`}
                              onClick={() => sortAppointments("appt_id")}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th className="avoid-hand-cursor">Doctor Name</th>
                          <th>
                            Appointment Date &amp; Time{" "}
                            <i
                              className={`fas ${sort.name === "service_at_date"
                                ? sort.icon
                                : "fa-sort"
                                } text-black`}
                              onClick={() =>
                                sortAppointments("service_at_date")
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th className="avoid-hand-cursor">Amount</th>
                          <th className="avoid-hand-cursor">Type</th>
                          <th className="text-right">
                            Status{" "}
                            <i
                              className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                                } text-black`}
                              onClick={() => sortAppointments("status")}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {patientAppts.length === 0 && (
                          <Empty msg="No Appointments" />
                        )}
                        {patientAppts.map((item) => (
                          <tr key={item.id}>
                            <td>
                              {" "}
                              <Link to={`/admin/appointments/${item.uid}`}>
                                {item.appt_id}
                              </Link>
                            </td>

                            <td>
                              <Link to={"/admin/doctors/" + item.doctor.uid}>
                                {item.doctor.full_name}
                              </Link>
                            </td>
                            <td>
                              {moment(item.service_at_date).format(
                                "MM/DD/YY - hh:mm a"
                              )}{" "}
                            </td>
                            <td>
                              $
                              {item.transactions &&
                                item.transactions.collected_amount}
                            </td>
                            <td>
                              {item.booking_type === "video_visit" && (
                                <>
                                  <i className="fas fa-video text-black" />{" "}
                                </>
                              )}
                              {item.booking_type === "in_clinic" && (
                                <>
                                  <i className="fas fa-hospital text-black" />{" "}
                                </>
                              )}
                            </td>
                            <td className="text-right">
                              {item.status === "completed" && (
                                <Badge color="success">Completed</Badge>
                              )}
                              {item.status === "pending" && (
                                <Badge color="warning">Pending</Badge>
                              )}
                              {item.status === "ongoing" && (
                                <Badge color="dark">On Going</Badge>
                              )}
                              {item.status === "cancelled" && (
                                <Badge color="danger">Canceled</Badge>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12">
                <div className="justify-content-end d-flex ">
                  <ReactPaginate
                    pageCount={pageCount}
                    forcePage={page}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={5}
                    containerClassName="pagination-style"
                    previousClassName="pagination-prev"
                    nextClassName="pagination-next"
                    pageClassName="pagination-btn"
                    breakClassName="pagination-btn"
                    activeClassName="pagination-active"
                    onPageChange={(page) => {
                      setPage(page.selected);
                    }}
                  />
                </div>
              </Col>{" "}
            </Row>{" "}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PatientDetails;
