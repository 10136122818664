import React from "react";
import { Notifications } from "utils/notification";

function Unread({ name }) {
  const { state } = React.useContext(Notifications);
  if (state?.unread?.services && ["/services"].includes(name)) {
    return (
      <i
        className="fas fa-circle text-primary mb-2"
        style={{
          fontSize: "10px",
          float: "unset",
          position: "absolute",
          top: "0",
        }}
      ></i>
    );
  } else if (state?.unread?.specialties && ["/specialties"].includes(name)) {
    return (
      <i
        className="fas fa-circle text-primary mb-2"
        style={{
          fontSize: "10px",
          float: "unset",
          position: "absolute",
          top: "0",
        }}
      ></i>
    );
  } else if (state?.unread?.notifications && ["/notifications"].includes(name)) {
    return (
      <i
        className="fas fa-circle text-primary mb-2"
        style={{
          fontSize: "10px",
          float: "unset",
          position: "absolute",
          top: "0",
          left:"15px"
        }}
      ></i>
    );
  } else {
    return <></>;
  }
}

export default Unread;
