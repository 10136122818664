import React from 'react'

function VideoStatus({ data }) {
    const blue = "#3c5fbf"
    const orange = "#ff511a"
    const css = {
        width: "15px",
        height: "15px",
        backgroundColor: "#ff511a",
        borderRadius: '50%',
        margin: "5px"
    }
    return (
        <span className='d-flex flex-column'>
            {data?.in_clinic === 1 && <div style={{ ...css, backgroundColor: orange }}></div>}
            {data?.video_visit === 1 && <div style={{ ...css, backgroundColor: blue }}></div>}
        </span>
    )
}

export default VideoStatus