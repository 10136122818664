import React from "react";

// reactstrap components
import classnames from "classnames";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import Confirm from "../components/ConfirmPopup";
import Empty from "../components/Empty";
import PwdConfirm from "../components/PwdConfirm";
import ApiService from "../services/APIService";
import Appointment from "components/Clinic/Appoinment";
import Doctors from "components/Clinic/Doctors";

const ClinicDetails = ({ history }) => {
  const [state, setState] = useState({
    activeTab: "1",
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [pwdConfirm, setPwdConfirm] = useState(false);
  const [popupDesc, setPopupDesc] = useState("");
  const [popupMsg, setPopupMsg] = useState("");

  const [pwdConfirmDel, setPwdConfirmDel] = useState(false);
  const [popupDescDel, setPopupDescDel] = useState("");
  const [popupMsgDel, setPopupMsgDel] = useState("");

  const [pwdConfirmReject, setPwdConfirmReject] = useState(false);
  const [popupDescReject, setPopupDescReject] = useState("");
  const [popupMsgReject, setPopupMsgReject] = useState("");

  const [pwdConfirmAccept, setPwdConfirmAccept] = useState(false);
  const [popupDescAccept, setPopupDescAccept] = useState("");
  const [popupMsgAccept, setPopupMsgAccept] = useState("");

  const [pwdConfirmUnblock, setPwdConfirmUnblock] = useState(false);
  const [popupDescUnblock, setPopupDescUnblock] = useState("");
  const [popupMsgUnblock, setPopupMsgUnblock] = useState("");

  const [clinicDoctors, setClinicDoctors] = useState([]);
  const [locations, setLocations] = useState([]);
  const [docPage, setDocPage] = useState(0);
  const [docPerPage, setDocPerPage] = useState(20);
  const [docPageCount, setDocPageCount] = useState(0);
  const [stripe, setStripe] = useState({});
  const [stripeError, setStripeError] = useState("bankDSingle");
  const [bank, setBank] = useState({});
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab != tab) setActiveTab(tab);
  };

  const [isLoading, setLoading] = useState(false);
  const [clinicDetails, setClinicDetails] = useState({});
  const [revenue, setRevenue] = useState({});
  const { addToast } = useToasts();
  let { uid } = useParams();

  useEffect(() => {
    fetchClinicDetails();
    fetchClinicDoctors();
  }, []);

  const fetchClinicDetails = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: `/clinics/${uid}`,
      params: {
        where: `{"uid":"${uid}"}`,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setClinicDetails(data.clinic_details);
        setLocations(data.clinic_details.location);
        setRevenue(data?.revenue);
        setStripe(data?.userStripe);
        setBank(data?.stripeBank?.[0]);
        setStripeError(
          !data?.userStripe?.active ? "bankDSingleError" : "bankDSingle"
        );
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const fetchClinicDoctors = () => {
    const API = new ApiService();
    setLoading(true);
    let populate = `["doctorFacility","doctorSpeciality","doctorSpeciality.speciality"]`;
    API._GET({
      api: `/doctor/clinics/${uid}`,
      params: {
        populate,
        offset: docPage * docPerPage,
        limit: docPerPage,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setClinicDoctors(data.doctors);
        setDocPerPage(data.limit);
        setDocPageCount(Math.ceil(data.count / data.limit));
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    fetchClinicDoctors();
  }, [docPage]);

  const blockClinic = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "block",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirm(false);
        fetchClinicDetails();
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const deleteClinic = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "delete",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmDel(false);
        addToast("Clinic successfully deleted", {
          appearance: "success",
          autoDismiss: true,
        });
        window.location.href = "/admin/clinics";
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const tabToggle = (tab) => {
    if (state.activeTab != tab) {
      setState({ activeTab: tab });
    }
  };

  const rejectClinic = () => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/${uid}`,
      body: {
        action: "reject",
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmReject(false);
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
        window.location.href = "/admin/clinics";
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const acceptClinic = () => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/${uid}`,
      body: {
        action: "accept",
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmAccept(false);
        fetchClinicDetails();
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const unblockClinic = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "unblock",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmUnblock(false);
        fetchClinicDetails();
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  const fallbackUrl = `https://ui-avatars.com/api/?color=f5f8fe&background=212740&name=${clinicDetails?.full_name
    ?.split(" ")
    ?.join("+")}`;
  const [image, setImage] = useState(clinicDetails.image_url);

  useEffect(() => {
    setImage(clinicDetails.image_url);
  }, [clinicDetails.image_url]);
  return (
    <>
      <PwdConfirm
        is_open={pwdConfirm}
        isLoading={isLoading}
        onClose={() => setPwdConfirm(!pwdConfirm)}
        msg={popupMsg}
        description={popupDesc}
        onSubmit={(password) => {
          setLoading(true);
          blockClinic(password);
        }}
      />

      <PwdConfirm
        is_open={pwdConfirmDel}
        isLoading={isLoading}
        onClose={() => setPwdConfirmDel(!pwdConfirmDel)}
        msg={popupMsgDel}
        description={popupDescDel}
        onSubmit={(password) => {
          setLoading(true);
          deleteClinic(password);
        }}
      />

      <PwdConfirm
        is_open={pwdConfirmUnblock}
        isLoading={isLoading}
        onClose={() => setPwdConfirmUnblock(!pwdConfirmUnblock)}
        msg={popupMsgUnblock}
        description={popupDescUnblock}
        onSubmit={(password) => {
          setLoading(true);
          unblockClinic(password);
        }}
      />

      <Confirm
        is_open={pwdConfirmReject}
        isLoading={isLoading}
        onClose={() => setPwdConfirmReject(!pwdConfirmReject)}
        msg={popupMsgReject}
        description={popupDescReject}
        onConfirm={() => {
          setLoading(true);
          rejectClinic();
        }}
      />

      <Confirm
        is_open={pwdConfirmAccept}
        isLoading={isLoading}
        onClose={() => setPwdConfirmAccept(!pwdConfirmAccept)}
        msg={popupMsgAccept}
        description={popupDescAccept}
        onConfirm={() => {
          setLoading(true);
          acceptClinic();
        }}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <div className="image ">
                <img
                  alt="..."
                  src={image || require("assets/img/profilebg.jpg")}
                  className="imgw"
                  onError={() => setImage(fallbackUrl)}
                  style={{
                    filter: "blur(1px)",
                  }}
                />
              </div>
              <CardBody>
                <div
                  className="d-flex justify-content-end"
                  style={{
                    opacity: clinicDetails ? 1 : 0,
                  }}
                >
                  {["blocked", "active"].includes(clinicDetails.status) && (
                    <button
                      className="pagination-next rounded"
                      onClick={() => {
                        if (clinicDetails.status === "blocked") {
                          setPopupMsgUnblock(
                            `Are you sure to unblock clinic ${clinicDetails.full_name}?`
                          );
                          setPopupDescUnblock(
                            `Will be able to access the app.`
                          );
                          setPwdConfirmUnblock(true);
                        } else {
                          setPopupMsg(
                            `Are you sure to block clinic ${clinicDetails.full_name}?`
                          );
                          setPopupDesc(
                            `Won't be able to access the app and all appointments will be cancelled automatically, if any.`
                          );
                          setPwdConfirm(true);
                        }
                      }}
                    >
                      {clinicDetails?.status === "blocked"
                        ? "Unblock"
                        : "Block"}
                    </button>
                  )}
                  {clinicDetails?.status !== "pending" && (
                    <button
                      className="ml-2 rounded pagination-next"
                      style={{
                        backgroundColor: "#f15527",
                        color: "#fff",
                      }}
                      onClick={() => {
                        setPopupMsgDel(
                          `Are you sure to delete the doctor ${clinicDetails?.full_name}?`
                        );
                        setPopupDescDel(
                          `He/She won't be able to access the app and his appointments will be canceled automatically, if any.`
                        );
                        setPwdConfirmDel(true);
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div className="author">
                  <img
                    alt="..."
                    className="avatar border-gray"
                    src={
                      image ||
                      "https://cashdocs.s3.us-east-2.amazonaws.com/placeholder.png"
                    }
                    onError={() => setImage(fallbackUrl)}
                  />
                  <h5 className="title">
                    {clinicDetails.full_name}{" "}
                    {clinicDetails.status && (
                      <Badge
                        color={
                          clinicDetails.status === "active"
                            ? "success"
                            : clinicDetails.status === "pending"
                              ? "warning"
                              : "danger"
                        }
                      >
                        {clinicDetails.status === "active"
                          ? "Active"
                          : clinicDetails.status === "pending"
                            ? "Pending Approval"
                            : clinicDetails.status === "blocked"
                              ? "Blocked"
                              : clinicDetails.status === "rejected"
                                ? "Rejected"
                                : "Deleted"}
                      </Badge>
                    )}
                  </h5>

                  {/* <p>
                    <i className="fas fa-star coloro" /> <b>0/5</b>{" "}
                  </p> */}
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "1",
                      })}
                      onClick={() => {
                        tabToggle("1");
                      }}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "2",
                      })}
                      onClick={() => {
                        tabToggle("2");
                      }}
                    >
                      Service Location
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "3",
                      })}
                      onClick={() => {
                        tabToggle("3");
                      }}
                    >
                      Bank Detail
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={state.activeTab}>
                  <TabPane tabId="1">
                    <div className="button-container mt-4">
                      <Row>
                        <Col lg="4" md="12" xs="12">
                          <h5 className="mb-3 text-left p-2">
                            <b>Profile Details</b>
                          </h5>
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td className="text-left text-primary">
                                  Email{" "}
                                </td>{" "}
                                <td className="text-right">
                                  {clinicDetails.email}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-left text-primary">
                                  Phone
                                </td>
                                <td className="text-right">
                                  {clinicDetails.phone}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>

                        <Col lg="4" md="12" xs="12">
                          <h5 className="mb-3 text-left p-2">
                            <b>&nbsp;</b>
                          </h5>
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td className="text-left text-primary">
                                  {" "}
                                  NPI Number{" "}
                                </td>
                                <td className="text-right">
                                  {clinicDetails.npi}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-left text-primary">
                                  #of Doctors
                                </td>
                                <td className="text-right">
                                  {clinicDoctors ? clinicDoctors.length : 0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>

                        {/* <Col lg="4" md="12" xs="12">
                          <h5 className="mb-3 text-left p-2">
                            <b>&nbsp;</b>
                          </h5>
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td className="text-left text-primary">
                                  Clinic Admin{" "}
                                </td>{" "}
                                <td className="text-right">
                                  {clinicDetails.clinic_manager}{" "}
                                </td>
                              </tr>
                             
                            </tbody>
                          </Table>
                        </Col> */}
                      </Row>
                      {console.log(revenue)}
                      <Row>
                        <Col lg="4" md="12" xs="12">
                          <h5 className="mb-3 text-left p-2">
                            <b>Clinic's Revenue </b>
                          </h5>
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td className="text-left text-primary">
                                  Total Appointments{" "}
                                </td>{" "}
                                <td className="text-right">
                                  {revenue?.total_appointments}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-left text-primary">
                                  Pending payout amount
                                </td>
                                <td className="text-right">
                                  ${revenue?.pending_balance}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>

                        <Col lg="4" md="12" xs="12">
                          <h5 className="mb-3 text-left p-2">
                            <b>&nbsp;</b>
                          </h5>
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td className="text-left text-primary">
                                  {" "}
                                  Total Payments{" "}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  ${revenue?.total_balance}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col lg="4" md="12" xs="12">
                          <h5 className="mb-3 text-left p-2">
                            <b>&nbsp;</b>
                          </h5>
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td className="text-left text-primary">
                                  Clinic's Revenue{" "}
                                </td>{" "}
                                <td className="text-right">
                                  ${revenue?.clinic_balance}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>

                      {/* <div className="p-1 mb-3 mt-4 text-left">
                        <h5 className="mb-3 text-left">
                          <b>Photos</b>{" "}
                        </h5>

                        <div className=" ">
                          <img
                            src={require("assets/img/sample.jpg")}
                            className="clinicimage m-1 "
                          />
                          {<Empty msg="No photos" />}
                        </div>
                      </div>
                      <hr /> */}
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Service Location</b>
                    </h5>
                    <Card className="shadow-none border service-card mb-3">
                      {locations.length === 0 && (
                        <CardBody>
                          <Empty />
                        </CardBody>
                      )}
                    </Card>

                    {locations.length != 0 && (
                      <>
                        {locations.map((item) => (
                          <Card
                            className="shadow-none border service-card mb-3"
                            key={item.uid}
                          >
                            <CardBody className=" p-0">
                              <div className="d-flex align-items-center flex-column flex-md-row">
                                <div>
                                  {/* <img className="border-gray mb-0 mr-md-3 img-fluid" src={require("assets/img/pin.png")} style={{ height: "10%", width: "10%" }} /> */}
                                </div>
                                <div className="pl-3 py-3">
                                  <p className="m-0">
                                    <span className="fz-18">
                                      {item.address}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        ))}
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="3">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Bank Detail</b>
                    </h5>
                    <Card className="shadow-none border medical-card mb-3">
                      {!stripe?.stripe_id && (
                        <CardBody>
                          <Empty />
                        </CardBody>
                      )}
                    </Card>
                    {!!stripe?.stripe_id && (
                      <>
                        <div className="bankDetail">
                          <div className={stripeError}>
                            <h2>{bank.bank_name}</h2>
                            <span>{`XXXX XXXX XXXX ${bank.last4}`}</span>
                          </div>
                        </div>
                        {stripe && !stripe.active && (
                          <div className="errMsg">
                            <h2>Error Message</h2>
                            <p>
                              There is something wrong with the bank
                              account.Please see the{" "}
                              <a
                                href="https://dashboard.stripe.com/dashboard"
                                target="_blank"
                              >
                                stripe dashboard
                              </a>{" "}
                              for more details.
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </TabPane>
                </TabContent>
                {["pending"].includes(clinicDetails.status) && (
                  <div className="col-12 justify-content-end d-flex align-items-center mb-3 mt-4">
                    <b className="ml-1 mr-1"> Do you want to approve? </b>

                    <Button
                      className="btn-round ml-1 mr-1"
                      color="reject"
                      type="button"
                      disabled={clinicDetails.status != "pending"}
                      onClick={() => {
                        setPopupMsgReject(
                          `Are you sure to reject the clinic ${clinicDetails.full_name}?`
                        );
                        setPopupDescReject(`Won't be able to access the app.`);
                        setPwdConfirmReject(true);
                      }}
                    >
                      REJECT{" "}
                    </Button>
                    <Button
                      className="btn-round ml-1 mr-1"
                      color="bluedark"
                      type="button"
                      disabled={clinicDetails.status != "pending"}
                      onClick={() => {
                        setPopupMsgAccept(
                          `Are you sure to accept the clinic ${clinicDetails.full_name}?`
                        );
                        setPopupDescAccept(``);
                        setPwdConfirmAccept(true);
                      }}
                    >
                      APPROVE{" "}
                    </Button>
                  </div>
                )}
              </CardFooter>
            </Card>
            <Row>
              <Col md="12">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Appointments
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Doctors
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {clinicDetails?.id ? (
                      <Appointment id={clinicDetails?.id} />
                    ) : (
                      <Row>
                        <Col>
                          <Card>
                            <CardBody>
                              <Empty />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {clinicDetails?.id ? (
                      <Doctors id={clinicDetails?.id} />
                    ) : (
                      <Row>
                        <Col>
                          <Card>
                            <CardBody>
                              <Empty />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ClinicDetails;
