import React from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  Label,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap";
import { useState, useEffect } from "react";

const Refund = ({ is_open, onConfirm, onClose, loading, msg, total }) => {
  const toggle = () => {
    setValidation({
      amount: undefined,
      reason: undefined,
    })
    setAmount('')
    setReason('')
    setType('full_refund')
    onClose()
  };
  const [reason, setReason] = useState("");
  const [type, setType] = useState("full_refund");
  const [amount, setAmount] = useState("");
  const [validation, setValidation] = useState({
    amount: undefined,
    reason: undefined,
  });

  const isValidateForm = () => {
    let errors = {};
    if (type === 'partial_refund' && !amount) errors.amount = "Please enter amount";
    if (type === 'partial_refund' && +amount > +total) errors.amount = "Refund amount cannot be greater than total amount";
    if (type === 'partial_refund' && +amount === +total) errors.amount = "Please select the Full Refund option";
    if (!reason) errors.reason = "Please enter reason";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };
  const submitForm = () => {
    if (!isValidateForm()) return;
    onConfirm(reason, amount, type);
    setAmount("");
    setReason("");
  }


  return (
    <div>
      <Modal isOpen={is_open} toggle={toggle} className="rounded-lg">
        <ModalHeader toggle={toggle} className="modeltitle">
          {"Refund"}
        </ModalHeader>
        <ModalBody className="text-center pb-2">
          {/* <i className="fas trianglestyle mb-4"> */}
          {/* <img
            width="13%"
            className="mb-4"
            src={require("assets/img/sponsor.png")}
          /> */}
          {/* </i> */}

          <div className="col-12 d-flex">
            <div className="col-6"><input type="radio" value="full_refund" checked={type === "full_refund"} name="refund"
              onChange={({ target: { value } }) => {
                setType(value);
                setValidation({
                  amount: undefined,
                  reason: undefined,
                })
                setAmount('')
              }}
            /> Full Refund</div>
            <div className="col-6"> <input type="radio" value="partial_refund" checked={type === "partial_refund"} name="refund"
              onChange={({ target: { value } }) => {
                setType(value);
                setAmount('')
                setValidation({
                  amount: undefined,
                  reason: undefined,
                })
                setAmount('')
              }} /> Partial Refund</div>
          </div>
          <FormGroup className="text-left mt-5 pb-3">
            {type === 'full_refund' && <div>
              <h5 className="col-12 justify-content-center d-flex align-items-center mb-1 mt-4 text-bold"><b>$ {total}</b></h5>
              <h5 className="col-12 justify-content-center d-flex align-items-center mb-3 mt-0"><b>Refund Amount</b></h5>
            </div>}
            {type === 'partial_refund' && <div className="mb-4">
              <label>Refund Amount</label>
              <Input
                placeholder="Amount"
                onChange={({ target: { value } }) => {
                  setAmount(value);
                  let errors = { ...validation };
                  if (type === "partial_refund" && value === '' && +value === 0)
                    errors.amount = "Please enter amount";
                  else if (type === "partial_refund" && (+value === 0 || value === '0') || +value < 0)
                    errors.amount = "Enter a valid amount";
                  else if (type === "partial_refund" && +value > +total)
                    errors.amount =
                      "Refund amount cannot be greater than total amount";
                  else if (type === "partial_refund" && +value === +total)
                    errors.amount = "Please select the Full Refund option";
                  else errors.amount = "";
                  setValidation({ ...errors });
                }}
                type="number"
                valid={!validation.amount && !!amount}
                invalid={!!validation.amount}
              />
              <FormFeedback>{validation.amount}</FormFeedback>
            </div>}
            <div>
              <label>Please enter reason</label>
              <Input
                placeholder="Reason"
                onChange={({ target: { value } }) => {
                  // Validation logic
                  let errors = { ...validation };
                  if (!value.trim()) {
                    errors.reason = "Please enter reason";
                  } else {
                    errors.reason = '';
                  }
                  setValidation({ ...errors });
                  // Update reason state
                  if (reason.length > 0) {
                    setReason(value);
                  } else {
                    setReason(value.trim());
                  }
                }}

                type="textarea"
                valid={!validation.reason && !!reason}
                invalid={!!validation.reason}
                maxLength={250}
              />
              <FormFeedback>{validation.reason}</FormFeedback>
            </div>
          </FormGroup>

          <div className="col-12 justify-content-center d-flex align-items-center mb-3 mt-4">
            <Button
              className="btn-round ml-1 mr-1"
              color="info"
              disabled={loading}
              style={{ width: "25%" }}
              type="button"
              onClick={() => submitForm()}
            >
              Done
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Refund;
