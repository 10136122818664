import React, { useState, useEffect } from "react";
import moment from "moment";
import Empty from "../components/Empty";

// reactstrap components
import {
  UncontrolledAlert,
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Badge,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
} from "reactstrap";
// core components
import ReactPaginate from "react-paginate";
import AddSpeciality from "../components/AddSpeciality";
import Confirm from "../components/ConfirmPopup";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import classnames from "classnames";
import { Notifications } from "utils/notification";
import debounce from "utils/debounce";

const Specialities = () => {
  const { addToast } = useToasts();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [openAddSpeciality, setAddSpeciality] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [openEditSpeciality, setEditSpeciality] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState({});
  const [sort, setSort] = useState({
    name: "name",
    order: "asc",
    icon: "fa-sort",
  });

  const [isDelLoading, setDelLoading] = useState(false);
  const [delUid, setDeleteUid] = useState("");
  const [delSuggUid, setSuggDeleteUid] = useState("");
  const [acceptSuggUid, setSuggAcceptUid] = useState("");
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [suggestedSpecialties, setSuggestedSpecialties] = useState([]);
  const [speCiCount, setSpeCiCount] = useState();
  const [suggestCount, setSuggestCount] = useState();

  const tabToggle = (tab) => {
    if (activeTab != tab) setActiveTab(tab);
  };

  useEffect(() => {
    fetchSpecialities();
    fetchSuggestedSpecialities();
  }, []);
  const fetchSpecialities = () => {
    const API = new ApiService();
    setLoading(true);
    let where = {};
    if (search) where["name"] = { $like: `%${search}%` };
    API._GET({
      api: "/speciality",
      params: {
        sort:
          sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
        where,
        offset: page * perPage,
        limit: perPage,
      },
    })
      .then(({ data }) => {
        setSpecialities(data.specialities);
        setPerPage(data.limit);
        setSpeCiCount(data.count);
        setPageCount(Math.ceil(data.count / data.limit));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  const fetchSuggestedSpecialities = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: "/doctor/suggest-speciality/admin",
      params: {
        search,
        limit: -1
      },
    })
      .then(({ data }) => {
        setSuggestedSpecialties(data.suggested_specialities);
        setSuggestCount(data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        addToast(error.data.message || "Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  const addSpeciality = (data) => {
    const API = new ApiService();
    setLoading(true);
    API._POST({
      api: "/speciality",
      body: {
        name: data,
        active: true,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setAddSpeciality(false);
        fetchSpecialities();
        addToast("Specialty added successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const updateSpeciality = (data) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/speciality/${editData.uid}`,
      body: {
        name: data,
      },
    })
      .then(({ data }) => {
        addToast("Specialty updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setLoading(false);
        setEditSpeciality(false);
        fetchSpecialities();
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    fetchSpecialities();
    fetchSuggestedSpecialities();
  }, [search, page]);

  const deleteSpeciality = () => {
    const API = new ApiService();
    setDelLoading(true);
    API._DELETE({
      api: `/speciality/${delUid}`,
      body: {},
    })
      .then(({ data }) => {
        fetchSpecialities();
        setDelLoading(false);
        setShowConfirm(false);
        addToast("Specialty deleted successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setDelLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const deleteSuggestedServices = () => {
    const API = new ApiService();
    setDelLoading(true);
    API._DELETE({
      api: `/doctor/suggest-speciality/${delSuggUid}`,
      body: {},
    })
      .then(({ data }) => {
        addToast("Specialty rejected", {
          appearance: "success",
          autoDismiss: true,
        });
        fetchSuggestedSpecialities();
        setDelLoading(false);
        setShowReject(false);
      })
      .catch((error) => {
        setShowAccept(false);
        setDelLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const acceptSuggestedService = () => {
    const API = new ApiService();
    setDelLoading(true);
    API._PUT({
      api: `/doctor/suggest-speciality/${acceptSuggUid}`,
      body: {},
    })
      .then(({ data }) => {
        addToast("Specialty added to global list.", {
          appearance: "success",
          autoDismiss: true,
        });
        fetchSuggestedSpecialities();
        fetchSpecialities();
        setDelLoading(false);
        setShowAccept(false);
      })
      .catch((error) => {
        setShowAccept(false);
        setDelLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <>
      <AddSpeciality
        is_open={openAddSpeciality}
        onClose={() => {
          setAddSpeciality(false);
        }}
        isLoading={isLoading}
        onSubmit={addSpeciality}
      />
      <AddSpeciality
        is_open={openEditSpeciality}
        onClose={() => {
          setEditSpeciality(false);
        }}
        isLoading={isLoading}
        onSubmit={updateSpeciality}
        data={editData}
      />
      <Confirm
        msg="Are you sure you want to delete this specialty!"
        desc="This specialty will no longer be available"
        is_open={showConfirm}
        onConfirm={deleteSpeciality}
        onClose={() => setShowConfirm(false)}
        loading={isDelLoading}
      />
      <Confirm
        msg="Are you sure you want to accept this specialty!"
        desc="This specialty will be added to the global list"
        is_open={showAccept}
        onConfirm={acceptSuggestedService}
        onClose={() => setShowAccept(false)}
        loading={isDelLoading}
      />
      <Confirm
        msg="Are you sure you want to reject this specialty!"
        desc="This specialty will no longer be available"
        is_open={showReject}
        onConfirm={deleteSuggestedServices}
        onClose={() => setShowReject(false)}
        loading={isDelLoading}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                {" "}
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Search</label>
                      <Input
                        placeholder="Search by specialty"
                        type="text"
                        value={search}
                        onChange={({ target: { value } }) => {
                          debounce(() => {
                            setPage(0);
                            setSearch(value.replace(/^\s+/g, ''));
                          }, 0);

                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="2">
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round  mb-0 mt-0 ml-2"
                        color="clear"
                        type="button"
                        onClick={() => {
                          setSearch("");
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </FormGroup>
                  </Col>

                  <Col
                    className="pl-1 align-self-end "
                    md="6"
                    style={{ opacity: activeTab === "2" ? ".5" : "1" }}
                  >
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round float-right  "
                        color="info"
                        type="button"
                        disabled={activeTab === "2"}
                        onClick={() => {
                          setAddSpeciality(true);
                        }}
                      >
                        {" "}
                        Add specialty{" "}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Nav tabs className="nav-item-services">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => tabToggle("1")}
                    >
                      <b>Active Specialties ({speCiCount})</b>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => tabToggle("2")}
                    >
                      <b>Suggested Specialties ({suggestCount})</b>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Table responsive>
                      {specialities?.length != 0 && (
                        <thead className="text-primary">
                          <tr>
                            <th className="avoid-hand-cursor">#</th>
                            <th className="avoid-hand-cursor">Specialty Name</th>
                            <th className="text-right avoid-hand-cursor">Actions</th>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {specialities.map((item, index) => (
                          <tr key={item.uid}>
                            <td>{page * perPage + index + 1} </td>
                            <td>{item.name}</td>
                            <td className="text-right">
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                  setEditData(item);
                                  setEditSpeciality(true);
                                }}
                              >
                                <i className="fas fa-pen"></i>
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setDeleteUid(item.uid);
                                  setShowConfirm(true);
                                }}
                                className="btn btn-link"
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {specialities?.length === 0 && <Empty msg="No Data" />}
                    <Col md="12">
                      <div className="justify-content-end d-flex ">
                        <ReactPaginate
                          pageCount={pageCount}
                          forcePage={page}
                          // marginPagesDisplayed={2}
                          // pageRangeDisplayed={5}
                          containerClassName="pagination-style"
                          previousClassName="pagination-prev"
                          nextClassName="pagination-next"
                          pageClassName="pagination-btn"
                          breakClassName="pagination-btn"
                          activeClassName="pagination-active"
                          onPageChange={(page) => {
                            setPage(page.selected);
                          }}
                        />
                      </div>
                    </Col>
                  </TabPane>
                  <TabPane tabId="2">
                    {suggestedSpecialties?.length > 0 && (
                      <Table responsive className="tbl-service mt-3">
                        <thead className="text-primary">
                          <tr>
                            <th className="avoid-hand-cursor"># </th>
                            <th className="avoid-hand-cursor">Specialty Name</th>
                            <th className="avoid-hand-cursor">Requested By</th>
                            <th className="avoid-hand-cursor">User Type</th>
                            <th className="avoid-hand-cursor">Date </th>
                            <th className="avoid-hand-cursor">Status</th>
                            <th className="avoid-hand-cursor"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {suggestedSpecialties?.map((service, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{service.speciality_name} </td>
                              <td>
                                {service.doctor && service.doctor.full_name ? (
                                  service.doctor.full_name
                                ) : (
                                  <i className="text-mute">Deleted User</i>
                                )}{" "}
                              </td>
                              <td>
                                {service.doctor.role_id === 3 && "Doctor"}
                                {service.doctor.role_id === 4 && "Clinic"}
                              </td>
                              <td>
                                {service.created_at
                                  ? moment(service.created_at).format(
                                    "MM/DD/YY"
                                  )
                                  : ""}{" "}
                              </td>
                              <td className="text-right d-flex ">
                                <Button
                                  type="button"
                                  color="success"
                                  size="sm"
                                  className="btn"
                                  onClick={() => {
                                    setSuggAcceptUid(service.uid);
                                    setShowAccept(true);
                                  }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  type="button"
                                  size="sm"
                                  color="danger"
                                  onClick={() => {
                                    setSuggDeleteUid(service.uid);
                                    setShowReject(true);
                                  }}
                                  className="btn ml-4"
                                >
                                  Reject
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                    {suggestedSpecialties?.length === 0 && (
                      <Empty msg="No Data" />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Specialities;
