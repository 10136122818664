import React from "react";
import { Button, Modal, ModalHeader, ModalBody, FormGroup } from "reactstrap";

const SponsorConfirm = ({ is_open, onConfirm, onClose, loading, msg }) => {
  const toggle = () => onClose();

  return (
    <div>
      <Modal isOpen={is_open} toggle={toggle} className="rounded-lg">
        <ModalHeader toggle={toggle} className="modeltitle">
          {" "}
        </ModalHeader>
        <ModalBody className="text-center pb-2">
          {/* <i className="fas trianglestyle mb-4"> */}
          <img
            width="13%"
            className="mb-4"
            src={require("assets/img/sponsor.png")}
          />
          {/* </i> */}

          <h5> {msg} </h5>

          <div className="col-12 justify-content-center d-flex align-items-center mb-3 mt-4">
            <Button
              className="btn-round ml-1 mr-1"
              color="reject"
              type="button"
              disabled={loading}
              style={{ width: "25%" }}
              onClick={onClose}
            >
              No
            </Button>
            <Button
              className="btn-round ml-1 mr-1"
              color="info"
              disabled={loading}
              style={{ width: "25%" }}
              type="button"
              onClick={onConfirm}
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SponsorConfirm;
