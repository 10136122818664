import AWS from "aws-sdk";
import moment from "moment";
import momentTZ from "moment-timezone";
import { createBrowserHistory } from "history";
import { createCipheriv, createDecipheriv } from "crypto";
const hist = createBrowserHistory();
const encryptionKey = "NotTheSharpestSecretKeyInTheShed";
const enc_type = "aes-256-cbc";
const IV = "5183666c72eec9e4";

export function encryptField(text) {
  let cipher = createCipheriv(enc_type, encryptionKey, IV);
  let encryptedText = cipher.update(text, "utf8", "base64");
  encryptedText += cipher.final("base64");
  return encryptedText;
}

export function decryptField(text) {
  if (text) {
    let decipher = createDecipheriv(enc_type, encryptionKey, IV);
    let decrypted = decipher.update(text, "base64", "utf8");
    return decrypted + decipher.final("utf8");
  } else {
    return null;
  }
}

export function redirect(url) {
  hist.push(url);
}

export function isZipValid(elementValue) {
  const zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
  return zipCodePattern.test(elementValue);
}

export function isValidEmail(elementValue) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
}

export function isValidPhone(elementValue) {
  const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return phoneNumberPattern.test(elementValue);
}

export function isNumber(val) {
  const numpattern = /^\d{5}$|^\d{5}-\d{4}$/;
  return numpattern.test(val);
}

export function convertTimeZone({
  fromTz = "Africa/Abidjan",
  dateString,
  dateFormat = "MM/DD/YY h:mm a",
}) {
  try {
    let str = momentTZ(moment.tz(dateString, fromTz))
      .tz(momentTZ.tz.guess())
      .format(dateFormat);
    return str;
  } catch (error) {
    // console.log(error)
  }
}

export function getTimeZone() {
  try {
    let str = momentTZ.tz.guess();
    return str;
  } catch (error) {
    console.log(error);
  }
}

export async function s3Upload({ file, fileName, folder }) {
  try {
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    folder = "prospects/" + encodeURIComponent(folder) + "/";
    const fileKey = folder + fileName;
    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: process.env.BUCKET_NAME,
        Key: fileKey,
        Body: file,
      },
    });

    return upload.promise();
  } catch (error) {
    throw error;
  }
}

export function s3GetSignedUrl({ key }) {
  try {
    const s3 = new AWS.S3();

    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    const signedUrlExpireSeconds = 60 * 5;
    console.log({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: key,
      Expires: signedUrlExpireSeconds,
    });
    const url = s3.getSignedUrl("getObject", {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: key,
      Expires: signedUrlExpireSeconds,
    });
    console.log({ url });
    return url;
  } catch (error) {
    throw error;
  }
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// Format price
export function formatPrice(number, style = "currency", currency = "USD") {
  let options = {};
  if (style && currency) {
    number = parseFloat(number);
    options.style = style;
    options.currency = currency;
  }
  return new Intl.NumberFormat("en-US", options).format(number);
}

// Round price
export function roundPrice(number) {
  return Math.round(number);
}

export function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/[-_]+/g, " ")
    .replace(/[^\w\s]/g, "")
    .replace(/ (.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/ /g, "");
}

export function objectToCamelCase(origObj) {
  return Object.keys(origObj).reduce(function (newObj, key) {
    let val = origObj[key];
    let newVal = typeof val=== "object" ? objectToCamelCase(val) : val;
    newObj[toCamelCase(key)] = newVal;
    return newObj;
  }, {});
}
