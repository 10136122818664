import {
  UncontrolledAlert,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";
// core components
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import ReactPaginate from "react-paginate";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Empty from "../components/Empty";
import Refund from "../components/Refund";
import React, { useState, useEffect } from "react";
import { decryptField } from "utils/helper";
import queryString from "query-string";

const Enquiries = () => {
  let location = useLocation();
  let history = useHistory();

  const { search: searchSting } = useLocation();
  const urlData = queryString.parse(searchSting);
  const [specialities, setSpecialities] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const { addToast } = useToasts();
  const [page, setPage] = useState(urlData.page || 0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });
  useEffect(() => {
    if (urlData.page) {
      setPage(parseInt(urlData.page));
    }
  }, [urlData.page]);
  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `?page=${parseInt(page)}`,
    });
  }, [page]);
  const fetchEnquiries = async () => {
    try {
      const API = new ApiService();
      setLoading(true);
      const {
        data: { enquiries, count, limit },
      } = await API._GET({
        api: "/enquiry",
        params: {
          sort:
            sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
          offset: page * perPage,
          limit: perPage,
          populate: `["user"]`,
        },
      });
      setEnquiries(enquiries);
      setPerPage(limit);
      setPageCount(Math.ceil(count / limit));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  useEffect(() => {
    fetchEnquiries();
  }, [sort, page]);
  const sortEnquirers = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  return (
    <div>
      <div className="content">
        <Row>
          <Col md="12">
            <Row className="cardRow">
              <Col md="12">
                {enquiries.filter((x) => x.status === 0).length > 0 && (
                  <UncontrolledAlert color="warning" fade={false}>
                    <span>
                      <b>
                        {`There are ${enquiries.filter((x) => x.status === 0).length
                          } New Messages`}
                      </b>
                    </span>
                  </UncontrolledAlert>
                )}
                <Card className="cardmt">
                  <CardBody>
                    <Table responsive>
                      {enquiries.length != 0 && (
                        <thead className="text-primary">
                          <tr>
                            <th>
                              Name{" "}
                              <i
                                className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                                  } text-black`}
                                onClick={() =>
                                  sortEnquirers(`user","full_name`)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </th>
                            <th>
                              Email{" "}
                              <i
                                className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                                  } text-black`}
                                onClick={() => sortEnquirers(`user","email`)}
                                style={{ cursor: "pointer" }}
                              />
                            </th>
                            <th>
                              Phone{" "}
                              <i
                                className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                                  } text-black`}
                                onClick={() => sortEnquirers("phone")}
                                style={{ cursor: "pointer" }}
                              />
                            </th>
                            <th className="avoid-hand-cursor">Message</th>
                            <th>
                              Subject{" "}
                              <i
                                className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                                  } text-black`}
                                onClick={() => sortEnquirers("subject")}
                                style={{ cursor: "pointer" }}
                              />
                            </th>
                            <th>
                              Enquiry Date & Time{" "}
                              <i
                                className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                                  } text-black`}
                                onClick={() => sortEnquirers("created_at")}
                                style={{ cursor: "pointer" }}
                              />
                            </th>
                            <th>
                              Status{" "}
                              <i
                                className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                                  } text-black`}
                                onClick={() => sortEnquirers("status")}
                                style={{ cursor: "pointer" }}
                              />
                            </th>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {enquiries.length > 0 &&
                          enquiries.map((_enq) => (
                            <tr key={_enq.id}>
                              <td>
                                {_enq.user.role_id === 2
                                  ? decryptField(_enq.user.full_name)
                                  : _enq.user.full_name}
                              </td>
                              <td>
                                {_enq.user.role_id === 2
                                  ? decryptField(_enq.user.email)
                                  : _enq.user.email}
                              </td>
                              <td>
                                {_enq.phone
                                  ? formatPhoneNumber(_enq.phone)
                                    ? formatPhoneNumber(_enq.phone)
                                    : _enq.phone
                                  : "N/A"}
                              </td>
                              <td>
                                {" "}
                                <Link to={`/admin/enquiries/${_enq.id}`}>
                                  {"Read Message"}
                                </Link>
                              </td>
                              <td>{_enq.subject}</td>
                              <td>
                                {_enq.created_at
                                  ? moment(_enq.created_at).format(
                                    "MM/DD/YY - hh:mm A"
                                  )
                                  : "N/A"}
                              </td>
                              <td>
                                {!!_enq.status && (
                                  <Badge color="success">Completed</Badge>
                                )}
                                {!_enq.status && (
                                  <Badge color="danger">Pending</Badge>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {enquiries.length === 0 && <Empty msg="No Data" />}
                  </CardBody>
                </Card>
              </Col>

              <Col md="12">
                <div className="justify-content-end d-flex ">
                  <ReactPaginate
                    pageCount={pageCount}
                    forcePage={page}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={5}
                    containerClassName="pagination-style"
                    previousClassName="pagination-prev"
                    nextClassName="pagination-next"
                    pageClassName="pagination-btn"
                    breakClassName="pagination-btn"
                    activeClassName="pagination-active"
                    onPageChange={(page) => {
                      setPage(page.selected);
                    }}
                  />
                </div>
              </Col>
            </Row>{" "}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Enquiries;
