import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Spinner,
} from "reactstrap";

const ConfirmPopup = ({
  msg,
  desc,
  is_open,
  onConfirm,
  onClose,
  loading = false,
}) => {
  const toggle = () => onClose();

  return (
    <div>
      <Modal isOpen={is_open} toggle={toggle}>
        <ModalHeader toggle={toggle} className="modeltitle">
          {" "}
        </ModalHeader>
        <ModalBody className="text-center pb-2">
          <i className="fas fa-exclamation-triangle trianglestyle mb-4"></i>
          <h5> {msg} </h5>
          <p>{desc}</p>
          <FormGroup className="text-left mt-5 pb-3">
            <Button
              className="btn-round  btn-block mt-4"
              color="info"
              type="button"
              onClick={onConfirm}
              disabled={loading}
            >
              {loading && (
                <Spinner
                  style={{ width: "1rem", height: "1rem" }}
                  // type="grow"
                  color="dark"
                />
              )}
              CONFIRM
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConfirmPopup;
