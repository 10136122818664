import Dashboard from "views/Dashboard.js";
import Doctors from "views/Doctors.js";
import Doctordetails from "views/Doctordetails.js";
import Patients from "views/Patients";
import Patientdetails from "views/Patientdetails.js";
import Clinics from "views/Clinics.js";
import Clinicdetails from "views/Clinicdetails.js";
import Appointments from "views/Appointments.js";
import Appointmentdetails from "views/Appointmentdetails.js";
import Specialities from "views/Specialities.js";
import Services from "views/Services.js";
import Payments from "views/Payments";
import Settings from "views/Settings";
import Enquiries from "views/Enquiries";
import EnqDetail from "views/EnqDetail";
import BoardCertifications from "views/BoardCertifications";
import Notification from "views/Notification";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-tachometer-alt",
    component: Dashboard,
    layout: "/admin",
    menu_item: true,
    meta: { auth: true },
  },
  {
    path: "/doctors",
    name: "Doctors",
    menu_item: true,
    icon: "fas fa-user-md",
    component: Doctors,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/doctors/:uid",
    name: "Doctordetails",
    icon: "fas fa-angle-right",
    component: Doctordetails,
    menu_item: false,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/patients",
    name: "Patients",
    menu_item: true,
    icon: "fas fa-user",
    component: Patients,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/patients/:uid",
    name: "Patientdetails",
    icon: "fas fa-angle-right",
    component: Patientdetails,
    menu_item: false,
    layout: "/admin",
    meta: { auth: true },
  },

  // {
  //   path: "/clinics",
  //   name: "Clinics",
  //   icon: "fas fa-hospital",
  //   component: Clinics,
  //   menu_item: true,
  //   layout: "/admin",
  //   meta: { auth: true },
  // },

  {
    path: "/clinics/:uid",
    name: "Clinicdetails",
    icon: "fas fa-angle-right",
    component: Clinicdetails,
    menu_item: false,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/appointments",
    name: "Appointments",
    menu_item: true,
    icon: "fas fa-calendar-check",
    component: Appointments,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/appointments/:uid",
    name: "Appointmentdetails",
    icon: "fas fa-angle-right",
    component: Appointmentdetails,
    menu_item: false,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/payments",
    name: "Payments",
    icon: "fas fa-money-bill-wave",
    component: Payments,
    menu_item: true,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/specialties",
    name: "Specialties",
    menu_item: true,
    icon: "fas fa-briefcase-medical",
    component: Specialities,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/services",
    name: "Services",
    menu_item: true,
    icon: "fas fa-check-circle",
    component: Services,
    layout: "/admin",
    meta: { auth: true },
  },

  {
    path: "/settings",
    name: "Settings",
    menu_item: false,
    icon: "fas fa-check-circle",
    component: Settings,
    layout: "/admin",
    meta: { auth: true },
  },
  // {
  //   path: "/forgot-password",
  //   name: "Forgot Password",
  //   icon: "fas fa-angle-right",
  //   component: ForgotPassword,
  //   // layout: "/forgot-password",
  //   meta: { auth: false },
  // },
  // {
  //   path: "/verification",
  //   name: "Verification Code",
  //   icon: "fas fa-angle-right",
  //   component: Verification,
  //   layout: "/admin",
  // },
  // {
  //   path: "/set-new-password",
  //   name: "Create a new password",
  //   icon: "fas fa-angle-right",
  //   component: SetNewPassword,
  //   layout: "/admin",
  // },
  // {
  //   path: "/success-login",
  //   name: "Success",
  //   icon: "fas fa-angle-right",
  //   component: SuccessLogin,
  //   layout: "/admin",
  // },
  {
    path: "/enquiries",
    name: "Enquiries",
    icon: "fas fa-comment-dots",
    component: Enquiries,
    menu_item: true,
    layout: "/admin",
  },
  {
    path: "/board-certifications",
    name: "Board Certifications",
    icon: "fa fa-certificate",
    component: BoardCertifications,
    menu_item: true,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    menu_item: true,
    icon: "fas fa-bell",
    component: Notification,
    layout: "/admin",
    meta: { auth: true },
  },
  {
    path: "/enquiries/:id",
    name: "Enquiry detail",
    icon: "fas fa-comment-dots",
    component: EnqDetail,
    layout: "/admin",
    meta: { auth: true },
  },

  // {
  //   path: "/dashold",
  //   name: "Dashold",
  //   icon: "nc-icon nc-bank",
  //   component: Dashold,
  //   layout: "/admin",
  // },

  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-diamond",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-page",
  //   name: "User Profile",
  //   icon: "nc-icon nc-single-02",
  //   component: UserPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "nc-icon nc-tile-56",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   pro: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-spaceship",
  //   component: UpgradeToPro,
  //   layout: "/admin",
  // },
];
export default routes;
