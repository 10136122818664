import axios from "axios";
import AuthService from "./AuthService";
import { getTimeZone } from "../utils/helper";

class APIService {
  baseURL;
  headers = {};
  authService = new AuthService();
  axiosInstance;

  constructor(
    baseURL = process.env.REACT_APP_API_URL
    // baseURL = "http://localhost:9210/hub"
  ) {
    this.baseURL = baseURL;
    this.headers["Authorization"] =
      "Bearer " + this.authService.getAuths()["x-access-token"];
    this.headers["timezone"] = getTimeZone();
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers: this.headers,
    });
  }

  getBaseURL() {
    return this.baseURL;
  }

  async _POST({ api, body }) {
    return new Promise((resolve, reject) => {
      let req = {
        baseURL: this.baseURL,
        method: "POST",
        data: body,
        url: api,
        headers: this.headers,
      };
      axios(req)
        .then((resp) => {
          const { headers, data: response, status } = resp;
          if (
            response &&
            response.data &&
            response.data["token"] &&
            response.data["refresh_token"] &&
            response.data["session_id"]
          ) {
            this.authService.setAuths({
              accessToken: response.data["token"],
              refreshToken: response.data["refresh_token"],
              sessionId: response.data["session_id"],
            });
          }
          resolve(response);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.authService.clearSession();
          }
          reject(err.response);
        });
    });
  }

  async _GET({ api, params }) {
    return new Promise((resolve, reject) => {
      let req = {
        baseURL: this.baseURL,
        method: "GET",
        params,
        url: api,
        headers: this.headers,
      };
      axios(req)
        .then((resp) => {
          const { headers, data: response, status } = resp;
          if (
            response &&
            response.data &&
            response.data["token"] &&
            response.data["refresh_token"] &&
            response.data["session_id"]
          ) {
            this.authService.setAuths({
              accessToken: response.data["token"],
              refreshToken: response.data["refresh_token"],
              sessionId: response.data["session_id"],
            });
          }
          resolve(response);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.authService.clearSession();
          }
          // console.log(err.message);
          reject(err.response);
        });
    });
  }

  async _PUT({ api, body }) {
    return new Promise((resolve, reject) => {
      delete this.headers["session-token"];
      let req = {
        baseURL: this.baseURL,
        method: "PUT",
        data: body,
        url: api,
        headers: this.headers,
      };
      axios(req)
        .then((resp) => {
          const { headers, data: response, status } = resp;
          if (
            response &&
            response.data &&
            response.data["token"] &&
            response.data["refresh_token"] &&
            response.data["session_id"]
          ) {
            this.authService.setAuths({
              accessToken: response.data["token"],
              refreshToken: response.data["refresh_token"],
              sessionId: response.data["session_id"],
            });
          }
          resolve(response);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.authService.clearSession();
          }
          reject(err.response);
        });
    });
  }

  async _DELETE({ api, body }) {
    return new Promise((resolve, reject) => {
      delete this.headers["session-token"];
      let req = {
        baseURL: this.baseURL,
        method: "DELETE",
        data: body,
        url: api,
        headers: this.headers,
      };
      axios(req)
        .then((resp) => {
          const { headers, data: response, status } = resp;
          if (
            response &&
            response.data &&
            response.data["token"] &&
            response.data["refresh_token"] &&
            response.data["session_id"]
          ) {
            this.authService.setAuths({
              accessToken: response.data["token"],
              refreshToken: response.data["refresh_token"],
              sessionId: response.data["session_id"],
            });
          }
          resolve(response);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.authService.clearSession();
          }
          reject(err.response);
        });
    });
  }
}

export default APIService;
