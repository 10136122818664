import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Button,
  Badge,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import ApiService from "services/APIService";
import { useToasts } from "react-toast-notifications";
import ReactPaginate from "react-paginate";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Empty from "components/Empty";
import { decryptField } from "utils/helper";
import queryString from "query-string";

const Appointment = ({ id }) => {
  let location = useLocation();
  let history = useHistory();
  const { search: searchSting } = useLocation();
  const urlData = queryString.parse(searchSting);
  const [appointments, setAppointments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [docFilter, setDocFilter] = useState("");
  const { addToast } = useToasts();
  const [page, setPage] = useState(urlData.page || 0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  useEffect(() => {
    if (urlData.page) {
      setPage(parseInt(urlData.page));
    }
  }, [urlData.page]);
  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `?page=${parseInt(page)}`,
    });
  }, [page]);
  useEffect(() => {
    fetchDoctors();
  }, []);

  const fetchAppointments = async () => {
    try {
      const API = new ApiService();
      setLoading(true);
      let where = {};
      if (status) where["status"] = { $eq: status };
      if (docFilter) where["doctor_id"] = { $eq: docFilter };
      if (date.startDate && date.endDate)
        where["date_utc"] = { $gte: date.startDate, $lte: date.endDate };
      const {
        data: { appointments, count, limit },
      } = await API._GET({
        api: "/user/appointments",
        params: {
          sort:
            sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
          where: { ...where, clinic_id: id },
          search,
          offset: page * perPage,
          limit: perPage,
          populate: `["user", "doctor"]`,
        },
      });
      setAppointments(appointments);
      setPerPage(limit);
      setPageCount(Math.ceil(count / limit));
    } catch (error) {
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDoctors = async () => {
    try {
      const API = new ApiService();
      const response = await API._GET({
        api: "/user/all",
        params: {
          where: { role_id: 3, active: 1, status: "active" },
          limit: -1,
          sort: `[["full_name", "asc"]]`,
        },
      });
      // console.log(response.data);
      setDoctors(JSON.parse(JSON.stringify(response.data.users)));
    } catch (error) {
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [sort, page, date, search, status, docFilter]);

  const sortAppointments = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Table responsive>
              {appointments.length != 0 && (
                <thead className="text-primary">
                  <tr>
                    <th>
                      Appointment ID{" "}
                      <i
                        className={`fas ${sort.name === "appt_id" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortAppointments("appt_id")}
                        style={{ cursor: "pointer" }}
                      />
                    </th>
                    <th>Patient Name </th>
                    <th>Doctor Name </th>
                    <th>
                      Appointment Date & Time{" "}
                      <i
                        className={`fas ${sort.name === "service_at_date"
                            ? sort.icon
                            : "fa-sort"
                          } text-black`}
                        onClick={() => sortAppointments("service_at_date")}
                        style={{ cursor: "pointer" }}
                      />
                    </th>
                    <th>Amount </th>
                    <th>
                      Type{" "}
                      {/* <i
                          className={`fas ${
                            sort.name === "full_name" ? sort.icon : "fa-sort"
                          } text-black`}
                          onClick={() => sortAppointments("appt_type")}
                          style={{ cursor: "pointer" }}
                        /> */}
                    </th>
                    <th className="text-right">
                      Status{" "}
                      <i
                        className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                          } text-black`}
                        onClick={() => sortAppointments("status")}
                        style={{ cursor: "pointer" }}
                      />
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {appointments.map((appointment) => (
                  <tr key={appointment.uid}>
                    <td>
                      <Link to={`/admin/appointments/${appointment.uid}`}>
                        {appointment.appt_id || "N/A"}
                      </Link>
                    </td>
                    <td>
                      {appointment.someone_else === "1" &&
                        decryptField(appointment.full_name)}
                      {(appointment.someone_else != "1" &&
                        appointment.user &&
                        decryptField(appointment.user.full_name)) ||
                        (appointment.someone_else != "1" && "N/A")}
                    </td>
                    <td>
                      <Link to={"/admin/doctors/" + appointment.doctor.uid}>
                        {(appointment.doctor && appointment.doctor.full_name) ||
                          "N/A"}
                      </Link>
                    </td>
                    <td>
                      {" "}
                      {moment(appointment.service_at_date).format(
                        "MM/DD/YY - hh:mm a"
                      )}{" "}
                    </td>
                    <td>${appointment?.total_cost}</td>
                    <td>
                      {appointment.booking_type === "video_visit" && (
                        <>
                          <i className="fas fa-video text-black" />{" "}
                        </>
                      )}
                      {appointment.booking_type === "in_clinic" && (
                        <>
                          <i className="fas fa-hospital text-black" />{" "}
                        </>
                      )}
                    </td>
                    <td className="text-right">
                      {appointment.status === "completed" && (
                        <Badge color="success">Completed</Badge>
                      )}
                      {appointment.status === "pending" && (
                        <Badge color="warning">Pending</Badge>
                      )}
                      {appointment.status === "ongoing" && (
                        <Badge color="dark">On Going</Badge>
                      )}
                      {appointment.status === "cancelled" && (
                        <Badge color="danger">Canceled</Badge>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {appointments.length === 0 && <Empty msg="No Data" />}
          </CardBody>
        </Card>
      </Col>

      <Col md="12">
        <div className="justify-content-end d-flex ">
          <ReactPaginate
            pageCount={pageCount}
            forcePage={page}
            // marginPagesDisplayed={2}
            // pageRangeDisplayed={5}
            containerClassName="pagination-style"
            previousClassName="pagination-prev"
            nextClassName="pagination-next"
            pageClassName="pagination-btn"
            breakClassName="pagination-btn"
            activeClassName="pagination-active"
            onPageChange={(page) => {
              setPage(page.selected);
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Appointment;
