import React, { useState } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import AuthService from "../services/AuthService";
import { Notifications } from "utils/notification";
import Default from "./Default";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      unread: {
        specialties: false,
        services: false,
      },
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action=== "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  setRead = (read) => {
    this.setState({ unread: read });
  };
  requireLogin(to, from, next) {
    let auth = new AuthService();
    if (to.meta.auth) {
      if (auth.isLogedIn()) {
        next();
      }
      next.redirect("/login");
    } else {
      next();
    }
  }

  render() {
    return (
      <Notifications.Provider
        value={{ state: this.state, setRead: this?.setRead }}
      >
        <Default >
          <div className="wrapper h-auto">
            <Sidebar
              {...this.props}
              routes={routes}
              bgColor={this.state.backgroundColor}
              activeColor={this.state.activeColor}
            />
            <div className="main-panel" ref={this.mainPanel}>
              <DemoNavbar {...this.props} />
              <GuardProvider guards={[this.requireLogin]}>
                <Switch>
                  {routes.map((prop, key) => {
                    return (
                      <GuardedRoute
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                        exact={true}
                        meta={prop.meta}
                      />
                    );
                  })}
                </Switch>
              </GuardProvider>
            </div>
          </div>
        </Default>
      </Notifications.Provider>
    );
  }
}

export default Dashboard;
