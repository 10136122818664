import React from "react";
import PwdConfirm from "../components/PwdConfirm";
import Confirm from "../components/ConfirmPopup";
import SponsorConfirm from "../components/SponsorConfirm";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from "react";
import ApiService from "../services/APIService";
import { decryptField, s3GetSignedUrl } from "../utils/helper";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Empty from "../components/Empty";
import classnames from "classnames";
import DoctorEditButton from "components/DoctorEditButton";
import PendingCard from "components/Donctor/PendingCard";
import Status from "components/Donctor/Status";
import VideoStatus from "components/Donctor/videoStatus";

const Doctordetails = ({ history }) => {
  const [sponsor, setSponsor] = useState({});
  const [state, setState] = useState({
    activeTab: "1",
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(history?.location?.search);
    const activeValue = searchParams.get("active");
    setState({
      activeTab: activeValue,
    });
  }, [history?.location]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [pwdConfirm, setPwdConfirm] = useState(false);
  const [popupDesc, setPopupDesc] = useState("");
  const [popupMsg, setPopupMsg] = useState("");

  const [pwdConfirmDel, setPwdConfirmDel] = useState(false);
  const [popupDescDel, setPopupDescDel] = useState("");
  const [popupMsgDel, setPopupMsgDel] = useState("");

  const [pwdConfirmReject, setPwdConfirmReject] = useState(false);
  const [popupDescReject, setPopupDescReject] = useState("");
  const [popupMsgReject, setPopupMsgReject] = useState("");

  const [pwdConfirmAccept, setPwdConfirmAccept] = useState(false);
  const [popupDescAccept, setPopupDescAccept] = useState("");
  const [popupMsgAccept, setPopupMsgAccept] = useState("");

  const [pwdConfirmUnblock, setPwdConfirmUnblock] = useState(false);
  const [popupDescUnblock, setPopupDescUnblock] = useState("");
  const [popupMsgUnblock, setPopupMsgUnblock] = useState("");

  const [changePasswordPopup, setChangePasswordPopup] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isConfirmSponsor, setConfirmSponsor] = useState(false);
  const [isSponsorLoading, setSponsorLoading] = useState(false);
  const [stripeError, setStripeError] = useState("bankDSingle");

  const [primarySpecialities, setPrimarySpecialities] = useState([]);
  const [secondarySpecialities, setSecondarySpecialities] = useState([]);
  const [otherSpecialities, setOtherSpecialities] = useState([]);
  const [services, setOtherServices] = useState([]);
  const [affiliations, setAffiliations] = useState([]);
  const [basicProfile, setBasicProfile] = useState({});
  const [certficates, setCertificates] = useState([]);
  const [clinicDetails, setClinicDetails] = useState({});
  const [educations, setEducations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [locations, setLocations] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [broadcast, setBroadcast] = useState("");
  const [bank, setBank] = useState({});
  const [stripe, setStripe] = useState({});

  const [docAppts, setDocAppts] = useState([]);
  const [availability, setAvailability] = useState([]);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });

  const { addToast } = useToasts();
  let { uid } = useParams();

  useEffect(() => {
    fetchDocDetails();
    fetchDocAppointements();
    fetchDocReviews();
    fetchAvailabilities();
    fetchDocNotifications();
    fetchDocSponsors();
  }, []);
  const fetchDocSponsors = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: `/user/doctors/subscription/${uid}`,
    })
      .then(({ data }) => {
        setSponsor(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDocDetails = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: `/doctor/fetch/${uid}`,
      params: {},
    })
      .then(({ data: { doctor } }) => {
        setLoading(false);
        setBasicProfile({ ...doctor.basic_profile, doctorAllData: doctor });
        setPrimarySpecialities(
          doctor.specialities.filter((item) => item.is_primary === "1")
        );
        setSecondarySpecialities(
          doctor.specialities.filter((item) => item.is_primary === "2")
        );
        setOtherSpecialities(
          doctor.specialities.filter((item) => item.is_primary === "0")
        );
        setAffiliations(doctor.affiliation);
        setCertificates(doctor.board_certificates);
        setClinicDetails(doctor.clinic_details);
        setEducations(doctor.education);
        setLanguages(doctor.languages_known);
        setLocations(doctor.basic_profile.location);
        setLicenses(doctor?.basic_profile?.license);
        setMemberships(doctor.professional_membership);
        setOtherServices(doctor.services);
        setBank(
          !!doctor.stripeBank && doctor.stripeBank[0]
            ? doctor.stripeBank[0]
            : {}
        );
        setStripe(doctor.userStripe);
        setStripeError(
          !!doctor.userStripe && !doctor.userStripe.active
            ? "bankDSingleError"
            : "bankDSingle"
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const tabToggle = (tab) => {
    if (state.activeTab != tab) {
      setState({ activeTab: tab });
      history.push(`?active=${tab}`);
    }
  };

  const fetchDocAppointements = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: `/user/appointments/doctors/${uid}`,
      params: {
        sort:
          sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
        offset: page * perPage,
        limit: perPage,
        populate: `["user", "doctor","transactions"]`,
      },
    })
      .then(({ data: { appointments, limit, count } }) => {
        // console.log(appointments);
        setPerPage(limit);
        setPageCount(Math.ceil(count / limit));
        setDocAppts(appointments);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const fetchDocReviews = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: `/user/reviews/${uid}`,
      params: {
        // sort:
        //   sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
        // offset: page * perPage,
        // limit: perPage,
        // populate: `["user", "doctor"]`,
      },
    })
      .then(({ data: { reviews } }) => {
        // console.log(reviews);
        setReviews(reviews);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const fetchDocNotifications = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: `/user/${uid}/notifications`,
      params: {
        sort: `[["created_at","desc"]]`,
      },
    })
      .then(({ data }) => {
        setNotifications(data);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const sendBroadcast = () => {
    if (!broadcast) {
      addToast("Please enter a message to broadcast", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    const API = new ApiService();
    setLoading(true);
    API._POST({
      api: `/user/${uid}/broadcast`,
      body: {
        description: broadcast,
      },
    })
      .then(({ data }) => {
        setBroadcast("");
        addToast(`Notification sent to Dr.${data.data.full_name}`, {
          appearance: "success",
          autoDismiss: true,
        });
        fetchDocNotifications();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const fetchAvailabilities = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: `/doctor-availability/${uid}`,
      params: {},
    })
      .then(({ data: { availability } }) => {
        // console.log(availability);
        setAvailability(availability);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const sortAppointments = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  const renderStars = (count) => {
    let stars = [];
    let balance = 5 - count;
    for (var i = 0; i < count; i++) {
      stars.push(
        <i className="fas fa-star  coloro-review m-1" key={Math.random()} />
      );
    }
    for (var i = 0; i < balance; i++) {
      stars.push(
        <i className="fas fa-star  colorg-review m-1" key={Math.random()} />
      );
    }
    return stars;
  };

  useEffect(() => {
    fetchDocAppointements();
  }, [sort, page]);

  const blockUser = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "block",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirm(false);
        fetchDocDetails();
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const deleteUser = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "delete",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmDel(false);
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
        window.location.href = "/admin/doctors";
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const rejectUser = () => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/${uid}`,
      body: {
        action: "reject",
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmReject(false);
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
        history.goBack();
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const acceptUser = () => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/${uid}`,
      body: {
        action: "accept",
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmAccept(false);
        fetchDocDetails();
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const unblockUser = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/action/${uid}`,
      body: {
        action: "unblock",
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setPwdConfirmUnblock(false);
        fetchDocDetails();
        addToast("Success", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const changePassword = (password) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/user/change-password/${uid}`,
      body: {
        password,
      },
    })
      .then((ress) => {
        setLoading(false);
        setChangePasswordPopup(false);
        addToast(ress.message, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const switchSponsor = async () => {
    try {
      setSponsorLoading(true);
      const API = new ApiService();
      let ress = await API._PUT({
        api: `/user/doctor/${uid}/sponsored/`,
        body: { key: basicProfile.is_sponsored === "1" ? "0" : "1" },
      });
      setSponsorLoading(false);
      fetchDocDetails();
      setConfirmSponsor(false);
      addToast("Success", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setSponsorLoading(false);
      addToast(error.data ? error.data.message : "Something went wrong!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const days = [
    {
      MON: 'Monday'
    },
    {
      TUE: 'Tuesday'
    },
    {
      WED: 'Wednesday'
    },
    {
      THU: 'Thursday'
    },
    {
      FRI: 'Friday'
    },
    {
      SAT: 'Saturday'
    },
    {
      SUN: 'Sunday'
    }
  ]

  return (
    <>
      <PwdConfirm
        is_open={pwdConfirm}
        isLoading={isLoading}
        onClose={() => setPwdConfirm(!pwdConfirm)}
        label={"Please enter the password to confirm"}
        msg={popupMsg}
        description={popupDesc}
        onSubmit={(password) => {
          setLoading(true);
          blockUser(password);
        }}
      />

      <PwdConfirm
        is_open={pwdConfirmDel}
        isLoading={isLoading}
        onClose={() => setPwdConfirmDel(!pwdConfirmDel)}
        label={"Please enter the password to confirm"}
        msg={popupMsgDel}
        description={popupDescDel}
        onSubmit={(password) => {
          setLoading(true);
          deleteUser(password);
        }}
      />

      <PwdConfirm
        is_open={pwdConfirmUnblock}
        isLoading={isLoading}
        onClose={() => setPwdConfirmUnblock(!pwdConfirmUnblock)}
        label={"Please enter the password to confirm"}
        msg={popupMsgUnblock}
        description={popupDescUnblock}
        onSubmit={(password) => {
          setLoading(true);
          unblockUser(password);
        }}
      />

      <PwdConfirm
        is_open={changePasswordPopup}
        isLoading={isLoading}
        onClose={() => setChangePasswordPopup(!changePasswordPopup)}
        label={"Please enter the password"}
        msg={`Are you sure to change password for Dr. ${basicProfile.full_name}?`}
        description={`Dr. ${basicProfile.full_name} will receive an email with the new password.`}
        onSubmit={(password) => {
          setLoading(true);
          changePassword(password);
        }}
      />

      <Confirm
        is_open={pwdConfirmReject}
        isLoading={isLoading}
        onClose={() => setPwdConfirmReject(!pwdConfirmReject)}
        msg={popupMsgReject}
        description={popupDescReject}
        onConfirm={() => {
          setLoading(true);
          rejectUser();
        }}
      />

      <Confirm
        is_open={pwdConfirmAccept}
        isLoading={isLoading}
        onClose={() => setPwdConfirmAccept(!pwdConfirmAccept)}
        msg={popupMsgAccept}
        description={popupDescAccept}
        onConfirm={() => {
          setLoading(true);
          acceptUser();
        }}
      />
      <SponsorConfirm
        msg={
          basicProfile.is_sponsored === "1"
            ? "Are you sure to remove sponsorship?"
            : "Are you sure to mark as sponsored?"
        }
        is_open={isConfirmSponsor}
        onConfirm={switchSponsor}
        loading={isSponsorLoading}
        onClose={() => setConfirmSponsor(false)}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <div className="image ">
                <img
                  alt="..."
                  src={require("assets/img/profilebg.jpg")}
                  className="imgw"
                />
              </div>
              <CardBody>
                <div className=" d-flex justify-content-end listdrop">
                  {!["deleted", "pending"].includes(basicProfile.status) && (
                    <Dropdown
                      nav
                      isOpen={dropdownOpen}
                      toggle={(e) => setDropdownOpen(!dropdownOpen)}
                    >
                      <DropdownToggle>
                        <i className="fas fa-ellipsis-h" />
                      </DropdownToggle>
                      <DropdownMenu right style={{
                        willChange: "auto",
                        right: 0
                      }}>
                        {(basicProfile?.status === "active" ||
                          basicProfile?.status === "deactivated") && (
                            <DropdownItem
                              tag="a"
                              disabled={
                                basicProfile.status === "blocked" ||
                                basicProfile.status === "pending"
                              }
                              onClick={() => {
                                setPopupMsg(
                                  `Are you sure to block the doctor ${basicProfile.full_name}?`
                                );
                                setPopupDesc(
                                  `He/She won't be able to access the app and his appointments will be canceled automatically, if any.`
                                );
                                setPwdConfirm(true);
                              }}
                            >
                              Block
                            </DropdownItem>
                          )}
                        {basicProfile.status === "blocked" && (
                          <DropdownItem
                            tag="a"
                            disabled={
                              basicProfile.status === "active" ||
                              basicProfile.status === "pending"
                            }
                            onClick={() => {
                              setPopupMsgUnblock(
                                `Are you sure to unblock the doctor ${basicProfile.full_name}?`
                              );
                              setPopupDescUnblock(
                                `He/She will be able to access the app.`
                              );
                              setPwdConfirmUnblock(true);
                            }}
                          >
                            Unblock
                          </DropdownItem>
                        )}
                        {basicProfile.status != "pending" && (
                          <DropdownItem
                            tag="a"
                            disabled={
                              basicProfile.status === "blocked" ||
                              basicProfile.status === "pending"
                            }
                            onClick={() => {
                              setChangePasswordPopup(true);
                            }}
                          >
                            Change Password
                          </DropdownItem>
                        )}
                        <DropdownItem
                          tag="a"
                          disabled={
                            basicProfile.status === "deleted" ||
                            basicProfile.status === "pending"
                          }
                          onClick={() => {
                            setPopupMsgDel(
                              `Are you sure to delete the doctor ${basicProfile.full_name}?`
                            );
                            setPopupDescDel(
                              `He/She won't be able to access the app and his appointments will be canceled automatically, if any.`
                            );
                            setPwdConfirmDel(true);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </div>

                <div className="author">
                  <img
                    alt="..."
                    className="avatar border-gray bg-light"
                    src={
                      basicProfile.image_url ||
                      require("assets/img/doctor_dummy.png")
                    }
                  />
                  <br />
                  {basicProfile && basicProfile.is_sponsored === "1" && (
                    <Badge
                      style={{ color: "#fff", backgroundColor: "#425381" }}
                    >
                      Sponsored
                    </Badge>
                  )}
                  <h5 className="title">
                    {"Dr. "}
                    {basicProfile.full_name}
                    {basicProfile.full_name && ", "}
                    {basicProfile.acronym &&
                      basicProfile.acronym.acronym_abbreviation}{" "}
                    {/* {basicProfile.status && (
                      <Badge
                        color={
                          basicProfile.status === "active"
                            ? "success"
                            : basicProfile.status === "pending"
                              ? "warning"
                              : "danger"
                        }
                      >
                        {basicProfile.status === "active"
                          ? "Active"
                          : basicProfile.status === "pending"
                            ? "Pending Approval"
                            : basicProfile.status === "blocked"
                              ? "Blocked"
                              : basicProfile.status === "rejected"
                                ? "Rejected"
                                : basicProfile.status === "deactivated"
                                  ? "Deactivated"
                                  : "Deleted"}
                      </Badge>
                    )} */}
                  </h5>

                  <p>
                    <i className="fas fa-star coloro" />{" "}
                    <b>{basicProfile?.doctor_avg_rating}</b>{" "}
                  </p>
                  <div className="orStatus">
                    <div className="orsR">
                      <label>Status:</label>
                      <Status item={basicProfile} />
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "1",
                      })}
                      onClick={() => {
                        tabToggle("1");
                      }}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "2",
                      })}
                      onClick={() => {
                        tabToggle("2");
                      }}
                    >
                      Medical Licenses
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "3",
                      })}
                      onClick={() => {
                        tabToggle("3");
                      }}
                    >
                      Service Location
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "4",
                      })}
                      onClick={() => {
                        tabToggle("4");
                      }}
                    >
                      Reviews
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "5",
                      })}
                      onClick={() => {
                        tabToggle("5");
                      }}
                    >
                      Services
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "6",
                      })}
                      onClick={() => {
                        tabToggle("6");
                      }}
                    >
                      Availability
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "7",
                      })}
                      onClick={() => {
                        tabToggle("7");
                      }}
                    >
                      Bank Detail
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "8",
                      })}
                      onClick={() => {
                        tabToggle("8");
                      }}
                    >
                      Notifications
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab === "9",
                      })}
                      onClick={() => {
                        tabToggle("9");
                      }}
                    >
                      Sponsorship Program
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={state?.activeTab}>
                  <TabPane tabId="1">
                    {basicProfile?.doctorAllData && (
                      <PendingCard
                        basicProfile={basicProfile?.doctorAllData}
                        profile={basicProfile}
                      />
                    )}
                    {basicProfile?.status === "pending" && (
                      <>
                        <div className="bg-light p-3 radius mt-3">
                          <div className="text-left d-flex flex-column flex-md-row align-items-center">
                            <b className="ml-1 mr-1">
                              {" "}
                              Do you want to approve ?{" "}
                            </b>
                            <div className="ml-md-auto mt-3 mt-md-0">
                              <Button
                                className="btn-round ml-1 mr-1"
                                color="reject"
                                type="button"
                                disabled={basicProfile?.status != "pending"}
                                onClick={() => {
                                  setPopupMsgReject(
                                    `Are you sure to reject the doctor ${basicProfile?.full_name}?`
                                  );
                                  setPopupDescReject(
                                    `He/She won't be able to access the app.`
                                  );
                                  setPwdConfirmReject(true);
                                }}
                              >
                                REJECT{" "}
                              </Button>
                              <Button
                                className="btn-round ml-1 mr-1"
                                color="bluedark"
                                type="button"
                                disabled={basicProfile?.status != "pending"}
                                onClick={() => {
                                  setPopupMsgAccept(
                                    `Are you sure to accept the doctor ${basicProfile?.full_name}?`
                                  );
                                  setPopupDescAccept(``);
                                  setPwdConfirmAccept(true);
                                }}
                              >
                                APPROVE{" "}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="button-container">
                      <DoctorEditButton
                        addToast={addToast}
                        uid={uid}
                        typeBtn={true}
                      />
                      <ul className="list-group d-none">
                        <li className="pb-2 py-3">
                          <span>Sponsored? </span> :{" "}
                          {basicProfile?.is_sponsored === "1" ? "Yes" : "No"}
                          <Button
                            className="btn-round py-2 ml-2"
                            color="info"
                            disabled={
                              basicProfile?.profile_status === 0 ||
                              basicProfile?.status != "active"
                            }
                            type="button"
                            onClick={() => setConfirmSponsor(true)}
                          >
                            {basicProfile?.is_sponsored === "1"
                              ? "REMOVE SPONSORSHIP"
                              : "MARK AS SPONSORED"}
                          </Button>
                          {(basicProfile?.profile_status === 0 ||
                            basicProfile?.status != "active") && (
                              <>
                                <i
                                  className="ml-1 fas fa-exclamation-circle text-black"
                                  title="Operation not available until the profile status is active."
                                ></i>{" "}
                              </>
                            )}
                        </li>
                      </ul>
                      <Row>
                        <Col lg="6" md="12" xs="12">
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Profile Details</b>
                          </h5>
                          <ul className="list-group">
                            <li className="pb-2">
                              <span>NPI Number</span> : {basicProfile.npi}
                            </li>
                            <li className="pb-2">
                              <span>Primary specialty</span> :{" "}
                              {primarySpecialities?.length === 0 && "N/A"}
                              {primarySpecialities?.length != 0 &&
                                primarySpecialities?.[0]["speciality.name"]}
                            </li>
                            <li className="pb-2">
                              <span>Secondary specialty</span> :{" "}
                              {secondarySpecialities?.length === 0 && "N/A"}
                              {secondarySpecialities?.length != 0 &&
                                secondarySpecialities?.[0]["speciality.name"]}
                            </li>
                            <li className="pb-2">
                              <span>Email</span> : {basicProfile?.email}
                            </li>
                            <li className="pb-2">
                              <span>Phone Number</span> : {basicProfile?.phone}
                            </li>
                            <li className="pb-2">
                              <span>DOB</span> :{" "}
                              {basicProfile?.dob
                                ? moment(basicProfile?.dob).format("MM/DD/YYYY")
                                : "N/A"}
                            </li>
                            <li className="pb-2">
                              <span>Sex</span> : {basicProfile?.gender}
                            </li>
                            <li className="pb-2">
                              <span>Created at</span> :{" "}
                              {basicProfile?.created_at
                                ? moment(basicProfile?.created_at).format(
                                  "MM-DD-YYYY hh:mm A"
                                )
                                : "N/A"}
                            </li>
                            <li className="pb-2">
                              <span>Approved at</span> :{" "}
                              {basicProfile?.approved_at
                                ? moment(basicProfile?.approved_at).format(
                                  "MM-DD-YYYY hh:mm A"
                                )
                                : "N/A"}
                            </li>
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Languages Known</b>
                          </h5>
                          <ul className="list-group">
                            {languages?.length === 0 && (
                              <li className="pb-2">"N/A"</li>
                            )}
                            {languages?.length != 0 && (
                              <>
                                {languages?.map((lang) => (
                                  <li className="pb-2" key={lang.uid}>
                                    {lang["language.language"]}
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Specialization</b>
                          </h5>
                          <ul className="list-group">
                            {otherSpecialities?.length === 0 && (
                              <li className="pb-2">"N/A"</li>
                            )}
                            {otherSpecialities?.length != 0 && (
                              <>
                                {otherSpecialities?.map((spec) => (
                                  <li className="pb-2" key={spec.uid}>
                                    {spec["speciality.name"]}
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Hospital Affiliation</b>
                          </h5>
                          <ul className="list-group">
                            {affiliations?.length === 0 && (
                              <li className="pb-2">"N/A"</li>
                            )}
                            {affiliations?.length != 0 && (
                              <>
                                {affiliations.map((item) => (
                                  <li className="pb-2" key={item.uid}>
                                    {item.name}
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>CV</b>
                          </h5>
                          <ul className="list-group">
                            {basicProfile?.file_url ? (
                              <a
                                href={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                  basicProfile?.signed_file_url
                                )}`}
                                target="_blank"
                                className="text-muted"
                              >
                                <i
                                  className="fas fa-file-alt"
                                  style={{ fontSize: "20px" }}
                                />
                                {"  "}
                                cv_
                                {basicProfile?.full_name
                                  ?.replace(" ", "_")
                                  ?.toLowerCase()}
                              </a>
                            ) : (
                              `"N/A"`
                            )}
                          </ul>
                        </Col>
                        <Col lg="6" md="12" xs="12">
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Board Certificate</b>
                          </h5>
                          <ul className="list-group">
                            {certficates.length === 0 && (
                              <li className="pb-2">"N/A"</li>
                            )}
                            {certficates.length != 0 && (
                              <>
                                {certficates.map((item) => (
                                  <li className="pb-2" key={item.uid}>
                                    {item.name}
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>About Me</b>
                          </h5>
                          <ul className="list-group">
                            <li className="pb-2">{basicProfile.about_me}</li>
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Education & Training</b>
                          </h5>
                          <ul className="list-group">
                            {educations.length === 0 && (
                              <li className="pb-2">"N/A"</li>
                            )}
                            {educations.length != 0 && (
                              <>
                                {educations.map((item) => (
                                  <li className="pb-2" key={item.uid}>
                                    {item.name}
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Professional Memberships</b>
                          </h5>
                          <ul className="list-group">
                            {memberships.length === 0 && (
                              <li className="pb-2">"N/A"</li>
                            )}
                            {memberships.length != 0 && (
                              <>
                                {memberships.map((item) => (
                                  <li className="pb-2" key={item.uid}>
                                    {item.name}
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Clinic Details</b>
                          </h5>
                          <ul className="list-group">
                            {Object.keys(clinicDetails).length === 0 && (
                              <li className="pb-2">"N/A"</li>
                            )}
                            {Object.keys(clinicDetails).length > 0 && (
                              <li className="pb-2">
                                {" "}
                                {clinicDetails.full_name}
                                <br />
                                {clinicDetails["location.address"]}
                                <br />
                                {clinicDetails.phone}
                              </li>
                            )}
                          </ul>
                          <h5 className="mt-4 mb-1 text-left py-2">
                            <b>Suffix / Acronym</b>
                          </h5>
                          { }
                          <ul className="list-group">
                            {basicProfile?.acronym?.name || "NA"}
                            {"," +
                              basicProfile?.acronym?.acronym_abbreviation ||
                              "NA"}
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Medical Licenses </b>
                    </h5>
                    <Card className="shadow-none border medical-card mb-3">
                      {licenses?.length === 0 && (
                        <CardBody>
                          <Empty />
                        </CardBody>
                      )}
                    </Card>
                    {licenses?.length != 0 && (
                      <>
                        {licenses?.map((item) => (
                          <Card
                            className="shadow-none border medical-card mb-3"
                            key={item.uid}
                          >
                            <CardBody>
                              <p className="m-1">
                                State :{" "}
                                <span>
                                  {item.state ? item.state.name : "N/A"}
                                  {!!item.is_expired && (
                                    <span
                                      style={{
                                        font: " normal normal normal 12px/36px",
                                        letterSpacing: "0.19px",
                                        color: " #FFFFFF",
                                        background:
                                          "#1A2241BA 0% 0% no-repeat padding-box",
                                        borderRadius: "19px",
                                        padding: "4px 10px ",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      Expired
                                    </span>
                                  )}
                                </span>
                              </p>
                              <p className="m-1">
                                License :{" "}
                                <span className="fz-18">{item.license}</span>
                              </p>
                              <div className="d-flex">
                                <p className="mr-3 m-0">
                                  {" "}
                                  Issued :{" "}
                                  <span>
                                    {" "}
                                    {moment(item.issued_date).format(
                                      "MMM DD, YYYY"
                                    )}{" "}
                                  </span>
                                </p>
                                <p className="m-0">
                                  Expire :{" "}
                                  <span>
                                    {moment(item.expiry_date).format(
                                      "MMM DD, YYYY"
                                    )}{" "}
                                  </span>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        ))}
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="3">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Service Location</b>
                    </h5>
                    <Card className="shadow-none border medical-card mb-3">
                      {locations.length === 0 && (
                        <CardBody>
                          <Empty />
                        </CardBody>
                      )}
                    </Card>

                    {locations.length != 0 && (
                      <>
                        {locations.map((item) => (
                          <Card
                            className="shadow-none border service-card mb-3"
                            key={item.uid}
                          >
                            <CardBody className=" p-0">
                              <div className="d-flex align-items-center flex-column flex-md-row">
                                <div>
                                  {/* <img className="border-gray mb-0 mr-md-3 img-fluid" src={require("assets/img/pin.png")} style={{ height: "10%", width: "10%" }} /> */}
                                </div>
                                <div className="pl-3 py-3">
                                  <p className="m-0">
                                    <span className="fz-18">
                                      {item?.specific_location}
                                      {item?.specific_location && " , "}
                                      {item.address}
                                    </span>
                                  </p>
                                  <p className="m-0">
                                    License :{" "}
                                    <span className="fz-18">
                                      {item?.license?.license}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        ))}
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="4">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Reviews</b>
                    </h5>
                    {reviews.length === 0 && (
                      <CardBody>
                        <Empty />
                      </CardBody>
                    )}
                    <div className="s-height">
                      {reviews.length != 0 && (
                        <>
                          {reviews.map((item) => (
                            <Card key={item.id}>
                              <CardHeader>
                                <h6 className="mb-2">
                                  Received {Math.round(item.rating * 10) / 10}/5
                                  star rating from{" "}
                                  {item.patient.full_name &&
                                    decryptField(item.patient.full_name)}
                                </h6>
                              </CardHeader>
                              <CardBody>
                                <div className="mb-2">
                                  {item && item.rating
                                    ? renderStars(Math.floor(item.rating))
                                    : ""}
                                </div>

                                <p>{item.comments}</p>
                              </CardBody>
                            </Card>
                          ))}
                        </>
                      )}
                    </div>
                  </TabPane>

                  <TabPane tabId="5">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Services</b>
                    </h5>
                    <Card className="shadow-none border medical-card mb-3">
                      {services.length === 0 && (
                        <CardBody>
                          <Empty />
                        </CardBody>
                      )}
                    </Card>

                    {services.length != 0 && (
                      <>
                        <div className="serList">
                          {services.map((item) => (
                            <div className="serSingle" key={item.uid}>
                              <div className="sersLeft">
                                <span>
                                  {item.service_type === "in_clinic"
                                    ? "General Consultation"
                                    : "Video Visit"}
                                </span>
                              </div>

                              <div
                                className="sersRt"
                                style={{
                                  gap: "0",
                                  width: "18%",
                                }}
                              >
                                <div className="min">
                                  <span>
                                    {item.status === "1"
                                      ? item.required_time &&
                                      moment
                                        .duration(item.required_time)
                                        .asMinutes() + " mins"
                                      : ""}
                                  </span>
                                </div>
                                <div className="price">
                                  <span>
                                    {item.status === "1" ? (
                                      "$" + item.cost
                                    ) : (
                                      <b>Disabled</b>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="6">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Availability</b>
                    </h5>
                    {/* <div className="availList">
                      <div className="availSingle">
                        <div className="avDay avcol">
                          <span>Monday</span>
                        </div>
                        <div className="avTime avcol">
                          <p className="avmorning">10:00 AM - 12:00 PM</p>
                          <p className="avafternoon">02:00 PM - 06:00 PM</p>
                        </div>
                        <div className="avLoc avcol">
                          <span>
                            <i className="fa fa-map-marker-alt"></i>110 East 55 th street, NY 10022
                          </span>
                          <span>
                            <i className="fa fa-map-marker-alt"></i>110 East 55 th street, NY 10022
                          </span>
                        </div>
                        <div className="avTea avcol">
                          <p><i className="fa fa-coffee"></i></p>
                          <p><i className="fa fa-coffee"></i> <i className="fa fa-coffee"></i></p>
                        </div>
                      </div>
                    </div> */}
                    {Object?.entries(availability)?.map(item => ({ ...item, day: days.find(day => day[item[0]]) })).map((item, key) => <div className="availList">
                      <div className="availSingle" key={key}>
                        <div className="avDay avcol">
                          <span>{item?.day?.[item?.[0]]}</span>
                        </div>
                        <div className="availabilityParent">  {
                          item[1].length > 0 ? (
                            item[1].map((item, index) => (
                              <div key={item.id} className="availabilityFlex align-items-center">
                                <div className="avTime avcol">
                                  <p className="p-0 d-flex align-items-center">
                                    <VideoStatus data={item} />
                                    {item &&
                                      item.from_time &&
                                      moment(
                                        `${moment().format("MM/DD/YY")} ${item.from_time
                                        }`
                                      ).format("h:mm A")}{" "}
                                    -{" "}
                                    {moment(
                                      `${moment().format("MM/DD/YY")} ${item.to_time
                                      }`
                                    ).format("h:mm A")}
                                  </p>
                                </div>
                                <div className="avLoc avcol">
                                  <span>
                                    <i className="fa fa-map-marker-alt"></i>
                                    {item["location.address"]}
                                  </span>
                                </div>
                                {item.no_of_break === 1 && (
                                  <div className="avTea avcol">
                                    <p>
                                      <i className="fa fa-coffee"></i>
                                    </p>
                                  </div>
                                )}
                                {item.no_of_break === 2 && (
                                  <div className="avTea avcol">
                                    <p>
                                      <i className="fa fa-coffee"></i>
                                    </p>
                                    <p>
                                      <i className="fa fa-coffee"></i>
                                    </p>
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className="avTime avcol">
                              No sessions added
                            </div>
                          )}
                        </div>
                      </div>
                    </div>)}
                  </TabPane>
                  <TabPane tabId="7">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Bank Detail</b>
                    </h5>
                    <Card className="shadow-none border medical-card mb-3">
                      {!stripe.stripe_id && (
                        <CardBody>
                          <Empty />
                        </CardBody>
                      )}
                    </Card>
                    {!!stripe.stripe_id && (
                      <>
                        <div className="bankDetail">
                          <div className={stripeError}>
                            <h2>{bank.bank_name}</h2>
                            <span>{`XXXX XXXX XXXX ${bank.last4}`}</span>
                          </div>
                        </div>
                        {stripe && !stripe.active && (
                          <div className="errMsg">
                            <h2>Error Message</h2>
                            <p>
                              There is something wrong with the bank
                              account.Please see the{" "}
                              <a
                                href="https://dashboard.stripe.com/dashboard"
                                target="_blank"
                              >
                                stripe dashboard
                              </a>{" "}
                              for more details.
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="8">
                    <div style={{ background: "#f6f6f6" }} className="p-4">
                      <h5 className="mb-3 ml-3"> Broadcast Message</h5>
                      <div className="col-12 d-flex">
                        <Input
                          className="col-10 pl-3"
                          placeholder="(255 character max)"
                          onChange={({ target: { value } }) => {
                            if (broadcast.length > 0) {
                              setBroadcast(value);
                            } else {
                              setBroadcast(value.replace(/^\s+|\s+$/gm, ""));
                            }
                          }}
                          value={broadcast}
                          type="textarea"
                          maxLength={255}
                        />
                        <div className="col-4 align-self-end">
                          <Button
                            className="formButtonfront"
                            color="info"
                            // size={"large"}
                            disabled={isLoading || broadcast.length <= 0}
                            loading={isLoading}
                            style={{ width: "50%" }}
                            type="button"
                            onClick={() => sendBroadcast()}
                          >
                            {isLoading ? "SENDING..." : "SEND"}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-4 mb-1 text-left py-2">
                      All Notifications
                    </h5>
                    <Card className="shadow-none border medical-card mb-3">
                      {notifications.length === 0 && (
                        <CardBody>
                          <Empty />
                        </CardBody>
                      )}
                    </Card>
                    <div className="s-height">
                      {notifications.length != 0 && (
                        <>
                          {notifications.map((item) => (
                            <Card
                              className="shadow-none border service-card mb-3"
                              key={item.uid}
                            >
                              <CardBody className=" p-0">
                                <div className="">
                                  <div>
                                    {/* <img className="border-gray mb-0 mr-md-3 img-fluid" src={require("assets/img/pin.png")} style={{ height: "10%", width: "10%" }} /> */}
                                  </div>
                                  <div className="pl-3 py-3">
                                    {/* <div className="m-0 d-flex"> */}
                                    {/* <span className="fz-16">
                                        {item.title}
                                      </span> */}
                                    <div
                                      className="fz-14"
                                      style={{ color: "#808080" }}
                                    >
                                      {item.created_at
                                        ? moment(item.created_at).format(
                                          "MM-DD-YYYY hh:mm A"
                                        )
                                        : ""}
                                    </div>

                                    {/* </div> */}
                                    <p className="m-0 mt-2">
                                      <span className="fz-16">
                                        {item.description}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          ))}
                        </>
                      )}
                    </div>
                  </TabPane>
                  <TabPane tabId="9">
                    <h5 className="mt-4 mb-1 text-left py-2">
                      <b>Sponsorship Program</b>
                      {sponsor?.subscription?.subscription_start_date ? (
                        <b className="ml-5">
                          {" "}
                          <i
                            className="fas fa-crown border p-2 rounded-circle"
                            style={{
                              backgroundColor: "#dfb205",
                              color: "#fff",
                              fontSize: "10px",
                            }}
                          />{" "}
                          Member since{" "}
                          {moment(
                            sponsor?.subscription?.subscription_start_date
                          ).format("MMMM YYYY")}{" "}
                          <Badge
                            color={
                              sponsor?.subscription?.is_subscribed === "Y"
                                ? "success"
                                : "danger"
                            }
                          >
                            {sponsor?.subscription?.is_subscribed === "Y"
                              ? "Active"
                              : "Inactive"}
                          </Badge>
                        </b>
                      ) : (
                        <Badge className="ml-5" color="dark">
                          No Sponsorship Program
                        </Badge>
                      )}
                    </h5>
                    {sponsor?.subscription?.subscription_start_date && (
                      <Table responsive id="ddj">
                        <thead className="text-primary">
                          <tr>
                            <th>Subscription ID</th>
                            <th>Transaction ID</th>
                            <th>Issued on</th>
                            <th>Expiry Date</th>
                            <th>Amount </th>
                            <th className="text-center">Payment status </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sponsor?.subscription?.records?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.stripe_subscription_id || "N/A"}</td>
                              <td>{item?.transaction_id}</td>
                              <td>
                                {moment(item.period_start).format("MM/DD/YY")}{" "}
                              </td>
                              <td>
                                {moment(item.period_end).format("MM/DD/YY")}{" "}
                              </td>
                              <td>${sponsor?.subscription?.price}</td>
                              <td className="text-center">
                                <Badge
                                  color={
                                    item.status === "paid"
                                      ? "success"
                                      : "danger"
                                  }
                                >
                                  {item.status === "paid"
                                    ? "Success"
                                    : "Failed"}
                                </Badge>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                    {sponsor?.subscription?.records?.length === 0 && (
                      <CardBody className="d-flex justify-content-center">
                        <Empty msg="No subscription found" />
                      </CardBody>
                    )}
                  </TabPane>
                </TabContent>
              </CardFooter>
            </Card>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">
                      <b>Recent Appointments</b>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead className="text-primary">
                        <tr>
                          <th>
                            Appointment ID{" "}
                            <i
                              className={`fas ${sort.name === "appt_id" ? sort.icon : "fa-sort"
                                } text-black`}
                              onClick={() => sortAppointments("appt_id")}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th className="avoid-hand-cursor">Patient Name </th>
                          <th>
                            Appointment Date &amp; Time{" "}
                            <i
                              className={`fas ${sort.name === "service_at_date"
                                ? sort.icon
                                : "fa-sort"
                                } text-black`}
                              onClick={() =>
                                sortAppointments("service_at_date")
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th className="avoid-hand-cursor">Amount</th>
                          <th className="avoid-hand-cursor">Type</th>
                          <th className="text-right">
                            Status{" "}
                            <i
                              className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                                } text-black`}
                              onClick={() => sortAppointments("status")}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {docAppts?.length === 0 && (
                          <CardBody>
                            <Empty msg="No Appointments" />
                          </CardBody>
                        )}
                        {docAppts.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <Link to={`/admin/appointments/${item.uid}`}>
                                {item.appt_id}
                              </Link>
                            </td>
                            <td>
                              <Link to={"/admin/patients/" + item.user.uid}>
                                {item.user &&
                                  item.user.full_name &&
                                  decryptField(item.user.full_name)}
                              </Link>
                            </td>
                            <td>
                              {moment(item.service_at_date).format(
                                "MM/DD/YY - hh:mm a"
                              )}{" "}
                            </td>
                            <td>
                              $
                              {item.transactions &&
                                item.transactions.collected_amount}
                            </td>
                            <td>
                              {item.booking_type === "video_visit" && (
                                <>
                                  <i className="fas fa-video text-black" />{" "}
                                </>
                              )}
                              {item.booking_type === "in_clinic" && (
                                <>
                                  <i className="fas fa-hospital text-black" />{" "}
                                </>
                              )}
                            </td>
                            <td className="text-right">
                              {item.status === "completed" && (
                                <Badge color="success">Completed</Badge>
                              )}
                              {item.status === "pending" && (
                                <Badge color="warning">Pending</Badge>
                              )}
                              {item.status === "ongoing" && (
                                <Badge color="dark">On Going</Badge>
                              )}
                              {item.status === "cancelled" && (
                                <Badge color="danger">Canceled</Badge>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col md="12">
                <div className="justify-content-end d-flex ">
                  <ReactPaginate
                    pageCount={pageCount}
                    forcePage={page}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={5}
                    containerClassName="pagination-style"
                    previousClassName="pagination-prev"
                    nextClassName="pagination-next"
                    pageClassName="pagination-btn"
                    breakClassName="pagination-btn"
                    activeClassName="pagination-active"
                    onPageChange={(page) => {
                      setPage(page.selected);
                    }}
                  />
                </div>
              </Col> */}

              {/* <Col md="12">
                <div className="justify-content-end d-flex ">
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled>
                      <PaginationLink first href="#" />
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink previous href="#" />
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink href="#">1</PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink href="#">2</PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink href="#">3</PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink href="#">4</PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink href="#">5</PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink next href="#" />
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink last href="#" />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Col>{" "} */}

              <Col md="12">
                <div className="justify-content-end d-flex">
                  <ReactPaginate
                    pageCount={pageCount}
                    forcePage={page}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={5}
                    containerClassName="pagination-style"
                    previousClassName="pagination-prev"
                    nextClassName="pagination-next"
                    pageClassName="pagination-btn"
                    breakClassName="pagination-btn"
                    activeClassName="pagination-active"
                    onPageChange={(page) => {
                      setPage(page.selected);
                    }}
                  />
                </div>
              </Col>
            </Row>{" "}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Doctordetails;
