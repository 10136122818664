import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import APIService from "services/APIService";
import { Notifications } from "utils/notification";

function Default({ children }, props) {
  const { pathname } = useLocation();
  useEffect(() => {
    setTimeout(() => {
      updateRead();
    }, 3000);
  }, [pathname]);
  const { setRead } = React.useContext(Notifications);
  const updateRead = async () => {
    try {
      const API = new APIService();
      const { data } = await API._GET({
        api: "/user/dashboard",
        params: {},
      });
      setRead({
        specialties: data?.unreadSpeciality,
        services: data?.unreadService,
        notifications: data?.unread_notification,
        doctorPendingCount: data?.doctorPendingCount,

      });
    } catch (error) {
      console.log(error);
    }
  };
  return <>{children}</>;
}

export default Default;
