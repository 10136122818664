import React from "react";
import { useHistory, useLocation } from "react-router-dom";

function BackButton() {
  let history = useHistory();
  let location = useLocation();

  return (
    location?.pathname?.split("/").length > 3 && (
      <button style={style} onClick={() => history.goBack()}>
        <i className="fas fa-arrow-left mr-1"></i> Back
      </button>
    )
  );
}

const style = {
  fontSize: "14px",
  textTransform: "capitalize",
  fontWeight: "normal",
  color: "gray",
  border: "none",
  outline: "none",
  backgroundColor: "#fad7d700",
  marginRight: "10px",
};
export default BackButton;
