import React from "react";

function PendingCard({ basicProfile, profile }) {
  console.log(basicProfile);
  const full_name = basicProfile?.basic_profile?.full_name;
  if (
    !basicProfile?.is_availability_added ||
    !basicProfile?.is_bank_added ||
    !basicProfile?.is_service_added ||
    basicProfile?.is_license_expired
  ) {
    return (
      <div style={style} class="alert alert-secondary mt-3 d-flex" role="alert">
        <i
          className="fas fa-info-circle"
          style={{
            color: "#307dd6",
            fontSize: "25px",
          }}
        />
        <div className="pl-3">
          <h5
            style={{
              color: "#307dd6",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            Pending
          </h5>
          <p className="text-dark p-0 m-0">
            Dr.{full_name} profile is pending with the details
          </p>
          <div>
            {!basicProfile?.is_bank_added && (
              <strong className="text-dark">
                <p className="p-0 m-0">Bank Details</p>
              </strong>
            )}
            {!basicProfile?.is_availability_added && (
              <strong className="text-dark">
                <p className="p-0 m-0">Availability</p>
              </strong>
            )}
            {!basicProfile?.is_service_added && (
              <strong className="text-dark">
                <p className="p-0 m-0">Consultation Fee</p>
              </strong>
            )}
            {basicProfile?.is_license_expired && (
              <strong className="text-dark">
                <p className="p-0 m-0">Medical Licenses</p>
              </strong>
            )}
          </div>
        </div>
      </div>
    );
  } else return <></>;
}
const style = {
  backgroundColor: "#e7f2ff",
};

export default PendingCard;
