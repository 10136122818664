import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input, Spinner
} from "reactstrap";
import { useHistory } from "react-router-dom";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";

const Verification = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [sessionId, setSessionId] = useState("");


  useEffect(() => {
    setSessionId(localStorage.getItem('session_id'))
    setEmail(localStorage.getItem('email'))
  }, []);

  const verifyOTP = async () => {
    const API = new ApiService();
    setLoading(true);
    API._POST({
      api: "/auth/otp/verify",
      body: {
        session_id: sessionId,
        otp,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data.session_id) {
          setLoading(false);
          history.push("/set-new-password");
        }
        else {
          addToast("Something went wrong", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <div className='panelLogin'>
      <div className="content">
        <Row>
          <Col className="d-flex justify-content-center " md="12" >
            <Card className="col-lg-3 col-md-4">
              <CardBody>  <Row>
                <Col className="mb-3">
                  <div className="text-center mt-5 mb-5">
                    <img src={require("assets/img/logo.svg")} className="imglogo" />
                  </div>
                  <FormGroup>
                    <div className="intro">
                      <h2>Verification Code</h2>
                      <p>Please enter your verification code, that you received in your email
                        <a href="#"> {email}</a>
                      </p>
                    </div>
                    <div className="mb-2">
                      <label>Enter Verification code</label>
                      <Input placeholder="Verification code" type="text" onChange={({ target: { value } }) => {
                        setOtp(value)
                      }} />
                    </div>
                    <Button className="btn-round  btn-block mt-4" color="info" type="submit" onClick={verifyOTP} > {isLoading && (
                      <Spinner
                        style={{ width: "1rem", height: "1rem" }}
                        // type="grow"
                        color="dark"
                      />)}  VERIFY </Button>
                    {/* <p>Did not receive email? Check your spam filter, or,<Link to={`/forgot-password`} className='anEm'>Try another email</Link></p> */}
                  </FormGroup> </Col></Row></CardBody></Card></Col>
        </Row>
      </div>
    </div>
  );
}

export default Verification;