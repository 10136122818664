import React from "react";
import { Badge } from "reactstrap";

function Status({ item: { profile_status, active, status } }) {
  const getStatus = () => {
    if (status === "blocked") {
      return "blocked";
    }
    if (status === "pending") {
      return "pending";
    } else if (status === "rejected") {
      return "rejected";
    } else if (!!profile_status && status === "active") {
      return "active";
    } else if (!profile_status && status === "active") {
      return "incomplete";
    }
  };
  const current = statusList[getStatus()];
  return current ? <Badge color={current.color}>{current.text}</Badge> : "";
}

export default Status;

const statusList = {
  pending: { text: "Verification Pending", color: "warning" },
  incomplete: { text: "Profile Incomplete", color: "primary-blue" },
  active: { text: "Active", color: "success" },
  blocked: { text: "Blocked", color: "danger" },
  rejected: { text: "Rejected", color: "secondary" },
};
