import React, { useEffect, useState } from "react";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Button,
  Badge,
  FormGroup,
  Input,
} from "reactstrap";
import queryString from "query-string";

import { Link, useHistory, useLocation } from "react-router-dom";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import Loader from "../components/Loader";
import ReactPaginate from "react-paginate";
import Empty from "../components/Empty";
import { encryptField, decryptField } from "../utils/helper";
import debounce from "utils/debounce";

const Patients = () => {
  let location = useLocation();
  let history = useHistory();
  const { search: searchSting } = useLocation();
  const urlData = queryString.parse(searchSting);
  const { addToast } = useToasts();
  const [patients, setPatients] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [page, setPage] = useState(urlData.page || 0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });

  useEffect(() => {
    fetchPatients();
  }, []);

  useEffect(() => {
    fetchPatients();
  }, [status, search, page]);
  useEffect(() => {
    if (urlData.page) {
      setPage(parseInt(urlData.page));
    }
  }, [urlData.page]);
  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `?page=${parseInt(page)}`,
    });
  }, [page]);
  const fetchPatients = () => {
    let query = {}
    const API = new ApiService();
    setLoading(true);
    let where = {};
    // if (search) where["full_name"] = { $like: `%${encryptField(search)}%` };
    // if (search) where["$or"] = [{ full_name: { $like: `%${encryptField(search)}%` } }, { email: { $like: `%${encryptField(search)}%` } }];
    if (search) where["search"] = encryptField(search.toLowerCase());
    if (status) where["status"] = status;
    query = {
      select:
        '["id","uid","full_name","npi","phone","email","status","provider"]',
      sort:
        sort.name && sort.order ? sort : "",
      where,
      page: page,
    };
    // if (!!search) query.search = `${encryptField(search)}`;
    API._GET({
      api: "/user",
      params: query
    })
      .then(({ data }) => {
        setPatients(data.users);
        setPageCount(Math.ceil(data.count / 20));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    fetchPatients();
  }, [sort]);

  const sortPatients = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Search</label>
                      <Input
                        defaultValue=""
                        placeholder="Full name / Email / Phone"
                        type="text"
                        onChange={({ target: { value } }) => {
                          debounce(() => {
                            setPage(0)
                            setSearch(value.replace(/^\s+/g, ''));
                          }, 0);
                        }}
                        value={search}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Status</label>
                      <Input
                        type="select"
                        name="select"
                        onChange={({ target: { value } }) => {
                          setPage(0);
                          setStatus(value);
                        }}
                        value={status}
                        id="exampleSelect"
                      >
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="blocked">Blocked</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="2">
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round  mb-0 mt-0 ml-2"
                        color="clear"
                        type="button"
                        onClick={() => {
                          setPage(0);
                          setStatus("");
                          setSearch("");
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  {patients.length != 0 && (
                    <thead className="text-primary">
                      <tr>
                        <th>
                          Name{" "}
                          <i
                            className={`fas ${sort.name === "full_name" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortPatients("full_name")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th className="avoid-hand-cursor">Phone</th>
                        <th className="avoid-hand-cursor">Email </th>
                        {/* <th>
                        Rating <i className="fas fa-sort text-black" />
                      </th> */}
                        <th class="avoid-hand-cursor">
                          # Appointments{" "}
                          {/* <i className="fas fa-sort text-black" /> */}
                        </th>
                        <th className="avoid-hand-cursor">Login type</th>
                        <th className="text-right avoid-hand-cursor">Status </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {patients.map((item) => (
                      <tr key={item.uid}>
                        <td>
                          {" "}
                          <Link to={"/admin/patients/" + item.uid}>
                            {(item.full_name && decryptField(item.full_name)) ||
                              "N/A"}
                          </Link>
                        </td>
                        <td>
                          {(item.phone && decryptField(item.phone)) || "N/A"}
                        </td>
                        <td>
                          {(item.email && decryptField(item.email)) || "N/A"}
                        </td>
                        {/* <td>
                          <i className="fas fa-star text-black coloro" /> 0
                        </td> */}
                        <td>{item.appt_count}</td>
                        <td>
                          {item.provider === "Local" ? "Email" : item.provider}
                        </td>
                        <td className="text-right">
                          {item.status === "active" && (
                            <Badge color="success">Active</Badge>
                          )}
                          {item.status === "pending" && (
                            <Badge color="warning">Pending</Badge>
                          )}
                          {item.status === "rejected" && (
                            <Badge color="secondary">Rejected</Badge>
                          )}
                          {item.status === "blocked" && (
                            <Badge color="dark">Blocked</Badge>
                          )}


                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {patients.length === 0 && <Empty msg="No Data" />}
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <div className="justify-content-end d-flex ">
              <ReactPaginate
                pageCount={pageCount}
                forcePage={page}
                // marginPagesDisplayed={2}
                // pageRangeDisplayed={5}
                containerClassName="pagination-style"
                previousClassName="pagination-prev"
                nextClassName="pagination-next"
                pageClassName="pagination-btn"
                breakClassName="pagination-btn"
                activeClassName="pagination-active"
                onPageChange={(page) => {
                  setPage(page.selected);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Patients;
