import {
  UncontrolledAlert,
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Badge,
  FormGroup,
  Form,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
// core components
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import ReactPaginate from "react-paginate";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Empty from "../components/Empty";
import Refund from "../components/Refund";
import React, { useState, useEffect } from "react";
import { decryptField } from "utils/helper";
import queryString from "query-string";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Payments = () => {
  let location = useLocation();
  let history = useHistory();
  const { search: searchSting } = useLocation();
  const urlData = queryString.parse(searchSting);
  const [appointments, setAppointments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [docFilter, setDocFilter] = useState("");
  const { addToast } = useToasts();
  const [page, setPage] = useState(urlData.page || 0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [refundId, setRefundId] = useState(null);
  const [showRefund, setShowRefund] = useState(false);
  const [loadingRefund, setRefundLoading] = useState(false);
  const [refundMsg, setRefundMsg] = useState(false);
  const [total, setTotal] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });

  useEffect(() => {
    fetchDoctors();
  }, []);
  useEffect(() => {
    if (urlData.page) {
      setPage(parseInt(urlData.page));
    }
  }, [urlData.page]);
  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `?page=${parseInt(page)}`,
    });
  }, [page]);
  const fetchDoctors = async () => {
    try {
      const API = new ApiService();
      const response = await API._GET({
        api: "/user/all",
        params: {
          where: { role_id: 3, active: 1, status: "active" },
          limit: -1,
          sort: `[["full_name", "asc"]]`,
        },
      });
      setDoctors(JSON.parse(JSON.stringify(response.data.users)));
    } catch (error) {
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const fetchAppointments = async () => {
    try {
      const API = new ApiService();
      setLoading(true);
      setAppointments([]);
      let where = {};
      if (status === "succeeded") where["refund_id"] = { $eq: null };
      if (status === "refunded") where["refund_id"] = { $ne: null };
      if (docFilter) {
        where = {
          ...where,
          "$appointment.doctor_id$": docFilter,
        };
      }
      if (date.startDate && date.endDate)
        where["$appointment.created_at$"] = {
          $gte: date.startDate,
          $lte: date.endDate,
        };
      const {
        data: { appointments, count, limit },
      } = await API._GET({
        api: "/user/payments",
        params: {
          sort:
            sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
          where,
          search,
          offset: page * perPage,
          limit: perPage,
          populate: `["appointment.user", "appointment.doctor","appointment"]`,
        },
      });
      // console.log(appointments);
      if (appointments.length > 0) {
        setAppointments(appointments);
        setPerPage(limit);
        setPageCount(Math.ceil(count / limit));
        setLoading(false);
      } else {
        setAppointments([]);
      }
    } catch (error) {
      setLoading(false);
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  useEffect(() => {
    fetchAppointments();
  }, [sort, page, date, search, status, docFilter]);

  const sortAppointments = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };

  const refund = async (reason, amount, type) => {
    try {
      // if (!reason) {
      //   addToast("Please enter a reason", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
      //   return;
      // }
      const API = new ApiService();
      setRefundLoading(true);
      const ress = await API._POST({
        api: `/payments/refund/${refundId}`,
        body: { reason, amount, type },
      });
      addToast("Refund Success", {
        appearance: "success",
        autoDismiss: true,
      });
      fetchAppointments();
      setRefundLoading(false);
      setShowRefund(false);
    } catch (error) {
      setRefundLoading(false);
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const [startDate, setStartDate] = useState();

  return (
    <>
      <Refund
        msg={refundMsg}
        total={total}
        is_open={showRefund}
        onConfirm={(reason, amount, type) => refund(reason, amount, type)}
        loading={loadingRefund}
        onClose={() => setShowRefund(false)}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                {" "}
                <Row>
                  <Col className="pr-1" md="3">
                    <FormGroup>
                      <label>Search</label>
                      <Input
                        type="text"
                        onChange={({ target: { value } }) => {
                          setPage(0);
                          setSearch(value);
                        }}
                        placeholder="Search by appointment id"
                        value={search}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-3" md="3">
                    <FormGroup>
                      <label>Date</label>
                      <div>
                        <DatePicker className="form-control w-100" showIcon calendarIconClassname="right-0"
                          selected={startDate} onChange={(date) => {
                            setPage(0);
                            if (!date) {
                              setStartDate()
                              setDate({
                                startDate: "",
                                endDate: "",
                              });
                            } else {
                              setStartDate(date)
                              setDate({
                                startDate: moment(date)
                                  .startOf("day")
                                  .format("YYYY-MM-DD HH:mm:ss"),
                                endDate: moment(date)
                                  .endOf("day")
                                  .format("YYYY-MM-DD HH:mm:ss"),
                              });
                            }
                          }} placeholderText="MM/DD/YYYY"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Doctors</label>
                      <Input
                        type="select"
                        name="select"
                        disabled={doctors.length === 0}
                        onChange={({ target: { value } }) => {
                          setPage(0);

                          setDocFilter(value);
                        }}
                        value={docFilter}
                        id="exampleSelect"
                      >
                        <option value="">All</option>
                        {doctors.length > 0 &&
                          doctors.map((doc) => (
                            <option key={doc.id} value={doc.id}>
                              {doc.full_name}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Status</label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        onChange={({ target: { value } }) => {
                          setPage(0);

                          setStatus(value);
                        }}
                        value={status}
                      >
                        <option value="">All</option>
                        <option value="succeeded">Success</option>
                        <option value="refunded">Refunded</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="2">
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round  mb-0 mt-0 ml-2"
                        color="clear"
                        type="submit"
                        onClick={() => {
                          setPage(0);
                          setSearch("");
                          setStatus("");
                          setDocFilter("");
                          setDate({
                            startDate: "",
                            endDate: "",
                          });
                          setStartDate()
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  {appointments.length != 0 && (
                    <thead className="text-primary">
                      <tr>
                        <th>
                          Appointment ID{" "}
                          <i
                            className={`fas ${sort.name === "appt_id" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments("appt_id")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th>
                          Transaction Date{" "}
                          <i
                            className={`fas ${sort.name === "service_at_date"
                              ? sort.icon
                              : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments(`appointment","service_at_date`)}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th className="avoid-hand-cursor">Transaction ID</th>
                        <th className="avoid-hand-cursor">Patient Name</th>
                        <th className="avoid-hand-cursor">Doctor Name </th>
                        <th className="avoid-hand-cursor">Doctor ($)</th>
                        <th className="avoid-hand-cursor">CashDoc ($) </th>
                        <th>
                          Total Amount{" "}
                          <i
                            className={`fas ${sort.name === "booking_amount"
                              ? sort.icon
                              : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments(`appointment","booking_amount`)}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th className="avoid-hand-cursor">Extended</th>
                        <th className="text-right avoid-hand-cursor">Refund</th>
                        <th className="text-right">
                          Status{" "}
                          <i
                            className={`fas ${sort.name === "payment_status"
                              ? sort.icon
                              : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments(`appointment","payment_status`)}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {appointments.length > 0 &&
                      appointments?.map((_appt, i) => (
                        <tr key={i}>
                          <td>
                            <Link
                              to={`/admin/appointments/${_appt.appointment.uid}`}
                            >
                              {_appt.appointment.appt_id || "N/A"}
                            </Link>
                          </td>
                          <td>
                            {moment(_appt.appointment.created_at).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>{_appt.transaction_id}</td>
                          <td>
                            {_appt.appointment.user &&
                              _appt.appointment.user.full_name ? (
                              decryptField(_appt.appointment.user.full_name)
                            ) : (
                              <i className="text-mute">Deleted User</i>
                            )}
                          </td>
                          <td>
                            {_appt.appointment.doctor?.full_name
                              ? _appt.appointment.doctor?.full_name
                              : <i className="text-mute">Deleted User</i> || ""}
                          </td>
                          <td>${_appt.destination_amount}</td>
                          <td>${_appt.application_amount}</td>
                          <td>${_appt.collected_amount}</td>
                          <td>
                            {(_appt.type === "normal" || !_appt.type) && (
                              <Badge pill>No</Badge>
                            )}
                            {_appt.type === "extended" && (
                              <Badge pill color="success">
                                Yes
                              </Badge>
                            )}
                          </td>
                          <td className="text-center">
                            {_appt.refund_id ? (
                              <>${_appt.refund_amount}</>
                            ) : (
                              <Button
                                className="btn-transparent pl-2 pr-2"
                                onClick={() => {
                                  setRefundMsg(
                                    `Are you sure you want to refund $${_appt.collected_amount
                                    } to ${_appt.appointment.user.full_name
                                      ? decryptField(
                                        _appt.appointment.user.full_name
                                      )
                                      : ""
                                    }`
                                  );
                                  setTotal(_appt.collected_amount);
                                  setRefundId(_appt.id);
                                  setTimeout(() => {
                                    setShowRefund(true);
                                  }, 1);
                                }}
                              >
                                Refund
                              </Button>
                            )}
                          </td>
                          <td className="text-right">
                            {!_appt.refund_id && (
                              <Badge color="success">Success</Badge>
                            )}
                            {!!_appt.refund_id && (
                              <Badge color="danger">Refunded</Badge>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {appointments.length === 0 && <Empty msg="No Data" />}
              </CardBody>
            </Card>
          </Col>

          <Col md="12">
            <div className="justify-content-end d-flex ">
              <ReactPaginate
                pageCount={pageCount}
                forcePage={page}
                // marginPagesDisplayed={2}
                // pageRangeDisplayed={5}
                containerClassName="pagination-style"
                previousClassName="pagination-prev"
                nextClassName="pagination-next"
                pageClassName="pagination-btn"
                breakClassName="pagination-btn"
                activeClassName="pagination-active"
                onPageChange={(page) => {
                  setPage(page.selected);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Payments;
