import React from "react";
const Empty = ({ msg }) => {
  return (
    <div style={{ textAlign: "center", marginBottom: "50px" }}>
      <img style={{ width: "150px" }} src="/empty.png" alt="Card image cap" />
      <p>{msg}</p>
    </div>
  );
};

export default Empty;
