import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Button,
  Badge,
  FormGroup,
  Input,
} from "reactstrap";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import ReactPaginate from "react-paginate";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Empty from "../components/Empty";
import { decryptField } from "utils/helper";
import queryString from "query-string";
import debounce from "utils/debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Appointment = ({ }) => {
  let location = useLocation();
  let history = useHistory();
  const { search: searchSting } = useLocation();
  const urlData = queryString.parse(searchSting);
  const [appointments, setAppointments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [docFilter, setDocFilter] = useState("");
  const { addToast } = useToasts();
  const [page, setPage] = useState(urlData.page || 0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [sort, setSort] = useState({
    name: "created_at",
    order: "desc",
    icon: "fa-sort",
  });
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  useEffect(() => {
    if (urlData.page) {
      setPage(parseInt(urlData.page));
    }
  }, [urlData.page]);
  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `?page=${parseInt(page)}`,
    });
  }, [page]);
  useEffect(() => {
    fetchDoctors();
  }, []);

  const fetchAppointments = async () => {
    try {
      const API = new ApiService();
      setLoading(true);
      let where = {};
      // if (search) where["appt_id"] = { $like: `%${search}%` };
      if (status) where["status"] = { $eq: status };
      if (docFilter) where["doctor_id"] = { $eq: docFilter };
      if (date.startDate && date.endDate)
        where["date_utc"] = { $gte: date.startDate, $lte: date.endDate };
      const {
        data: { appointments, count, limit },
      } = await API._GET({
        api: "/user/appointments",
        params: {
          sort:
            sort.name && sort.order ? `[["${sort.name}","${sort.order}"]]` : "",
          where,
          search,
          offset: page * perPage,
          limit: perPage,
          populate: `["user", "doctor"]`,
        },
      });
      setAppointments(appointments);
      setPerPage(limit);
      setPageCount(Math.ceil(count / limit));
    } catch (error) {
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDoctors = async () => {
    try {
      const API = new ApiService();
      const response = await API._GET({
        api: "/user/all",
        params: {
          where: { role_id: 3, active: 1, status: "active" },
          limit: -1,
          sort: `[["full_name", "asc"]]`,
        },
      });
      // console.log(response.data);
      setDoctors(JSON.parse(JSON.stringify(response.data.users)));
    } catch (error) {
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [sort, page, date, search, status, docFilter]);

  const sortAppointments = (name) => {
    setSort({
      ...sort,
      name,
      order: !sort.order ? "asc" : sort.order === "asc" ? "desc" : "",
      icon: !sort.order
        ? "fa-sort-down"
        : sort.order === "asc"
          ? "fa-sort-up"
          : "fa-sort",
    });
  };
  const [startDate, setStartDate] = useState();
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                {" "}
                <Row>
                  <Col className="pr-1" md="3">
                    <FormGroup>
                      <label>Search</label>
                      <Input
                        defaultValue=""
                        placeholder="Search by appointment id"
                        type="text"
                        value={search}
                        onChange={({ target: { value } }) => {
                          debounce(() => {
                            setPage(0);
                            setSearch(value.replace(/^\s+/g, ''));
                          }, 0);

                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-3" md="3">
                    <FormGroup>
                      <label>Date</label>
                      <div>
                        <DatePicker className="form-control w-100" showIcon calendarIconClassname="right-0"
                          selected={startDate} onChange={(date) => {
                            setPage(0);
                            if (!date) {
                              setStartDate()
                              setDate({
                                startDate: "",
                                endDate: "",
                              });
                            } else {
                              setStartDate(date)
                              setDate({
                                startDate: moment(date)
                                  .startOf("day")
                                  .format("YYYY-MM-DD HH:mm:ss"),
                                endDate: moment(date)
                                  .endOf("day")
                                  .format("YYYY-MM-DD HH:mm:ss"),
                              });
                            }
                          }} placeholderText="MM/DD/YYYY"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Doctors</label>
                      <Input
                        type="select"
                        name="select"
                        disabled={doctors.length === 0}
                        onChange={({ target: { value } }) => {
                          setPage(0);

                          setDocFilter(value);
                        }}
                        value={docFilter}
                        id="exampleSelect"
                      >
                        <option value="">All</option>
                        {doctors.length > 0 &&
                          doctors.map((doc) => (
                            <option value={doc.id}>{doc.full_name}</option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="2">
                    <FormGroup>
                      <label>Status</label>
                      <Input
                        type="select"
                        name="select"
                        onChange={({ target: { value } }) => {
                          setPage(0);

                          setStatus(value);
                        }}
                        value={status}
                        id="exampleSelect"
                      >
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Canceled</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="2">
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round  mb-0 mt-0 ml-2"
                        color="clear"
                        type="button"
                        onClick={() => {
                          setPage(0);
                          setStatus("");
                          setSearch("");
                          setDocFilter("");
                          setDate({
                            startDate: "",
                            endDate: "",
                          });
                          setStartDate()
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  {appointments.length != 0 && (
                    <thead className="text-primary">
                      <tr>
                        <th>
                          Appointment ID{" "}
                          <i
                            className={`fas ${sort.name === "appt_id" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments("appt_id")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th className="avoid-hand-cursor">Patient Name </th>
                        <th className="avoid-hand-cursor">Doctor Name </th>
                        <th>
                          Appointment Date & Time{" "}
                          <i
                            className={`fas ${sort.name === "service_at_date"
                              ? sort.icon
                              : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments("service_at_date")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th className="avoid-hand-cursor">Amount </th>
                        <th className="avoid-hand-cursor">
                          Type{" "}
                          {/* <i
                          className={`fas ${
                            sort.name === "full_name" ? sort.icon : "fa-sort"
                          } text-black`}
                          onClick={() => sortAppointments("appt_type")}
                          style={{ cursor: "pointer" }}
                        /> */}
                        </th>
                        <th className="text-right">
                          Status{" "}
                          <i
                            className={`fas ${sort.name === "status" ? sort.icon : "fa-sort"
                              } text-black`}
                            onClick={() => sortAppointments("status")}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {appointments.map((appointment) => (
                      <tr key={appointment.uid}>
                        <td>
                          <Link to={`/admin/appointments/${appointment.uid}`}>
                            {appointment.appt_id || "N/A"}
                          </Link>
                        </td>
                        <td>
                          {appointment.someone_else === "1" &&
                            decryptField(appointment.full_name)}
                          {(appointment.someone_else != "1" &&
                            appointment.user &&
                            decryptField(appointment.user.full_name)) ||
                            (appointment.someone_else != "1" && "N/A")}
                        </td>
                        <td>
                          {appointment?.doctor?.deleted_at ? <i className="text-mute">{"Deleted (" + appointment.doctor.full_name + ")"}</i>
                            : <Link to={"/admin/doctors/" + appointment.doctor.uid}>
                              {(appointment.doctor &&
                                appointment.doctor.full_name) ||
                                "N/A"}
                            </Link>}
                        </td>
                        <td>
                          {" "}
                          {moment(appointment.service_at_date).format(
                            "MM/DD/YY - hh:mm a"
                          )}{" "}
                        </td>
                        <td>${appointment?.total_cost}</td>
                        <td>
                          {appointment.booking_type === "video_visit" && (
                            <>
                              <i className="fas fa-video text-black" />{" "}
                            </>
                          )}
                          {appointment.booking_type === "in_clinic" && (
                            <>
                              <i className="fas fa-hospital text-black" />{" "}
                            </>
                          )}
                        </td>
                        <td className="text-right">
                          {appointment.status === "completed" && (
                            <Badge color="success">Completed</Badge>
                          )}
                          {appointment.status === "pending" && (
                            <Badge color="warning">Pending</Badge>
                          )}
                          {appointment.status === "ongoing" && (
                            <Badge color="dark">On Going</Badge>
                          )}
                          {appointment.status === "cancelled" && (
                            <Badge color="danger">Canceled</Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {appointments.length === 0 && <Empty msg="No Data" />}
              </CardBody>
            </Card>
          </Col>

          <Col md="12">
            <div className="justify-content-end d-flex ">
              <ReactPaginate
                pageCount={pageCount}
                forcePage={page}
                // marginPagesDisplayed={2}
                // pageRangeDisplayed={5}
                containerClassName="pagination-style"
                previousClassName="pagination-prev"
                nextClassName="pagination-next"
                pageClassName="pagination-btn"
                breakClassName="pagination-btn"
                activeClassName="pagination-active"
                onPageChange={(page) => {
                  setPage(page.selected);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Appointment;
