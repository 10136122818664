import moment from "moment";
import React from "react";
import { Nav } from "reactstrap";
import { Link } from "react-router-dom";

function Item(props) {
  const { title, description, created_at, index } = props;
  return (
    <>
      <Link
        to={"/admin/doctors/" + props["doctor.uid"] + "/?active=2"}
        className="nav-link btn-magnify"
      >
        <div className="row px-4" onClick={() => {}}>
          <div
            className="col-8 card border rounded-0 cate p-3 w-100 mb-2  bg-white"
            style={{ cursor: "pointer" }}
          >
            <div className="row flex-row-reverse">
              <div className="col-12 col-md-2 text-end">
                <h6 className="fs-14 text-gray ">
                  <i>{moment(created_at).format("h:mm a")}</i>
                </h6>
              </div>
              <div className="col-12 col-md-10 ">
                <div className=" d-flex align-items-center">
                  <div>
                    <img className="m-0" src={require("assets/img/bell.png")} />
                  </div>
                  <div className="ml-3">
                    <h5
                      className="text-primary mb-0"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      <b> {title}</b>{" "}
                    </h5>
                    <p className="fs-16 primary-text m-0">{description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
export const ItemLoading = () => {
  return (
    <div className="row px-4">
      <div
        className="col-8 card border rounded-0 cate p-3 w-100 mb-2  bg-white"
        style={{ cursor: "pointer" }}
      >
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-2 text-end">
            <h6 className="fs-14 text-gray ">
              <i className=" skeleton-box">
                {" "}
                <span className="opacity-0">01:44 pm</span>
              </i>
            </h6>
          </div>
          <div className="col-12 col-md-10 ">
            <div className=" d-flex align-items-center">
              <div>
                <img
                  className="m-0 "
                  style={{
                    filter: "grayscale(1)",
                  }}
                  src={require("assets/img/bell.png")}
                />
              </div>
              <div className="ml-3">
                <h5
                  className="text-primary mb-0"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  <b className="skeleton-box">
                    {" "}
                    <span className="opacity-0">
                      Appointment Amount Refunded
                    </span>
                  </b>{" "}
                </h5>
                <p className="fs-16 primary-text m-0  skeleton-box">
                  <span className="opacity-0">
                    {" "}
                    Your appointment on 5th Thu, January, 2023 08:15 AM CDT has
                    been refunded.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
