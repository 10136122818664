import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  label,
  Spinner,
  FormFeedback,
} from "reactstrap";

const PwdConfirm = ({
  is_open,
  onClose,
  isLoading,
  label,
  msg,
  description,
  onSubmit,
}) => {
  const [modal, setModal] = useState(false);
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState({
    password: undefined,
  });
  const [processing, setSubmit] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fas fa-eye-slash");

  const isValidateForm = () => {
    let errors = {};
    if (!password) errors.password = "Please enter password";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };

  const submit = () => {
    setSubmit(true);
    if (!isValidateForm()) {
      setSubmit(false);
      return;
    }
    onSubmit(password);
    setPassword("");
  };

  const togglePassword = () => {
    console.log(type);
    if (type === "password") {
      setType("text");
      setIcon("fas fa-eye");
    } else {
      setType("password");
      setIcon("fas fa-eye-slash");
    }
  };

  const toggle = () => onClose();

  return (
    <div>
      <Modal isOpen={is_open} toggle={toggle}>
        <ModalHeader toggle={toggle} className="modeltitle">
          {" "}
        </ModalHeader>
        <ModalBody className="text-center pb-2">
          <i className="fas fa-exclamation-triangle trianglestyle mb-4"></i>

          <h5>{msg}</h5>
          <p>{description}</p>
          <FormGroup className="text-left mt-5 pb-3">
            <div>
              <label>{label}</label>
              <InputGroup>
                <Input
                  placeholder="Enter Password"
                  type={type}
                  valid={!validation.password && !!password}
                  invalid={!!validation.password}
                  onChange={({ target: { value } }) => {
                    setPassword(value);
                  }}
                  onKeyUp={(e) => {
                    e.persist();
                    if (e.keyCode === 13) submit();
                    e && isValidateForm();
                  }}
                ></Input>

                <InputGroupText>
                  <i
                    class={icon}
                    aria-hidden="true"
                    onClick={togglePassword}
                  ></i>
                </InputGroupText>
              </InputGroup>
              <FormFeedback>{validation.password}</FormFeedback>
            </div>

            <Button
              className="btn-round  btn-block mt-4"
              color="info"
              type="button"
              onClick={submit}
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              {isLoading && (
                <Spinner
                  style={{ width: "1rem", height: "1rem" }}
                  // type="grow"
                  color="dark"
                />
              )}
              CONFIRM{" "}
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PwdConfirm;
