import React, { useState, useEffect } from "react";
// react plugin used to create charts
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Button,
  Badge,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
// core components
import Empty from "../components/Empty";
import ReactPaginate from "react-paginate";
import AddService from "../components/AddService";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import Confirm from "../components/ConfirmPopup";
import classnames from "classnames";
import moment from "moment";
import debounce from "utils/debounce";

const Services = () => {
  const { addToast } = useToasts();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [openAddService, setAddService] = useState(false);
  const [services, setServices] = useState([]);
  const [suggestedServices, setSuggestedServices] = useState([]);
  const [serviceCount, setServiceCount] = useState();
  const [suggestCount, setSuggestCount] = useState();
  const [openEditService, setEditService] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState({});

  const [isDelLoading, setDelLoading] = useState(false);
  const [delUid, setDeleteUid] = useState("");
  const [delSuggUid, setSuggDeleteUid] = useState("");
  const [acceptSuggUid, setSuggAcceptUid] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const tabToggle = (tab) => {
    if (activeTab != tab) setActiveTab(tab);
  };

  useEffect(() => {
    fetchServices();
    fetchSuggestedServices();
  }, []);

  const fetchServices = () => {
    const API = new ApiService();
    setLoading(true);
    let where = {};
    if (search) where["name"] = { $like: `%${search}%` };
    API._GET({
      api: "/service",
      params: {
        sort: `[["order", "asc"],["name", "asc"]]`,
        where,
        offset: page * perPage,
        limit: perPage,
      },
    })
      .then(({ data }) => {
        setServices(data.services);
        setServiceCount(data?.count);
        setPerPage(data.limit);
        setPageCount(Math.ceil(data.count / data.limit));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const fetchSuggestedServices = () => {
    const API = new ApiService();
    setLoading(true);
    API._GET({
      api: "/doctor/all-suggest-service",
      params: {
        search,
      },
    })
      .then(({ data }) => {
        setSuggestedServices(data.suggested_services);
        setSuggestCount(data?.count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        addToast(error.data.message || "Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const addSpeciality = (data) => {
    const API = new ApiService();
    setLoading(true);
    console.log(data);
    API._POST({
      api: "/service",
      body: {
        active: true,
        name: data.service,
        cpt: data.cpt_code,
        cost: data.cost,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setAddService(false);
        fetchServices();
        addToast("Service added successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const updateServices = (data) => {
    const API = new ApiService();
    setLoading(true);
    API._PUT({
      api: `/service/${editData.uid}`,
      body: {
        name: data.service,
        cpt: data.cpt_code,
        cost: data.cost,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setEditService(false);
        fetchServices();
        addToast("Service updated successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    fetchServices();
    fetchSuggestedServices();
  }, [search, page]);

  const deleteServices = () => {
    const API = new ApiService();
    setDelLoading(true);
    API._DELETE({
      api: `/service/${delUid}`,
      body: {},
    })
      .then(({ data }) => {
        fetchServices();
        setDelLoading(false);
        setShowConfirm(false);
        addToast("Service deleted successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setDelLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const deleteSuggestedServices = () => {
    const API = new ApiService();
    setDelLoading(true);
    API._DELETE({
      api: `/doctor/suggest-service/${delSuggUid}`,
      body: {},
    })
      .then(({ data }) => {
        addToast("Service rejected", {
          appearance: "success",
          autoDismiss: true,
        });
        fetchSuggestedServices();
        setDelLoading(false);
        setShowReject(false);
      })
      .catch((error) => {
        setDelLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const acceptSuggestedService = () => {
    const API = new ApiService();
    setDelLoading(true);
    API._PUT({
      api: `/doctor/suggest-service/${acceptSuggUid}`,
      body: {},
    })
      .then(({ data }) => {
        addToast("Service added to global list.", {
          appearance: "success",
          autoDismiss: true,
        });
        fetchSuggestedServices();
        fetchServices();
        setDelLoading(false);
        setShowAccept(false);
      })
      .catch((error) => {
        setDelLoading(false);
        addToast(error.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <>
      <AddService
        is_open={openAddService}
        onClose={() => {
          setAddService(false);
        }}
        isLoading={isLoading}
        onSubmit={addSpeciality}
      />
      <AddService
        is_open={openEditService}
        onClose={() => {
          setEditService(false);
        }}
        isLoading={isLoading}
        onSubmit={updateServices}
        data={editData}
      />
      <Confirm
        msg="Are you sure you want to delete this service!"
        desc="This service will no longer be available"
        is_open={showConfirm}
        onConfirm={deleteServices}
        onClose={() => setShowConfirm(false)}
        loading={isDelLoading}
      />
      <Confirm
        msg="Are you sure you want to accept this service!"
        desc="This service will be added to the global list"
        is_open={showAccept}
        onConfirm={acceptSuggestedService}
        onClose={() => setShowAccept(false)}
        loading={isDelLoading}
      />
      <Confirm
        msg="Are you sure you want to reject this service!"
        desc="This service will no longer be available"
        is_open={showReject}
        onConfirm={deleteSuggestedServices}
        onClose={() => setShowReject(false)}
        loading={isDelLoading}
      />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                {" "}
                <Row>
                  <Col className="pr-1" md="6">
                    <FormGroup>
                      <label>Search</label>
                      <Input
                        placeholder="Search by service"
                        type="text"
                        value={search}
                        onChange={({ target: { value } }) => {
                          debounce(() => {
                            setPage(0);
                            setSearch(value.replace(/^\s+/g, ""));
                          }, 0);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="2">
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round  mb-0 mt-0 ml-2"
                        color="clear"
                        type="button"
                        onClick={() => {
                          setSearch("");
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </FormGroup>
                  </Col>

                  <Col className="pl-1 align-self-end " md="4">
                    <FormGroup>
                      <label>&nbsp;</label>
                      <br />
                      <Button
                        className="btn-round float-right  "
                        color="info"
                        type="button"
                        onClick={() => {
                          setAddService(true);
                        }}
                      >
                        {" "}
                        Add SERVICE{" "}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Nav tabs className="nav-item-services">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => tabToggle("1")}
                    >
                      <b>Active Services ({serviceCount})</b>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => tabToggle("2")}
                    >
                      <b>Suggested Services ({suggestCount})</b>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Table responsive className="mt-3">
                      {services?.length != 0 && (
                        <thead className="text-primary">
                          <tr>
                            <th className="avoid-hand-cursor">#</th>
                            <th className="avoid-hand-cursor">Service Name</th>
                            {/* className="avoid-hand-cursor" <th>CPT Code</th> */}
                            <th className="avoid-hand-cursor">
                              Default Amount
                            </th>
                            <th className="text-right avoid-hand-cursor">
                              Action
                            </th>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {services.map((service, index) => (
                          <tr key={index}>
                            <td>{page * perPage + index + 1} </td>
                            <td>{service.name}</td>
                            {/* <td>{service.cpt} </td> */}
                            <td>${service.cost} </td>
                            <td className="text-right">
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                  setEditData(service);
                                  setEditService(true);
                                }}
                              >
                                <i className="fas fa-pen"></i>
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setDeleteUid(service.uid);
                                  setShowConfirm(true);
                                }}
                                className="btn btn-link"
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {services?.length === 0 && <Empty msg="No Data" />}

                    <Col md="12">
                      <div className="justify-content-end d-flex ">
                        <ReactPaginate
                          pageCount={pageCount}
                          forcePage={page}
                          // marginPagesDisplayed={2}
                          // pageRangeDisplayed={5}
                          containerClassName="pagination-style"
                          previousClassName="pagination-prev"
                          nextClassName="pagination-next"
                          pageClassName="pagination-btn"
                          breakClassName="pagination-btn"
                          activeClassName="pagination-active"
                          onPageChange={(page) => {
                            setPage(page.selected);
                          }}
                        />
                      </div>
                    </Col>
                  </TabPane>
                  <TabPane tabId="2">
                    {suggestedServices.length > 0 && (
                      <Table responsive className="tbl-service mt-3">
                        {suggestedServices.length != 0 && (
                          <thead className="text-primary">
                            <tr>
                              <th className="avoid-hand-cursor"># </th>
                              <th className="avoid-hand-cursor">
                                Service Name
                              </th>
                              <th className="avoid-hand-cursor">
                                Requested by
                              </th>
                              <th className="avoid-hand-cursor">User Type</th>
                              <th className="avoid-hand-cursor">Date </th>
                              <th className="avoid-hand-cursor">Status</th>
                              <th
                                colSpan={2}
                                className="text-center avoid-hand-cursor"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {suggestedServices.map((service, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{service.service_name} </td>
                              <td>
                                {service.doctor && service.doctor.full_name ? (
                                  service.doctor.full_name
                                ) : (
                                  <i className="text-mute">Deleted User</i>
                                )}{" "}
                              </td>
                              <td>
                                {service?.doctor?.role_id === 3 && "Doctor"}
                                {service?.doctor?.role_id === 4 && "Clinic"}
                              </td>{" "}
                              <td>
                                {service.created_at
                                  ? moment(service.created_at).format(
                                      "MM/DD/YY"
                                    )
                                  : ""}{" "}
                              </td>
                              <td>
                                {" "}
                                <Badge color="warning">Pending</Badge>
                              </td>
                              <td className="text-right">
                                <Button
                                  type="button"
                                  color="success"
                                  size="sm"
                                  className="btn"
                                  onClick={() => {
                                    setSuggAcceptUid(service.uid);
                                    setShowAccept(true);
                                  }}
                                >
                                  Accept
                                </Button>
                              </td>
                              <td className="text-left">
                                <Button
                                  type="button"
                                  size="sm"
                                  color="danger"
                                  onClick={() => {
                                    setSuggDeleteUid(service.uid);
                                    setShowReject(true);
                                  }}
                                  className="btn"
                                >
                                  Reject
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                    {suggestedServices.length === 0 && <Empty msg="No Data" />}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Services;
