import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Spinner, FormFeedback
} from "reactstrap";
import { useHistory } from "react-router-dom";
import ApiService from "../services/APIService";
import { useToasts } from "react-toast-notifications";
import Loader from "../components/Loader";

const SetNewPassword = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [validation, setValidation] = useState({
    new_password: undefined,
    confirm_password: undefined,
  });

  const isValidateFormPassword = () => {
    let errors = {};
    if (!password) errors.new_password = "Please enter valid password";
    if (!confirmPassword)
      errors.confirm_password = "Please enter valid password";
    if (!!password && password.length < 6)
      errors.new_password = "Password must be minimum 6 characters";
    if (!!confirmPassword && confirmPassword.length < 6)
      errors.confirm_password = "Confirm Password must be minimum 6 characters";
    if (
      !!confirmPassword &&
      confirmPassword.length >= 6 &&
      !!password &&
      password != confirmPassword
    )
      errors.confirm_password = "Confirm Password must be same as password";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };

  const changePassword = async () => {
    if (!isValidateFormPassword()) {
      return;
    }
    const API = new ApiService();
    setLoading(true);
    API._POST({
      api: "/auth/password/reset",
      body: {
        session_id: sessionId,
        password,
      },
    }).then(() => {
      localStorage.removeItem("session_id");
      localStorage.removeItem("email");
      setLoading(false);
      history.push("/success-login");
    })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    setSessionId(localStorage.getItem("session_id"));
  }, []);

  return (
    <div>
      <div className="panelLogin">
        <div className="content">
          <Row>
            <Col className="d-flex justify-content-center " md="12">
              <Card className="col-lg-3 col-md-4">
                <CardBody>
                  {" "}
                  <Row>
                    <Col className="mb-3">
                      <div className="text-center mt-5 mb-5">
                        <img
                          src={require("assets/img/logo.svg")}
                          className="imglogo"
                        />
                      </div>
                      <FormGroup>
                        <div className="intro">
                          <h2>Create a new password</h2>
                          <p>
                            Your new password must be different from previous
                            used passwords.
                          </p>
                        </div>
                        <div className="mb-2">
                          <label>New Password</label>
                          <Input
                            placeholder="Password"
                            type="password"
                            valid={
                              !validation.new_password && !!password
                            }
                            invalid={!!validation.new_password}
                            onChange={({ target: { value } }) => {
                              setPassword(value);
                            }}
                          />
                          <FormFeedback>{validation.new_password}</FormFeedback>
                        </div>
                        <div className="mb-2">
                          <label>Confirm Password</label>
                          <Input
                            placeholder="Confirm Password"
                            type="password"
                            valid={!validation.confirm_password && !!confirmPassword}
                            invalid={!!validation.confirm_password}
                            onChange={({ target: { value } }) => {
                              setConfirmPassword(value);
                            }}
                          />
                          <FormFeedback>
                            {validation.confirm_password}
                          </FormFeedback>
                        </div>
                        <Button
                          className="btn-round  btn-block mt-4"
                          color="info"
                          type="submit"
                          onClick={changePassword} > {isLoading && (
                            <Spinner
                              style={{ width: "1rem", height: "1rem" }}
                              // type="grow"
                              color="dark"
                            />)}
                          RESET PASSWORD
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
